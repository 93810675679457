<template>
  <div class="page ordenCompra px-3 py-5 px-md-5">
    <div class="header-page">
      <h3>Nota de despacho</h3>
    </div>
    <div class="body-page py-4">
      <div class="sub-page sub-page-start d-flex justify-content-center"
        :class="{ 'align-items-start': listTrack, 'align-items-center': !listTrack }">
        <!-- Pantalla Inicio Orden de producción -->
        <div class="item-page-list w-100" v-if="listTrack">
          <div>
            <div class="text-left my-3 d-flex justify-content-between">
              <div class="col-sm d-flex justify-content-start">
                <span class="icon-list icon-blue-dark">
                  <i class="fas fa-cogs fa-1x"></i></span> <span class="mx-3 h4">Gestión de notas de despacho</span>
              </div>
            </div>
            <div class="row justify-content-end mt-4 mb-4 filters" v-if="!is_leader()">
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por OP:</p>
                <div>
                  <b-form-input v-model="codeOp" @change="applyFiltersRemission()" placeholder="Ingrese una OP"
                    class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por lista empaque:</p>
                <div>
                  <b-form-input v-model="list" @change="applyFiltersRemission()" placeholder="Ingrese una lista"
                    class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por nota despacho:</p>
                <div>
                  <b-form-input v-model="note" @change="applyFiltersRemission()" placeholder="Ingrese una nota"
                    class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por estado:</p>
                <div>
                  <div>
                    <b-form-select v-model="status" @change="applyFiltersRemission()" :options="statusFilter" class="mb-3">
                      <template #first></template>
                    </b-form-select>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 text-left">
                <p class="font-weight-bold">Filtrar por fecha:</p>
                <div class="col-12 p-0 m-0">
                  <b-form-select class="col-7" v-model="monthOp" @change="applyFiltersRemission()"
                    :options="monthOptions"></b-form-select>
                  <b-form-select class="col-5" v-model="yearOp" @change="applyFiltersRemission()"
                    :options="yearOptions"></b-form-select>
                </div>
              </div>
              <div class="col-xl-1 allData text-left">
                <button class="btn-filter-search" @click="applyFiltersRemission()">Refrescar</button>
              </div>
            </div>
            <div class="table-responsive">
              <table v-if="loadTable" class="table table-list list-track">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th class="row-op text-center font-weight">Orden de Producción</th>
                    <th class="row-oc text-center font-weight">Orden de Compra</th>
                    <th style="width: 10rem;"></th>
                    <th style="width: 20rem;"></th>
                    <th style="width: 10rem;"></th>
                    <th style="width: 10rem;"></th>
                    <th style="width: 10rem;"></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="subhead-titles">
                    <td class="font-weight" style="width: 10%;">Consecutivo lista empaque</td>
                    <td class="font-weight" style="width: 10%;">Consecutivo nota despacho</td>
                    <td class="font-weight">Fecha</td>
                    <td class="font-weight">N° OP</td>
                    <td class="font-weight">N° OC</td>
                    <td class="font-weight">Cod. Producto</td>
                    <td class="font-weight">Descripción</td>
                    <td class="font-weight">Lote verificado</td>
                    <td class="font-weight">Estado despacho</td>
                    <td class="font-weight">Código SIESA</td>
                    <td class="font-weight">Cantidad</td>
                    <td></td>
                  </tr>
                  <template v-for="item in paginatedNotas">
                    <tr v-for="(subitem, i) in item.Remission" :key="subitem.pk">
                      <td v-if="i === 0" :rowspan="item.Remission.length">
                        <b-button class="text-dark font-weight-bold" variant="link"
                          @click="$bvModal.show('detail-listaempaque'); modalListClose(); detailPackinglist(item.PackingList)">
                          {{ item.PackingList }}
                        </b-button>
                      </td>
                      <td>
                        <span>
                          <b-button class="text-dark font-weight-bold" variant="link"
                            @click="$bvModal.show('detail-notadespacho'); modalListClose(); detailRemission(subitem.id)">
                            {{ subitem.id }}
                          </b-button>
                        </span>
                      </td>
                      <td><span class="d-block">{{ subitem.date }}</span></td>
                      <td><span class="d-block">{{ subitem.Op }}</span></td>
                      <td><span class="d-block">{{ subitem.oc }}</span></td>
                      <td><span class="d-block">{{ subitem.reference }}</span></td>
                      <td><span class="d-block">{{ subitem.description }}</span></td>
                      <td><span class="d-block">{{ subitem.batch }}</span></td>
                      <td><span class="d-block">{{ subitem.status }}</span></td>
                      <td><span class="d-block">{{ subitem.codigo_siesa }}</span></td>
                      <td><span class="d-block">{{ subitem.quantity }}</span></td>
                      <td>
                        <button class="btn py-0 px-2" @click="showBlockEdit(item.Remission[i]);"><i
                            class="far fa-edit text-success"></i>
                        </button>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="text-center" v-if="load" style="color: #03185A;">
              <b-spinner label="Text Centered"></b-spinner>
            </div>
            <div class="center" v-if="startRemission.length === 0 && load === false">
              <span><b-badge variant="danger align-middle"><h6>No existen registros en la base de datos.</h6></b-badge></span>
            </div>
            <br>
            <b-pagination class="list-pagination" pills align="center" hide-ellipsis :total-rows="startRemission.length" v-model="pag" :per-page="NUM_RESULTS" />
            <div v-if="is_admin()" class="my-5">
              <b-button class="btn-action-page py-2" @click="showBlock();" size="lg">Agregar una nota de
                despacho</b-button>
            </div>
            <div class="my-3" v-if="is_leader()">
              <b-button class="btn-action-page py-2" @click="showBlock(); currentData = []" size="lg">Agregar
                Trazabilidad</b-button>
            </div>
          </div>
          <b-modal ref="close-sub-modal" id="detail-listaempaque" size="xl" :hide-footer="true" :hide-header="true"
            body-class="">
            <div id="imprimir">
              <div class="d-flex justify-content-end">
                <button class="bg-transparent border-0" @click="close_sub_modal()">
                  <i class="far fa-times-circle fa-2x color-grayLight"></i>
                </button>
              </div>
              <div class="px-3 mb-3">
                <div class="" style="display: flex; justify-content: center; padding-bottom: 50px;">
                  <img src="../assets/logoproduempak.png" alt="" width="450">
                </div>
                <div class="col">
                  <div class="pb-3" style="font-size: 25px;">Fecha de creación: {{ datearray }}</div>
                  <div class="pb-3" style="font-size: 20px;">Consecutivo lista de empaque: {{ consecutivoarray }}</div>
                  <hr>
                  <p class="font-weight-bold" style="font-size: 20px;">Observaciones</p>
                  <div style="font-size: 20px;" class="py-1">
                    {{ observationsarray }}
                  </div>
                </div>
                <div class="table-responsive mt-4" style="text-align: center">
                  <table style="width: 100%;" v-if="loadTable" class="table table-list list-track">
                    <thead>
                      <tr>
                        <th style="height: 3rem;"></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="subhead-titles">
                        <td style="width: 10%;font-size: 20px;">No. Nota despacho</td>
                        <td style="width: 15%;font-size: 20px;">Producto</td>
                        <td style="width: 20%;font-size: 20px;">Descripción</td>
                        <td style="width: 10%;font-size: 20px;">NO OP</td>
                        <td style="width: 10%;font-size: 20px;">Cantidad</td>
                        <td style="width: 15%;font-size: 20px;">NO OC</td>
                        <td style="width: 15%;font-size: 20px;">Lote verificado</td>
                      </tr>
                      <tr v-for="(index, i) in detaildata" :key="i">
                        <td><span class="d-block" style="font-size: 20px;">{{ index.id }}</span></td>
                        <td><span class="d-block" style="font-size: 20px;">{{ index.reference }}</span></td>
                        <td><span class="d-block" style="font-size: 20px;">{{ index.description }}</span></td>
                        <td><span class="d-block" style="font-size: 20px;">{{ index.Op }}</span></td>
                        <td><span class="d-block" style="font-size: 20px;">{{ index.quantity }}</span></td>
                        <td><span class="d-block" style="font-size: 20px;">{{ index.oc }}</span></td>
                        <td><span class="d-block" style="font-size: 20px;">{{ index.batch }}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: center;">
              <b-button class="btn-filter-search" @click="print()">Imprimir</b-button>
            </div>
          </b-modal>
          <b-modal ref="close-sub-modal" id="detail-notadespacho" size="xl" :hide-footer="true" :hide-header="true"
            body-class="">
            <div id="imprimir2">
              <div class="d-flex justify-content-end">
                <button class="bg-transparent border-0" @click="close_sub_modal()">
                  <i class="far fa-times-circle fa-2x color-grayLight"></i>
                </button>
              </div>
              <div class="px-3 mb-3">
                <div class="" style="display: flex; justify-content: center; padding-bottom: 50px;">
                  <img src="../assets/logoproduempak.png" alt="" width="450">
                </div>
                <div class="col">
                  <div class="pb-3" style="font-size: 25px;">Fecha de creación: {{ datedetail }}</div>
                </div>
                <div class="table-responsive mt-4" style="text-align: center">
                  <table style="width: 100%;" v-if="loadTable" class="table table-list list-track">
                    <thead>
                      <tr>
                        <th style="height: 3rem;"></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="subhead-titles">
                        <td style="width: 10%;font-size: 20px;">No. Nota despacho</td>
                        <td style="width: 15%;font-size: 20px;">Producto</td>
                        <td style="width: 20%;font-size: 20px;">Descripción</td>
                        <td style="width: 10%;font-size: 20px;">NO OP</td>
                        <td style="width: 10%;font-size: 20px;">Cantidad</td>
                        <td style="width: 15%;font-size: 20px;">NO OC</td>
                        <td style="width: 15%;font-size: 20px;">Lote verificado</td>
                      </tr>
                      <tr v-for="(index, i) in detaildata" :key="i">
                        <td><span class="d-block" style="font-size: 20px;">{{ index.id }}</span></td>
                        <td><span class="d-block" style="font-size: 20px;">{{ index.reference }}</span></td>
                        <td><span class="d-block" style="font-size: 20px;">{{ index.description }}</span></td>
                        <td><span class="d-block" style="font-size: 20px;">{{ index.Op }}</span></td>
                        <td><span class="d-block" style="font-size: 20px;">{{ index.quantity }}</span></td>
                        <td><span class="d-block" style="font-size: 20px;">{{ index.oc }}</span></td>
                        <td><span class="d-block" style="font-size: 20px;">{{ index.batch }}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: center;">
              <button class="btn-filter-search" @click="print2()">Imprimir</button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
    <div class="sidenav-page p-4" :class="{ openSidebar: openOptions }">
      <side-block :icon="'track-interna'" :tpl="'remission'" @closeBlock="close" :data="currentData" />
    </div>
    <div>
      <b-modal ref="pdf-modal" id="pdf-modal" size="xl" centered :hide-footer="true" :hide-header="true">
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="close_pdf_modal()">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div align="center">
          <pdf :src="currentDataSheetURL" id="pdf-view" :resize="true" @loading="removeText()" :page="1">
            <template slot="loading">
              Cargando ficha técnica...
            </template>
          </pdf>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
// import Multiselect from 'vue-multiselect'
import pdf from 'pdfvuer'
import SideBlock from '@/components/SideBlock.vue'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  data: () => ({
    NUM_RESULTS: 5, // Numero de resultados por página
    pag: 1, // Página inicial
    load: true,
    currentDataSheetURL: '',
    showModal: false,
    detaildata: [],
    observationsarray: [],
    datedetail: '',
    datearray: [],
    consecutivoarray: [],
    listTrack: true,
    currentData: [],
    loadTable: true,
    Op: undefined,
    codeList: [],
    codeOp: undefined,
    list: undefined,
    note: undefined,
    status: null,
    selectedItems: null,
    filterOption: null,
    filterOptions: [
      { value: null, text: 'Seleccione un filtro' },
      { value: '1', text: 'Filtro por OP' },
      { value: '2', text: 'Filtro por mes' },
      { value: '3', text: 'Ver todo' }
    ],
    statusFilter: [
      { value: null, text: 'Seleccione un filtro' },
      { value: 'creado', text: 'Creado' },
      { value: 'despachado', text: 'Despachado' },
      { value: 'facturado', text: 'Facturado' },
      { value: 'devolución', text: 'Devolución' }
    ]
  }),
  components: {
    pdf,
    SideBlock
    // Multiselect
  },
  mounted () {
    const self = this
    if (sessionStorage.token && sessionStorage.rol === '1') {
      self.$store.state.dataEstado = []
      axios.get('/api_orders/StatusRemission/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
        .then(function (response) {
          // console.log(response.data.data)
          response.data.data.forEach((item, index) => {
            var data = { value: item.pk, text: item.name }
            self.$store.commit('actionSetDataEstado', data)
          })
        })
      self.setdataOrderStatus()
      self.$store.dispatch('getLineListWithTeam')
      self.$root.$on('setdataOrderStatus', () => {
        self.setdataOrderStatus()
      })
    } else {
      this.$router.push({ name: 'Inicio' })
    }
    if (sessionStorage.token) {
      this.$store.dispatch('statusModulesEnabled')
      this.$store.dispatch('atLeastQualityRecordFn')
    }
  },
  computed: {
    paginatedNotas () {
      // Calcular la lista paginada
      const startIndex = (this.pag - 1) * this.NUM_RESULTS
      const endIndex = startIndex + this.NUM_RESULTS
      return this.startRemission.slice(startIndex, endIndex)
    },
    getLineList () {
      return this.$store.state.lineListWithTeam
    },
    currentTask () {
      return this.$store.state.storeProduction.currentTask
    },
    openOptions () {
      return this.$store.state.showOptions
    },
    estadoObj () {
      return this.$store.state.storeProduction.dispatchStatus
    },
    dataEstado () {
      return this.$store.state.dataEstado
    },
    startRemission: {
      get () {
        return this.$store.state.storeTrack.startRemission
      },
      set (value) {
        this.$store.state.storeTrack.startRemission = value
      }
    },
    monthOp: {
      get () {
        return this.$store.state.storeTrack.monthOp
      },
      set (value) {
        this.$store.state.storeTrack.monthOp = value
      }
    },
    yearOp: {
      get () {
        return this.$store.state.storeTrack.yearOp
      },
      set (value) {
        this.$store.state.storeTrack.yearOp = value
      }
    },
    monthOptions: {
      get () {
        return this.$store.state.storeTrack.monthOptions
      },
      set (value) {
        this.$store.state.storeTrack.monthOptions = value
      }
    },
    yearOptions: {
      get () {
        return this.$store.state.storeTrack.yearOptions
      },
      set (value) {
        this.$store.state.storeTrack.yearOptions = value
      }
    },
    dispatchStatus: {
      set (value) {
        this.$store.state.storeProduction.dispatchStatus = value
      },
      get () {
        return this.$store.state.storeProduction.dispatchStatus
      }
    }
  },
  methods: {
    print () {
      // Pass the element id here
      this.$htmlToPaper('imprimir')
    },
    print2 () {
      // Pass the element id here
      this.$htmlToPaper('imprimir2')
    },
    modalListClose: function () {
      this.$bvModal.hide('modal-list-production')
    },
    removeText () {
      if (document.getElementsByClassName('textLayer')[0]) {
        document.getElementsByClassName('textLayer')[0].hidden = true
      }
    },
    reload () {
      setTimeout(function () {
        location.reload()
      }, 100)
    },
    renderPdf (urlSent) {
      this.currentDataSheetURL = url + 'media/' + urlSent.split('/media/')[1]
      window.open(this.currentDataSheetURL, '_blank')
    },
    showBlock: function () {
      this.$store.state.editarReferenciaPlan = ''
      this.$store.state.ProductionPurchase = ''
      this.$store.state.storeProduction.codeSelect = null
      this.$store.state.referenciaRemision = null
      this.$store.state.codeSiesa = ''
      this.$store.state.storeProduction.dispatchStatus = { value: null, text: '' }
      this.$store.state.productionBatch = ''
      this.$store.state.cantidadPlan = 0
      this.$store.state.storeProduction.packingsLists = null
      this.$store.state.editarReferenciaPlan = false
      this.$store.commit('actionBlockOptions', true)
      this.$parent.overlayBody('on')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    showBlockEdit: function (info) {
      this.$store.state.editarReferenciaPlan = true
      this.$store.state.idremission = info.id
      this.$store.state.ProductionPurchase = info.oc
      this.$store.state.storeProduction.codeSelect = info.Op
      this.$store.state.codeSiesa = info.codigo_siesa
      this.$store.state.storeProduction.dispatchStatus = { value: info.status_pk, text: info.status }
      this.$store.state.productionBatch = info.batch
      this.$store.state.cantidadPlan = info.quantity
      this.$store.state.storeProduction.packingsLists = info.packing_list
      this.$store.commit('actionBlockOptions', true)
      this.$parent.overlayBody('on')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
      this.$store.state.referenciaRemision = { ref: info.reference, lote: info.batch, text: info.reference + ' - ' + info.batch }
      this.$store.state.list_referencias = []
      axios.post('/api_orders/getReferencesByOp/', { OP: info.Op }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        response.data.forEach((item, index) => {
          this.$store.state.list_referencias.push({ ref: item.product_code__reference, lote: item.batch, text: item.product_code__reference + ' - ' + item.batch })
        })
      }).catch((error) => {
        console.error(error)
      })
    },
    close: function (value) {
      this.$store.commit('actionBlockOptions', value)
      this.$parent.overlayBody('off')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    updateTrack () {
      this.$store.state.storeTrack.updateTrack = true
    },
    is_leader () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    },
    close_modal: function () {
      this.$refs['close-modal'].hide()
    },
    setTrazToAction: function (item) {
      this.$store.commit('actionSetOpToAction', item)
    },
    infoLoaded () {
      if (this.codeList.length !== 0) {
        return true
      } else {
        return false
      }
    },
    close_sub_modal () {
      this.$refs['close-sub-modal'].hide()
    },
    close_pdf_modal () {
      this.$refs['pdf-modal'].hide()
    },
    async setdataOrderStatus () {
      const self = this
      self.startRemission = []
      await axios.get('/api_orders/StartRemission/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.startRemission = response.data.data
        self.monthOp = response.data.month
        self.yearOp = response.data.year
        self.monthOptions = response.data.monthOptions
        self.yearOptions = response.data.yearOptions
        self.load = false
      })
    },
    async applyFiltersRemission () {
      const self = this
      self.load = true
      self.startRemission = []
      await axios.post('/api_orders/StartRemission/', {
        packing_list: self.list,
        monthRemission: self.monthOp,
        yearRemission: self.yearOp,
        remission: self.note,
        status: self.status,
        op: self.codeOp
      }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.startRemission = response.data
        self.load = false
      })
    },
    is_admin () {
      if (sessionStorage.getItem('rol') === '1') {
        return true
      } else {
        return false
      }
    },
    async detailPackinglist (packinglist) {
      await axios.post('/api_orders/StartRemission/', { packing_list: packinglist }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        response.data.forEach((element, index) => {
          this.observationsarray = element.Remission[0].observations
          this.datearray = element.Remission[0].date
          this.consecutivoarray = element.PackingList
          this.detaildata = element.Remission
        })
      })
    },
    async detailRemission (remission) {
      await axios.post('/api_orders/StartRemissiondetail/', { id: remission }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        this.detaildata = response.data.Remission
        this.datedetail = response.data.Remission[0].date
      })
    }
  }
}
</script>
<style lang="scss">
.action-page2 {
  background-color: #39C2A1;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
  font-weight: 600;
  border-radius: 10px;
  border: 0;
  font-size: 1.2rem;
}

.action-page2:hover {
  background-color: #31b093;
}

.action-page2:focus {
  background-color: #31b093;
}

.action-page2:active {
  background-color: #31b093;
}

.list-track {
  thead {
    tr {
      background-color: #F5F6F8;
      border-bottom: 1px solid #ccc;

      th.row-op {
        background-color: #39C2A1;
        color: #FFF;
      }

      th.row-oc {
        background-color: #03185A;
        color: #FFF;
      }
    }
  }

  tbody {
    tr.subhead-titles {
      background-color: #FFF;
      border: 0;
    }

    td {
      vertical-align: middle;

      &.multiple-register {
        span {
          border-bottom: 1px solid #8e8e8e;
          padding-bottom: 5px;

          &:last-child {
            border: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 950.98px) {
  #detail-task {
    margin-left: 0;

    .modal-dialog {
      max-width: 90%;
      margin-left: 10%;
      margin-right: 10%;
    }
  }
}

@media (min-width: 950.98px) {

  #detail-task,
  #pdf-modal {
    margin-left: 0;

    .modal-dialog {
      max-width: 90%;
      margin-left: 15%;
      margin-right: 10%;
    }
  }
}

@media (max-width: 834px) {
  .filters {
    font-size: 10px;
  }
  .mx-3 {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .btn-action-page {
    width: 161px;
    height: 40px;
    font-size: 13px;
  }
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

button,
input,
optgroup,
select,
textarea {
  padding-left: 10px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.btn-refresh {
  // background-color: #39C2A1;
  background-color: #03185A;
  display: flex;
  box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
  font-weight: 600;
  border-radius: 10px;
  border: 0;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  margin-bottom: 1rem;
}

.my-class-form-control-group {
  display: flex;
  align-items: center;
  margin-left: 60vw;
  padding-bottom: 2rem;
}

.titleFilterNote {
  margin-right: 10rem;
}

.logopro {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}
</style>
