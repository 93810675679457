<template>
  <div class="w-100" :class="'list-' + state">
    <div class="item-page-list">
      <div v-if="data">
        <div class="text-left mt-3 d-md-flex align-items-center header-list-production">
          <div class="header-column-left">
            <span class="icon-list align-middle" :class="[state !== 'tomorrow' ? class_icon_color : '']" :style="{ 'background-color': tomorrowIconColor }"><i :class="{ 'fa-exclamation' : state === 'warning', 'fa-exclamation' : state === 'danger', 'fa-check' : state === 'success', 'fa-eject': state === 'tomorrow', 'fa-list' : state === 'rango' }" class="fas"></i></span>
            <span v-if="(state == 'success')" class="mx-3 h5 align-middle" :class="'color-' + color">Por maquilar</span>
            <span v-if="state == 'warning'" class="mx-3 h5 align-middle" :class="'color-' + color">Producción pendiente</span>
            <span v-if="state == 'danger'" class="mx-3 h5 align-middle" :class="'color-' + color">Producción critica</span>
            <span v-if="(state == 'tomorrow')" class="mx-3 h5 align-middle" :class="'color-' + color">Maquilado</span>
            <span class="mx-3 date-production align-middle" :class="'color-' + color">{{data.date}}<span v-if="state == 'danger'" class="mx-3 date-production align-middle" :class="'color-' + color">↓</span></span>
            <b-badge variant="danger align-middle">{{data.data.length}}</b-badge>
          </div>
          <div class="header-column-right d-flex align-items-center" :class="{'justify-content-lg-between': limit, 'justify-content-lg-end': !limit}">
            <div class="d-flex align-items-center">
                <span class="mr-3 h6" align="middle">Total horas producción</span>
                <span class="hours-production mr-3">{{totalHours * 4}}</span>
              </div>
              <div v-if="limit">
                <b-button @click="$bvModal.show('modal-list-production');list(state);" class="bg-transparent text-info border-0 p-0">Ver todos</b-button>
              </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="w-100 table-list-production">
            <thead class="border-top">
              <tr>
                <th style="width:80px;font-weight: 600;">OP</th>
                <th style="border-left: 1px solid #ccc; width: 180px; font-weight: 600;">Fecha</th>
                <th style="width:11rem;font-weight: 600;" class="border-left-head">Total Horas</th>
                <th style="width:11rem;font-weight: 600;" class="border-left-head">Cód. Producto</th>
                <th style="width:30rem;font-weight: 600;" class="border-left-head">Descripción</th>
                <th style="width:80px;font-weight: 600;" class="border-left-head">Cantidad</th>
                <th style="width:80px;font-weight: 600;" class="border-left-head">Cantidad Maquilada</th>
                <th style="width:9px;font-weight: 600;" class="border-left-head">Lote</th>
                <th style="width:80px;font-weight: 600;" class="border-left-head">Horas</th>
                <th style="width:80px;font-weight: 600;" class="border-left-head">Estado</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in data.data.slice(startIndex, endIndex)" >
                  <tr v-for="(subitem, i) in item.Referencias" :key="subitem.pk_productsOp">
                    <td v-if="i === 0" :rowspan="item.Referencias.length">
                      <b-button variant="link" @click="$bvModal.show('detail-order');modalListClose();putCurrentTaskForBsn(item.OP)" class="text-dark font-weight-bold" :class="{ 'countLenght' : state === 'success' }">{{ item.OP }}
                      </b-button>
                    </td>
                    <td v-if="i === 0" :rowspan="item.Referencias.length">
                      {{ item.Fecha }}
                    </td>
                    <td v-if="i === 0" :rowspan="item.Referencias.length">
                      {{ item.total_horas }}
                    </td>
                    <td>
                      {{ subitem.codigo_producto }}
                    </td>
                    <td>
                      {{ subitem.descripcion }}
                    </td>
                    <td>
                      {{ subitem.cantidad }}
                    </td>
                    <td>
                      {{ subitem.end_quantity }}
                    </td>
                    <td>
                      {{ subitem.lote }}
                    </td>
                    <td>
                      {{ subitem.horas }}
                    </td>
                    <td>
                      <span v-if="subitem.complete == true" class="font-weight-bold color-green">
                        Completado
                      </span>
                      <span v-else class="font-weight-bold color-red">
                        No Completado
                      </span>
                    </td>
                    <td>
                      <div v-if="subitem.complete == false">
                        <b-button @click="completeProductsByProduccionOrder(subitem.pk_productsOp)" variant="link" class="text-info" v-if="state == 'success'"><i class="fa fa-check text-success"></i></b-button>
                      </div>
                    </td>
                    <td v-if="(state != 'rango' && state != 'tomorrow') && i === 0" :rowspan="item.Referencias.length">
                      <div>
                        <b-button variant="link" @click="$emit('edit'); modalListClose(); setInfoRefToAction(item.codigo_producto, data.date); setFormInfoBsn(item.OP)" v-if="state == 'success' || state === 'tomorrow'"><i class="far fa-edit text-success"></i></b-button>
                        <b-button @click="$bvModal.show('update-task'); modalListClose(); setInfoRefToAction(item.codigo_producto, data.date);" variant="link" class="text-info" v-if="state == 'warning'"><i class="fas fa-sync-alt"></i></b-button>
                        <b-button @click="$bvModal.show('update-task'); modalListClose(); setInfoOpToAction(item.OP, item.wp_date, item.codigo_producto);" variant="link" class="text-info" v-if="state == 'danger'"><i class="fas fa-sync-alt"></i></b-button>
                      </div>
                    </td>
                    <td v-if="state != 'rango' && i === 0" :rowspan="item.Referencias.length">
                      <div>
                        <b-button v-if="state == 'danger'" variant="link" @click="$bvModal.show('delete-task'); modalListClose(); setInfoOpToAction(item.OP, item.wp_date, item.codigo_producto)"><i class="far fa-trash-alt text-success"></i></b-button>
                        <b-button @click="setInfoOpToAction(item.OP, item.wp_date, item.codigo_producto); updateProductionOrder(item.OP)" variant="link" class="text-info" v-if="state == 'success'" ><i class="fa fa-check text-success"></i></b-button>
                      </div>
                    </td>
                  </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="pb-3" v-if="state === 'success' && !modal">
          <b-button class="btn-action-page py-2" @click="$emit('edit')" size="lg">Agregar tarea</b-button>
        </div>
        <!-- <div class="list-pagination" v-if="modal">
          <b-pagination :total-rows="data.data.length" v-model="pag" :per-page="NUM_RESULTS" pills align="center"
            hide-ellipsis hide-goto-end-buttons></b-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import draggable from 'vuedraggable'
const { url } = require('../../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url

export default {
  name: 'ListProduction',
  components: {
    // draggable
  },
  props: {
    state: String,
    color: String,
    data: Object,
    limit: Number,
    startIndex: Number,
    endIndex: Number,
    modal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      items: this.data.data,
      orders: [],
      production: this.$store.state.infoProduction,
      perPage: this.limit,
      currentPage: 1,
      class_icon_color: 'icon-' + this.color,
      class_color: 'color-' + this.color,
      class_border_color: 'border-lf-' + this.color
    }
  },
  async mounted () {},
  computed: {
    infoTomorrow () {
      return this.$store.state.storeProduction.infoTomorrow
    },
    infoProduction () {
      return this.$store.state.storeProduction.infoProduction
    },
    infoPendiente () {
      return this.$store.state.storeProduction.infoPendiente
    },
    infoCritico () {
      return this.$store.state.storeProduction.infoCritico
    },
    totalHours () {
      const self = this
      var total = 0
      if (self.data != null && self.data.data != null) {
        self.data.data.forEach(function (item, index) {
          total += item.total_horas
        })
        return total.toFixed(2)
      } else {
        return 0
      }
    },
    tomorrowIconColor () {
      if (this.state === 'tomorrow') {
        return '#00b7ff'
      } else {
        return ''
      }
    },
    module_op () {
      return this.$store.state.storeProduction.module_op
    }
  },
  methods: {
    async update () {
      this.items.map((item, index) => {
        item.order = index + 1
        this.orders[index] = { order: item.order, pk: item.pk }
      })
      await axios.post('/api/startworkplanorder/', { order: this.orders })
        .then((response) => {
        })
      await this.$store.dispatch('getInfoProduction')
    },
    list: function (list) {
      this.$store.commit('actionListCurrent', list)
    },
    emitGetProjects () {
      this.$root.$emit('selectRef')
    },
    modalListClose: function () {
      this.$bvModal.hide('modal-list-production')
    },
    putCurrentTask: function (task) {
      var result = this.data.data.filter(function (value, index, arr) {
        if (value.reference === task) {
          return arr
        }
      })
      this.$store.commit('actionSetCurrentTask', result)
    },
    putCurrentTaskForBsn: function (task) {
      var result = this.data.data.filter(function (value, index, arr) {
        if (value.OP === task) {
          return arr
        }
      })
      this.$store.commit('actionSetCurrentTask', result)
    },
    setInfoRefToAction: function (ref, date) {
      this.$store.commit('actionSetRefToDel', ref)
      this.$store.commit('actionSetRefDateToDel', date)
    },
    setInfoOpToAction: function (codeOp, date, ref) {
      this.$store.commit('actionSetOpToDel', codeOp)
      this.$store.commit('actionSetOpDateToDel', date)
      this.$store.commit('actionSetReferenciaBsn', ref)
    },
    async refInTraz (ref) {
      var exist = false
      await axios.post('/api/getTraceabilities/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        response.data.forEach(function (traz, index) {
          if (traz.trazability.product_code === ref) {
            exist = true
          }
        })
      })
      return exist
    },
    async setFormInfo () {
      const self = this
      await axios.post('/api/getdatasheettoform/', {
        reference: self.$store.state.storeProduction.refToDel,
        date: self.$store.state.storeProduction.refDateToDel
      }).then(function (response) {
        self.$store.commit('actionSetFichaTecnica', url + 'media/' + response.data.file.split('/media/')[1])
      }).catch(function (error) {
        alert(error)
      })
      if (this.state === 'success') {
        this.$store.state.storeProduction.infoProduction.data.forEach(function (item, index) {
          if (item.reference === self.$store.state.storeProduction.refToDel) {
            self.$store.state.storeProduction.referencia.ref = item.reference
            self.$store.state.storeProduction.referencia.nombre = item.name
            self.$store.commit('actionSetCantidad', item.start_quantity)
            self.$store.commit('actionSetOP', item.code_op)
            self.$store.commit('actionSetOC', item.code_oc)
            self.$store.state.horasPlan = item.hours
            self.$store.commit('actionSetBases', item.bases)
            self.$store.commit('actionSetInsumos', item.supplies)
            self.$store.commit('actionSetLotes', item.batchs)
            self.$store.state.storeProduction.date = self.$store.state.storeProduction.infoProduction.date
          }
        })
      } else if (this.state === 'tomorrow') {
        this.$store.state.storeProduction.infoTomorrow.data.forEach(function (item, index) {
          if (item.reference === self.$store.state.storeProduction.refToDel) {
            self.$store.state.storeProduction.referencia.ref = item.reference
            self.$store.state.storeProduction.referencia.nombre = item.name
            self.$store.commit('actionSetCantidad', item.start_quantity)
            self.$store.commit('actionSetOP', item.code_op)
            self.$store.commit('actionSetOC', item.code_oc)
            self.$store.commit('actionSetHoras', item.hours)
            self.$store.commit('actionSetBases', item.bases)
            self.$store.commit('actionSetInsumos', item.supplies)
            self.$store.commit('actionSetLotes', item.batchs)
            self.$store.state.storeProduction.date = self.$store.state.storeProduction.infoTomorrow.date
          }
        })
      }
      this.$store.state.dataReferencias.forEach(function (item, index) {
        if (item.ref === self.$store.state.storeProduction.refToDel) {
          self.$store.commit('actionSetReferencia', item)
        }
      })
      this.$store.commit('actionSetEditarReferenciaPlan', true)
      this.emitGetProjects()
    },
    async setFormInfoBsn (code) {
      const self = this
      this.$store.state.storeProduction.infoProduction.data.forEach(function (item, index) {
        if (item.OP === code) {
          self.$store.state.dataPlanProduccion.op = item.OP
          self.$store.state.dataPlanProduccion.date = item.Fecha
          console.log(item.Referencias)
          item.Referencias.forEach((element, index) => {
            self.$store.state.dataPlanProduccion.rowsPlanProduccion.splice(index, 1, { codigo: { nombre: element.descripcion, ref: element.codigo_producto }, descripcion: element.descripcion, cantidad: element.cantidad, quantityWorked: element.end_quantity, lote: element.lote, horas: element.horas, pk_productsOp: element.pk_productsOp, ficha: null, fichaName: '' })
          })
          self.$store.commit('actionSetFichaTecnica', item.Ficha_tecnica)
        }
      })
      this.$store.commit('actionSetEditarReferenciaPlan', true)
    },
    async updateProductionOrder (codeOp) {
      await axios.post('/api_orders/updateProductionOrder/', { codeOp: codeOp }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      }).catch(function (error) {
        console.log(error)
      })
      await this.$store.dispatch('getInfoProduction')
    },
    async completeProductsByProduccionOrder (pkProductsOp) {
      await axios.post('/api/completeProductsByProduccionOrder/', { pk: pkProductsOp }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
        .then(function (response) {
        })
        .catch(function (error) {
          console.log(error)
        })
      await this.$store.dispatch('getInfoProduction')
    }
  }
}
</script>

<style lang="scss">
 .header-list-production {
  background: #fbfbfb;
  padding: 15px 10px;
  border-left-color: #00b7ff;
 }
 .b-form-input {
  border: 1px solid #363D8A;
  border-radius: 8px;
  height: 45px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
 }
.btn-filter-search {
  background-color: #03185A;
  color: #fff;
  border-radius: 10px;
  height: 45px;
}
.btn-filter-search:hover {
  background-color: #39C2A1;
  color: #fff;
}
tbody {
  tr.subhead-titles {
    background-color: #FFF;
    border: 0;
  }
  td {
    vertical-align: middle;
    &.multiple-register {
      span {
        border-bottom: 1px solid #8e8e8e;
        padding-bottom: 5px;
        &:last-child {
          border: 0;
        }
      }
    }
  }
}
</style>
