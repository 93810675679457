<template>
  <div class="form-picking">
    <div>
      <div class="row box-option mx-4 center">
        <div class="col-md-12 py-4">
          <div class="text-left">
            <div class="form-group row">
              <label for="example-date-input" class="col-2 col-form-label font-weight-bold">Código OT</label>
              <div class="col-10">
                <input class="form-control" v-model="ot" id="ot" type="number"
                  placeholder="Ingrese un código de transporte">
              </div>
            </div>
            <div class="form-group row">
              <label for="example-date-input" class="col-2 col-form-label font-weight-bold">Fecha</label>
              <div class="col-10">
                <input class="form-control" v-model="dateBsn" id="dateBsn" type="date"
                  :disabled="this.$store.state.editarReferenciaPlan ? '' : false" :min="dateTwoMonthsBefore" :max="dateTwoMonthsAfter" @change="validateRangeDate()">
              </div>
            </div>
            <table id="tablePicking" class="table mt-5" style="text-align: center;">
              <thead>
                <tr>
                  <td><strong>Código material</strong></td>
                  <td><strong>Descripción</strong></td>
                  <td><strong>Código Op</strong></td>
                  <td><strong>Lote</strong></td>
                  <td><strong>Cantidad de OT</strong></td>
                  <td><strong>Cantidad Recibida</strong></td>
                  <td><strong>Acción</strong></td>
                  <td></td>
                </tr>
              </thead>
              <tbody id="tbody">
                <tr v-for="(row, r) in this.$store.state.storeInventory.rowsFormPicking" :key="r">
                  <td class="text-center">
                    <multiselect v-model="row.material" id="codeMaterial" :show-labels="false" :options="dataMaterial"
                      placeholder="Código"
                      @select="material(r, $event), DescriptionPiking(row.material, r)">
                    </multiselect>
                  </td>
                  <td class="text-center">
                    <b-form-input type="text" v-model="row.descripcion
                    " id="descripcion" @change="descripcion(r, $event)" :value="getDescripcion(r)" hidden>
                    </b-form-input>
                    {{ pikingDescripcion.descripcions[r] }}
                  </td>
                  <td class="text-center">
                    <b-form-input type="number" v-model="row.op" min="0" id="codeOp" @change="code_op(r, row.op)">
                    </b-form-input>
                  <!-- <multiselect v-model="row.op" id="codeOp" :options="dataOp" :show-labels="false"
                    placeholder="Seleccione un codigo op" label="text" track-by="text"
                    @select="Viewsordersproduction(r), code_op(r, row.op.value)">
                  </multiselect> -->
                </td>
                <td class="text-center">
                  <b-form-input type="text" v-model="row.lote" id="lote" @change="lote(r, row.lote)"></b-form-input>
                  <!-- <b-form-input v-if="inputlote[r] === true" type="text" v-model="row.lote" id="lote" @change="lote(r, $event)"></b-form-input>
                  <div v-if="textlote[r] === true">{{ pikingLotes.lotes[r] }}</div>
                  <multiselect v-if="selectlote[r] === true" v-model="row.lote" id="codeMaterial" :show-labels="false" :options="dataLotes"
                    placeholder="Seleccione un codigo material" label="text" track-by="text" @select="lote(r, row.lote.value)">
                  </multiselect> -->
                </td>
                  <td class="text-center">
                    <b-form-input type="number" min="0" v-model="row.ordenTransporte" id="orderTransportation"
                      @change="ordenTransporte(r, $event)" :value="getOt(r)"></b-form-input>
                  </td>
                  <td class="text-center">
                    <b-form-input type="number" min="0" v-model="row.recibida" id="recibida" @change="recibida(r, $event)"
                      :value="getRecibida(r)"></b-form-input>
                  </td>
                  <td>
                    <b-button variant="link" @click="removeRow(r)"><i
                        class="far fa-trash-alt text-danger"></i></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="text-align: center;">
              <b-button class="btn-action-page py-2" size="sm" id="addRow" @click="addRow()"><i class="fa fa-plus"
                  aria-hidden="true"></i></b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  name: 'FormPicking',
  components: {
    Multiselect
  },
  data () {
    return {
      inputlote: [],
      textlote: [],
      selectlote: [],
      inputOt: {
        material: [],
        descripcion: [],
        lote: [],
        ordenTransporte: [],
        recibida: []
      }
    }
  },
  computed: {
    ot: {
      get () {
        return this.$store.state.storeInventory.OrdenTransportePk
      },
      set (value) {
        this.$store.state.storeInventory.OrdenTransportePk = value
      }
    },
    op: {
      get () {
        return this.$store.state.storeInventory.OrdenProducion
      },
      set (value) {
        this.$store.state.storeInventory.OrdenProducion = value
      }
    },
    dateBsn: {
      get () {
        return this.$store.state.storeProduction.dateBsn
      },
      set (value) {
        this.$store.state.storeProduction.dateBsn = value
      }
    },
    dateTwoMonthsBefore: {
      get () {
        return this.$store.state.storeProduction.dateTwoMonthsBefore
      }
    },
    dateTwoMonthsAfter: {
      get () {
        return this.$store.state.storeProduction.dateTwoMonthsAfter
      }
    },
    rowsFormPicking: {
      get () {
        return this.$store.state.storeInventory.rowsFormPicking
      },
      set (value) {
        this.$store.state.storeInventory.rowsFormPicking = value
      }
    },
    pikingDescripcion: {
      get () {
        return this.$store.state.storeInventory.pikingDescripcion
      },
      set (value) {
        this.$store.state.storeInventory.pikingDescripcion.descripcions = value
      }
    },
    pikingLotes: {
      get () {
        return this.$store.state.storeInventory.pikingLotes
      },
      set (value) {
        this.$store.state.storeInventory.pikingDescripcion.lotes = value
      }
    },
    CodigoOplotef: {
      get () {
        return this.$store.state.storeInventory.CodigoOplotef
      },
      set (value) {
        this.$store.state.storeInventory.CodigoOplotef.codigos = value
      }
    },
    codeMaterial: {
      set (value) {
        this.$store.state.storeProduction.codeMaterial = value
      },
      get () {
        return this.$store.state.storeProduction.codeMaterial
      }
    },
    codeLotes: {
      set (value) {
        this.$store.state.storeProduction.codeLotes = value
      },
      get () {
        return this.$store.state.storeProduction.codeLotes
      }
    },
    dataMaterial () {
      return this.$store.state.dataMaterial
    },
    dataLotes () {
      return this.$store.state.dataLotes
    },
    dataOp () {
      return this.$store.state.dataOp
    }
  },
  mounted () {
    const self = this
    self.$store.state.dataMaterial = []
    axios
      .get('/api/supplies/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
      .then(function (response) {
        response.data.forEach(function (item, inde) {
          var arrayCode = item.id
          self.$store.commit('actionSetDataMaterial', arrayCode)
        })
      })
    self.$store.state.dataOp = []
    // axios
    //   .get('/api_inventory/viewsordersproduction/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
    //   .then(function (response) {
    //     response.data.forEach(function (item, inde) {
    //       var arrayCode = { value: item.production_order_code, text: item.production_order_code }
    //       self.$store.commit('actionSetDataOp', arrayCode)
    //     })
    //   })
  },
  methods: {
    addRow: function () {
      this.$store.state.storeInventory.rowsFormPicking.push({ material: '', descripcion: '', op: '', lote: '', ordenTransporte: '', recibida: '' })
    },
    removeRow: function (index) {
      this.$store.state.storeInventory.rowsFormPicking.splice(index, 1)
    },
    getMaterial (index) {
      return this.$store.state.storeInventory.inputOt.material[index]
    },
    getDescripcion (index) {
      return this.$store.state.storeInventory.inputOt.descripcion[index]
    },
    getLote (index) {
      return this.$store.state.storeInventory.inputOt.lote[index]
    },
    getOt (index) {
      return this.$store.state.storeInventory.inputOt.ordenTransporte[index]
    },
    getRecibida (index) {
      return this.$store.state.storeInventory.inputOt.recibida[index]
    },
    material: function (index, value) {
      this.$store.commit('actionSetInputMaterial', { index, value })
    },
    descripcion: function (index, value) {
      this.$store.commit('actionSetInputDescripcion', { index, value })
    },
    lote: function (index, value) {
      this.$store.commit('actionSetInputLoteOrdenTransporte', { index, value })
    },
    code_op: function (index, value) {
      this.$store.commit('actionSetInputOpOrdenTransporte', { index, value })
    },
    ordenTransporte: function (index, value) {
      this.$store.commit('actionSetInputOt', { index, value })
    },
    recibida: function (index, value) {
      this.$store.commit('actionSetInputRecibida', { index, value })
    },
    validateRangeDate () {
      const dateChosed = this.$store.state.dataPlanProduccion.date
      const dateBefore = this.dateTwoMonthsBefore
      const dateAfter = this.dateTwoMonthsAfter
      if (!(dateChosed >= dateBefore & dateChosed <= dateAfter)) {
        this.dateBsn = dateBefore
      }
    },
    async DescriptionPiking (material, position) {
      await axios.get('/api/supplies/' + material, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        if (response.data.description === undefined) {
          this.$bvToast.toast('El código material no contiene una descripcion', {
            title: 'Codigo Material',
            variant: 'danger',
            autoHideDelay: 5000,
            solid: true
          })
          this.pikingDescripcion.descripcions.splice(position, 0, response.data.description)
        } else {
          this.pikingDescripcion.descripcions.splice(position, 0, response.data.description)
        }
      }).catch(function (error) {
        console.log(error)
      })
    }
    // async Viewsordersproduction (position) {
    //   this.$store.state.storeInventory.rowsFormPicking.forEach(element => {
    //     var supplies = element.material
    //     var codeOp = element.op
    //     if (supplies === '') {
    //     } else if (codeOp === '') {
    //     } else if (codeOp === '' && supplies === '') {
    //     } else {
    //       axios.post('/api_inventory/viewsordersproduction/', { code_op: codeOp.value, supplies: supplies }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
    //         if (response.data.length > 1) {
    //           this.$store.state.dataLotes = []
    //           response.data.forEach((lotes, inde) => {
    //             var arrayCode = { value: lotes.batch, text: lotes.batch }
    //             this.$store.commit('actionSetDataLotes', arrayCode)
    //           })
    //           this.pikingLotes.lotes.splice(position, 1, '')
    //           this.selectlote.splice(position, 1, true)
    //           this.inputlote.splice(position, 1, false)
    //           this.textlote.splice(position, 1, false)
    //         } else {
    //           response.data.forEach((item) => {
    //             if (item.batch === '') {
    //               this.pikingLotes.lotes.splice(position, 1, '')
    //               this.inputlote.splice(position, 1, true)
    //               this.textlote.splice(position, 1, false)
    //               this.selectlote.splice(position, 1, false)
    //             } else {
    //               this.inputlote.splice(position, 1, false)
    //               this.textlote.splice(position, 1, true)
    //               this.selectlote.splice(position, 1, false)
    //               element.lote = item.batch
    //               this.lote(position, item.batch)
    //               this.pikingLotes.lotes.splice(position, 1, item.batch)
    //             }
    //           })
    //         }
    //       }).catch(function (error) {
    //         console.log(error)
    //       })
    //     }
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>
.box-simple {
  background: #FFF;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.inputs {
  width: 42vh !important;
}

.title {
  margin-top: 1rem;
}

input {
  width: 100%;
  margin-bottom: 25px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: 0 1px 8px #0064b7;
  }
}

.imgBoton {
  margin: 10px;
}

.contenedor-imagenes {
  margin-left: 21vw
}

input[data-v-1af5163d] {
  width: 100%;
  margin-bottom: 5px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  align-items: center;
  margin-top: 5px;
}

.table-bordered {
  input {
    width: 100%;
    margin-bottom: 5px;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    align-items: center;
    margin-top: 5px;
  }
}
</style>
