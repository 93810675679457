<template>
    <div class="packing-list">
        <div>
            <div class="row box-option mx-4 center">
                <div class="col-md-10 py-5 pr-5">
                    <div class="text-left">
                        <p class="font-weight-bold">Observaciones</p>
                        <div class="py-4">
                         <!-- <input v-model="observations" id="observations" :disabled="this.$store.state.editarReferenciaPlan ? '': false"> -->
                         <textarea v-model="observations"  id="observations" style="width: 80%;height: 200px;" :disabled="this.$store.state.editarReferenciaPlan ? '': false"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/*
import dataproduct from '../../public/product.json'
*/
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  name: 'PackingList',
  props: {
    icon: String,
    data: Array,
    extraData: Array
  },
  data () {
    return {
      tag: '',
      tags: []
    }
  },
  computed: {
    module_op () {
      return this.$store.state.storeProduction.module_op
    },
    dataReferencias () {
      return this.$store.state.dataReferencias
    },
    referenciaObj () {
      return this.$store.state.storeProduction.referencia
    },
    codeOp: {
      set (value) {
        this.$store.state.ProductionOrder = value
      },
      get () {
        return this.$store.state.ProductionOrder
      }
    },
    bases () {
      return this.$store.state.storeProduction.bases
    },
    insumos () {
      return this.$store.state.storeProduction.insumos
    },
    lotes () {
      return this.$store.state.storeProduction.lotes
    },
    fichaTecnica: {
      get () {
        return this.$store.state.storeProduction.fichaTecnica
      },
      set (value) {
        this.$store.state.storeProduction.fichaTecnica = value
      }
    },
    observations: {
      set (value) {
        this.$store.state.storeProduction.observations = value
      },
      get () {
        return this.$store.state.storeProduction.observations
      }
    }
  },
  mounted () {
    const self = this
    if (sessionStorage.token) {
      self.$store.state.dataReferencias = []
      axios
        .get('/api/product/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
        .then(function (response) {
          response.data.forEach(function (item, index) {
            var data = { value: { nombre: item.name, ref: item.reference }, text: item.reference }
            self.$store.commit('actionSetDataReferencias', data)
          })
        })
      // this.$root.$on('selectRef', () => {
      //   this.selectReference(self.referencia)
      // })
    }
  },
  methods: {
    getImgUrl: function (pic) {
      return require('../assets/icons/' + pic + '.png')
    },
    cantidadMin: function () {
      const self = this
      if (this.cantidad !== 0) {
        this.cantidad--
      }
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
    },
    updateHour: function () {
      const self = this
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
    },
    cantidadMax: function () {
      const self = this
      this.cantidad++
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
    },
    // selectReference: function (id) {
    //   const self = this
    //   self.$store.state.referenciaPlan = id.ref
    //   axios.get('/api/productinfo/' + id.ref, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
    //     self.$store.commit('actionSetBases', response.data.Bases)
    //     self.$store.commit('actionSetInsumos', response.data.Supplies)
    //     self.$store.commit('actionSetLotes', response.data.Batchs)
    //     if (self.referencia != null) {
    //       self.ppk_standard = response.data.Product.ppk_standard
    //       var personStandard = self.ppk_standard
    //       var hours = (self.cantidad * 60 / personStandard) / 60
    //       self.$store.state.horasPlan = Number(hours).toFixed(2)
    //     }
    //   })
    // },
    fileFichaTecnica: function (event) {
      const self = this
      var reader = new FileReader()
      reader.onload = function (e) {
        self.fichaTecnica = e.target.result
      }
      reader.readAsDataURL(event.target.files[0])
    },
    deleteFile: function () {
      document.getElementById('fileFichaTecnica').value = ''
      this.$store.commit('actionSetFichaTecnica', '')
    },
    close_modal_zoom: function () {
      this.$refs['close-modal'].hide()
    }
  }
}
</script>
<style lang="scss" scoped>
  // Plan de Producción -------------------------------------------------
  .box-simple {
    background: #FFF;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  .inputs {
    width: 42vh !important;
  }
  .title {
    margin-top: 1rem;
  }
  input {
    width: 100%;
    margin-bottom: 25px;
    padding: 8px 12px;
    // box-shadow: 0 1px 2px #0064b7;
    border: none;
    border-radius: 5px;
    &:hover, &:active, &:focus {
      outline: none;
      box-shadow: 0 1px 8px #0064b7;
    }
  }
  .tags {
    .tag {
        position: relative;
        display: inline-block;
        margin: 0 10px 10px 0;
        padding: 8px 30px 8px 12px;
        color: #fff;
        background: #03185a;
        border: 1px solid #03185a;
        border-radius: 5px;
        cursor: pointer;
        &:after {
          position: absolute;
          right: 8px;
          color: #39c2a1;
          content: '\00d7';
        }
      }
  }
</style>
