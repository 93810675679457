<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <div class="form-bsn">
      <link rel="stylesheet" href="https://unpkg.com/vue-select@latest/dist/vue-select.css">
      <div>
        <div class="row box-option mx-2">
            <div class="colform1 py-4 pr-4">
              <div class="text-left">
                  <p class="font-weight-bold">Número de OP</p>
                  <div class="py-3">
                    <input type="text" v-model="codeOp" id="codeOp" placeholder="Ingrese el número de la OP" :disabled="this.$store.state.editarReferenciaPlan ? '': false" >
                  </div>
                  <p class="font-weight-bold">Cód. Producto</p>
                  <div class="pb-5">
                    <multiselect v-model="referencia" :options="dataReferencias" :disabled="this.$store.state.editarReferenciaPlan ? '' : false"  @select="selectReference(referencia, true)" placeholder="Seleccione un codigo de producto" label="ref" track-by="ref" >
                    </multiselect>
                  </div>
                  <div class="form-group row">
                    <label for="example-date-input" class="col-2 col-form-label font-weight-bold">Fecha</label>
                    <div class="col-10">
                      <input class="form-control" v-model="dateBsn" type="date" :disabled="this.$store.state.editarReferenciaPlan ? '': false">
                    </div>
                  </div>
                  <div class="row align-items-center pt-3">
                    <div class="col-5 text-left">
                      <p class="font-weight-bold m-0">Cantidad</p>
                    </div>
                    <div class="col-7 d-flex text-center">
                        <b-form-input type="number" min="0" v-model="cantidad" @change="updateHour(),selectReference(referencia)"></b-form-input>
                    </div>
                    <div class="alert alert-danger mt-4 px-3 w-100" role="alert" v-if="cantidad < 0">* La cantidad no puede <br> ser menor a uno</div>
                  </div>
                  <div class="row align-items-center mt-4">
                    <div class="col-5 text-left">
                        <p class="font-weight-bold m-0">Horas de producción</p>
                    </div>
                    <div class="col-7">
                        <b-form-input  class="horas-input" v-model="horas" disabled></b-form-input>
                    </div>
                  </div>
                  <p class="font-weight-bold mt-5">Lote(s)</p>
                  <div class="py-3">
                    <input type="text" placeholder="Ingrese lote(s)-cantidad, ej: 12345678-100" v-model="lotes" @keyup="handleTyping" :disabled="this.$store.state.editarReferenciaPlan ? '': false">
                    <div class="tags" id="tags"><div v-for="(_tag, index) in batchList" :key="index" class="tag" @click="removeTag(index)">{{ _tag }}</div></div>
                  </div>
                  <div class="mt-4 text-left">
              <p class="font-weight-bold m-0">Ficha técnica</p>
              <div class="box-add-file mt-3 text-center">
                <div class="px-3 py-4" v-show="!fichaTecnica">
                  <label for="fileFichaTecnica"><img src="~@/assets/icons/uploadFile.png" class="img-fluid"> <br> Adjuntar archivo</label>
                  <input type="file" name="file" id="fileFichaTecnica" @change="fileFichaTecnica($event)" class="inputfile" />
                </div>
                <div class="col-10 m-auto pt-4" v-show="fichaTecnica">
                  <div>
                    <img :src="fichaTecnica" class="img-fluid m-auto" alt="">
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <b-button v-b-modal.zoom-file class="color-grayLight col" variant="link"><i class="fas fa-search-plus h3"></i></b-button>
                    <span class="h2 font-weight-light col color-grayLight"> | </span>
                    <b-button class="col" variant="link" @click="deleteFile()"><i class="fas fa-trash-alt text-danger h3"></i></b-button>
                  </div>
                </div>
              </div>
            </div>
              </div>
            </div>
            <div class="colform2 py-4 border-custom-left">
              <div class="text-left">
                  <p class="font-weight-bold border-bottom pb-2">Nombre</p>
                  <div class="p-3 box-simple" style="height:120px;">
                  {{ referencia && referencia.nombre}}
                  </div>
              </div>
              <div class="text-left mt-4" v-if="bases.length">
                  <p class="font-weight-bold border-bottom pb-2">Bases</p>
                  <table class="w-100 table-bases">
                      <tbody>
                      <tr v-for="base in bases" :key="base.reference">
                          <td><strong>{{base.reference}}</strong></td>
                          <td>{{base.name}}</td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <div class="table text-left mt-5" style="display: inline-grid;" v-if="insumos.length">
                  <p class="font-weight-bold border-bottom pb-2">Insumos</p>
                  <table>
                    <thead>
                      <tr style="text-align: center">
                        <th><span>Insumo</span></th>
                        <th><span>Nombre</span></th>
                        <th><span>Cantidad</span></th>
                        <th><span>Lote</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(insumo, i) in insumos" :key="insumo.id" >
                        <td class="text-center">
                          <b-form-input v-model="insumo.id" disabled hidden></b-form-input>
                          <div class="">{{ insumo.id }}</div>
                        </td>
                        <td class="text-center">
                          <b-form-input v-model="insumo.name" disabled hidden></b-form-input>
                          <div class="">{{ insumo.name }}</div>
                        </td>
                        <td class="text-center" style="width: 20%;" v-if="$store.state.editarReferenciaPlan === false">
                          <b-form-input :value="setCalculateQuantity(i)" disabled></b-form-input>
                          <!-- <b-form-input @change="quantity(i, $event), getCalculateQuantity(i)" :value="insumos[i].quantity" disabled></b-form-input> -->
                          <!-- <b-form-input @change="quantity(i, $event)" :value="getQuantity(i)" disabled></b-form-input> -->
                        </td>
                        <td class="text-center" v-else>
                          <div>{{ insumo.quantity }}</div>
                        </td>
                        <td class="text-center" style="width: 20%;" v-if="$store.state.editarReferenciaPlan === false">
                          <b-form-input @change="batch(i, $event)" :value="getBatch(i)"></b-form-input>
                        </td>
                        <td class="text-center" v-else>
                          <div>{{ insumo.batch }}</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </div>
            </div>
        </div>
      </div>
  </div>
</template>
<script>
/*
import dataproduct from '../../public/product.json'
*/
import Multiselect from 'vue-multiselect'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  name: 'FormBsn',
  props: {
    icon: String,
    data: Array,
    extraData: Array
  },
  components: {
    Multiselect
  },
  data () {
    return {
      tags: [],
      inputBsn: {
        quantity: [],
        batch: []
      }
    }
  },
  computed: {
    dateBsn: {
      get () {
        return this.$store.state.storeProduction.dateBsn
      },
      set (value) {
        this.$store.state.storeProduction.dateBsn = value
      }
    },
    cantidad: {
      set (value) {
        this.$store.state.cantidadPlan = value
      },
      get () {
        return this.$store.state.cantidadPlan
      }
    },
    codeOp: {
      set (value) {
        this.$store.state.ProductionOrder = value
      },
      get () {
        return this.$store.state.ProductionOrder
      }
    },
    horas: {
      set (value) {
        this.$store.state.horasPlan = value
      },
      get () {
        return this.$store.state.horasPlan
      }
    },
    referencia: {
      set (value) {
        this.$store.state.storeProduction.referencia = value
      },
      get () {
        return this.$store.state.storeProduction.referencia
      }
    },
    fichaTecnica: {
      get () {
        return this.$store.state.storeProduction.fichaTecnica
      },
      set (value) {
        this.$store.state.storeProduction.fichaTecnica = value
      }
    },
    lotes: {
      set (value) {
        this.$store.state.storeProduction.lotes = value
      },
      get () {
        return this.$store.state.storeProduction.lotes
      }
    },
    tagOfBatch: {
      set (value) {
        this.$store.state.storeProduction.tagOfBatch = value
      },
      get () {
        return this.$store.state.storeProduction.tagOfBatch
      }
    },
    batchList: {
      set (value) {
        this.$store.state.storeProduction.batchList = value
      },
      get () {
        return this.$store.state.storeProduction.batchList
      }
    },
    module_op () {
      return this.$store.state.storeProduction.module_op
    },
    dataReferencias () {
      return this.$store.state.dataReferencias
    },
    referenciaObj () {
      return this.$store.state.storeProduction.referencia
    },
    bases () {
      return this.$store.state.storeProduction.bases
    },
    insumos () {
      return this.$store.state.storeProduction.insumos
    }
  },
  mounted () {
    const self = this
    if (sessionStorage.token) {
      self.$store.state.dataReferencias = []
      axios
        .get('/api/product/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
        .then(function (response) {
          response.data.forEach(function (item, index) {
            var data = { nombre: item.name, ref: item.reference }
            self.$store.commit('actionSetDataReferencias', data)
          })
        })
    }
  },
  methods: {
    getQuantity (index) {
      return this.$store.state.storeTrack.inputBsn.quantity[index]
    },
    setCalculateQuantity (index) {
      this.insumos[index].quantity = Math.round(this.cantidad * this.insumos[index].calculate_quantity)
      return this.insumos[index].quantity
    },
    quantity: function (index, value) {
      this.$store.commit('actionSetInputQuantity', { index, value })
    },
    getBatch (index) {
      return this.$store.state.storeTrack.inputBsn.batch[index]
    },
    batch: function (index, value) {
      this.$store.commit('actionSetInputBatch', { index, value })
    },
    getImgUrl: function (pic) {
      return require('../assets/icons/' + pic + '.png')
    },
    cantidadMin: function () {
      const self = this
      if (this.cantidad !== 0) {
        this.cantidad--
      }
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
      self.updateHour()
    },
    updateHour: function () {
      const self = this
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
    },
    cantidadMax: function () {
      const self = this
      this.cantidad++
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
      self.updateHour()
    },
    selectReference: function (id, resetMainQuantity = false) {
      const self = this
      if (resetMainQuantity === true) {
        self.cantidad = 0
      }
      self.$store.state.referenciaPlan = id.ref
      axios.get('/api/productinfo/' + id.ref, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.$store.commit('actionSetBases', response.data.Bases)
        if (self.$store.state.editarReferenciaPlan === false) {
          self.$store.commit('actionSetInsumos', response.data.Supplies)
        }
        self.$store.commit('actionSetLotes', self.$store.state.storeProduction.lotes)
        self.$store.commit('actionInsumoBsn', response.data.Supplies)
        if (self.referencia != null) {
          self.ppk_standard = response.data.Product.ppk_standard
          var personStandard = self.ppk_standard
          var hours = (self.cantidad * 60 / personStandard) / 60
          self.$store.state.horasPlan = Number(hours).toFixed(2)
        }
      })
    },
    fileFichaTecnica: function (event) {
      const self = this
      var reader = new FileReader()
      reader.onload = function (e) {
        self.fichaTecnica = e.target.result
      }
      reader.readAsDataURL(event.target.files[0])
    },
    deleteFile: function () {
      document.getElementById('fileFichaTecnica').value = ''
      this.$store.commit('actionSetFichaTecnica', '')
    },
    close_modal_zoom: function () {
      this.$refs['close-modal'].hide()
    },
    /* tags */
    mySetFunction: function () {
      this.tagOfBatch = ''
    },
    addTag (tag) {
      const regex = /^\d{8}-\d{1,7}$/
      if (!regex.test(tag)) {
        this.$bvToast.toast('Por favor, ingrese un lote de 8 números y una cantidad que conste de al menos 1 número y máximo 7 números.', {
          title: 'Advertencia!',
          variant: 'warning',
          autoHideDelay: 5000,
          solid: true
        })
      } else {
        this.batchList.push(tag)
      }
      // if (!(/^\d{8}-\d{5}$/.test(tag)) && !(/^\d{8}-\d{4}$/.test(tag)) && !(/^\d{8}-\d{3}$/.test(tag)) && !(/^\d{8}-\d{2}$/.test(tag))) {
      //   this.$bvToast.toast('Por favor, ingrese un lote y cantidad válidas.', {
      //     title: 'Error!',
      //     variant: 'danger',
      //     autoHideDelay: 5000,
      //     solid: true
      //   })
      // } else {
      //   this.batchList.push(tag)
      // }
    },
    removeTag (index) {
      this.batchList.splice(index, 1)
      this.tagOfBatch = ''
    },
    tagExists (tag) {
      return this.batchList.indexOf(tag) !== -1
    },
    handleTyping (e) {
      if (e.keyCode === 13) {
        this.tagOfBatch = this.$store.state.storeProduction.lotes.replace(/,/, '-')
        if (!this.tagExists(this.tagOfBatch)) {
          this.addTag(this.tagOfBatch)
          this.mySetFunction()
          this.tagOfBatch = ''
        }
        this.lotes = ''
      }
    }
    // getLenghtOp (reference) {
    //   axios.post('/api/ClientLenOP/', { referencia: reference.ref }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
    //     .then((response) => {
    //       console.log(response.data.length_op)
    //       this.$store.state.storeProduction.length_op = response.data.length_op
    //     })
    // }
  }
}
</script>
<style lang="scss" scoped>
// Plan de Producción -------------------------------------------------
.box-simple {
  background: #FFF;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
}
.colform1 {
  position: relative;
  width: 30%;
  padding-right: 15px;
  padding-left: 15px;
}

.colform2 {
  position: relative;
  width: 70%;
  padding-right: 15px;
  padding-left: 15px;
}
.inputs {
  width: 42vh !important;
}
.title {
  margin-top: 1rem;
}
input {
  width: 100%;
  margin-bottom: 25px;
  padding: 8px 12px;
  // box-shadow: 0 1px 2px #0064b7;
  border: none;
  border-radius: 5px;
  &:hover, &:active, &:focus {
    outline: none;
    box-shadow: 0 1px 8px #0064b7;
  }
}
.tags {
  .tag {
      position: relative;
      display: inline-block;
      margin: 0 10px 10px 0;
      padding: 8px 30px 8px 12px;
      color: #fff;
      background: #03185a;
      border: 1px solid #03185a;
      border-radius: 5px;
      cursor: pointer;
      &:after {
        position: absolute;
        right: 8px;
        color: #39c2a1;
        content: '\00d7';
      }
    }
}
.textores {
  width: 100px;
  /* Control de la altura con base en el texto del div*/
  height: auto;
  word-wrap: break-word;
}
</style>
