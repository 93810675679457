<template>
  <div v-if="data !== undefined" class="form-control-calidad">
    <div class="header-form text-center py-3">
      <div class="h5">Verificación</div>
    </div>
    <div class="d-flex mb-4">
      <div class="col-6 p-4">
        <pdf v-if="urlDataSheet !== undefined" :src="urlDataSheet" id="pdf_view" :resize="true" :annotation="false" :text="false" @error="presetErrorPdf">
          <template slot="loading">
            Cargando ficha técnica...
          </template>
          <template slot="no_found">
            No se encontró la ficha técnica.
          </template>
        </pdf>
        <div v-else>No se encontró alguna ficha técnica que esté relacionada con la referencia. Recarga la página</div>
      </div>
      <div class="col-6 border-left py-4 pr-4">
        <!-- Referencia -->
        <div class="my-4 text-left">
          <h6>Referencia</h6>
          <div class="bg-white border p-2">{{ data.referencia }}</div>
        </div>
        <!-- Nombre -->
        <div class="my-4 text-left">
          <h6>Nombre</h6>
          <div class="bg-white border p-2">{{ data.product_name }}</div>
        </div>
        <hr>
        <!-- Bases -->
        <div class="text-left mt-4">
          <div class="table-responsive">
            <table class="w-100 table-bases">
              <thead>
                <tr>
                  <th><p class="font-weight-bold m-0">Bases</p></th>
                  <th><span>Lote (LT)</span></th>
                  <th><span>Lote logístico (PA)</span></th>
                  <th><span>Orden Producción</span></th>
                  <th><span>Día Juliano</span></th>
                  <th><span>Foto</span></th>
                </tr>
              </thead>
              <!-- Info del la Traceabilidad relacionada con el Formato de Calidad -->
              <tbody>
                <tr v-for="(item, index) in data.base" :key="index">
                  <td><strong>{{ item }}</strong></td>
                  <td class="text-center">
                    <b-form-input v-model="data.lote_lt[index]" disabled readonly></b-form-input>
                  </td>
                  <td class="text-center">
                    <b-form-input v-model="data.lote[index]" disabled readonly></b-form-input>
                  </td>
                  <td class="text-center">
                    <b-form-input v-model="data.orden[index]" disabled readonly></b-form-input>
                  </td>
                  <td class="text-center">
                    <b-form-input v-model="data.julian_day[index]" disabled readonly></b-form-input>
                  </td>
                  <td>
                    <div class="image-upload text-center">
                      <img :src="data.url + 'media/' + data.file[index]" :id="'foto-' + index" v-if="data.url != undefined && data.file.length > index" class="imagedisplay">
                      <div v-else ref="icon" :data-key="'1'">
                        <i class="fas fa-camera fa-2x color-grayLight"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Trazabilidad de salida -->
        <div class="text-left mt-4" v-if="data.traceability_output">
          <div class="table-responsive">
            <table class="w-100 table-bases">
              <thead>
                <tr>
                  <th colspan="3"><p class="font-weight-bold m-0">Trazabilidad de salida</p></th>
                </tr>
                <tr>
                  <th><span>Orden final maquila</span></th>
                  <th><span>Lote salida</span></th>
                  <th><span>Fecha de vencimiento</span></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">
                    <b-form-input v-model="data.traceability_output['output_production_order']" disabled readonly></b-form-input>
                  </td>
                  <td class="text-center">
                    <b-form-input v-model="data.traceability_output['output_batch']" disabled readonly></b-form-input>
                  </td>
                  <td class="text-center">
                    <b-form-input v-model="data.traceability_output['output_due_date']" disabled readonly></b-form-input>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Insumos -->
        <div class="text-left mt-4">
          <table class="w-100 table-insumos">
              <thead>
                <tr>
                  <th class="col-1"><p class="font-weight-bold m-0">Referencia</p></th>
                  <th><span>Nombre</span></th>
                  <th class="col-md-2"><span>Lote</span></th>
                  <th class="col-md-2"><span>Hora</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record, index) in data.supplies" :key="index">
                  <td><strong>{{record.supply_id}}</strong></td>
                  <td class="text-center">
                    <b-form-input v-model="record.supply_name" disabled readonly></b-form-input>
                  </td>
                  <td class="text-center">
                    <b-form-input v-model="record.batch" disabled readonly></b-form-input>
                  </td>
                  <td class="text-center">
                    <b-form-input v-model="record.hour" disabled readonly></b-form-input>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdfvuer from 'pdfvuer'
export default {
  name: 'FormControlCalidad',
  props: {
    icon: String,
    data: {
      type: Object,
      default: undefined
    },
    dataSheet: {
      type: String,
      default: undefined
    },
    extraData: Array,
    closeBox: Function
  },
  data () {
    return {
      urlDataSheet: undefined
    }
  },
  components: {
    // pdf
    pdf: pdfvuer
  },
  computed: {
  },
  methods: {
    reassignValue: function () {
      const self = this
      this.resetValue().then(function () {
        self.urlDataSheet = self.dataSheet
      })
    },
    async resetValue () {
      this.urlDataSheet = undefined
    },
    closeBlock: function () {
      this.$store.commit('actionBlockOptions', false)
      this.$store.commit('actionOrvelay', false)
    },
    presetErrorPdf: function (event) {
      if (event !== undefined && event.name !== undefined) {
        if (event.name === 'MissingPDFException') {
          document.getElementById('pdf_view').innerHTML = 'No se encontró la ficha técnica de esta referencia. <br />Puede que sirva recargar la página'
        }
      }
    }
  },
  updated () {
    if (this.urlDataSheet !== this.dataSheet) {
      this.reassignValue()
    }
  }
}
</script>
<style lang="scss">
  .imagedisplay {
    height: auto;
    width: 3vw;
    border: 1px solid #bbb;
    position: static;
    z-index: 3;
  }
</style>
