<template>
  <div class="remission-order">
      <div>
        <div class="row box-option mx-4 center">
            <div class="col-md-10 py-4">
              <div class="text-left">
                <p class="font-weight-bold">Número de OP</p>
                <div class="pb-5">
                  <input style="margin: 0;" type="text" v-model="this.$store.state.storeInventory.opForSideBlock" disabled>
                </div>
                <p class="font-weight-bold">Número de OC</p>
                <div class="pb-5" style="display: flex;">
                  <multiselect v-if="inputstate" v-model="codeOc" id="codeReferencePurchase" :options="optionsOc"
                   @select="selectReference(referencia)"
                    placeholder="Seleccione un número de orden" label="code">
                  </multiselect>
                  <input v-if="!inputstate" class="form-control" v-model="codeOc" type="number" style="margin: 0;">
                  <b-button class="btn-action-page py-2" style="width: 10%;" size="sm" id="addRow"
                  @click="changeInput()">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  </b-button>
                </div>
                <p class="font-weight-bold">Cód. Producto</p>
                <div class="pb-5">
                  <multiselect v-model="referenciaOc" id="codeReferencePurchase" :options="dataReferencias"
                   @select="selectReference(referenciaOc)"
                    placeholder="Seleccione un número de orden" label="text" track-by="text">
                  </multiselect>
                </div>
                <div class="form-group row">
                  <label for="fechaOC" class="col-2 col-form-label font-weight-bold">Fecha</label>
                  <input class="form-control" v-model="dateProduction" type="date" id="fechaOC" :min="dateTwoMonthsBefore" :max="dateTwoMonthsAfter" @change="validateRangeDate()">
                  </div>
                </div>
                <div class="row align-items-center pt-3 pb-5">
                  <div class="col-5 text-left">
                    <p class="font-weight-bold m-0">Cantidad</p>
                  </div>
                  <div class="col-7">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-btn variant="info" @click="cantidadMin()"><i class="fas fa-caret-left"></i></b-btn>
                      </b-input-group-prepend>
                      <b-form-input type="number" min="0" v-model="cantidad" id="quantityPurchase"></b-form-input>
                      <b-input-group-append>
                        <b-btn variant="info" @click="cantidadMax()"><i class="fas fa-caret-right"></i></b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <div class="alert alert-danger mt-4 px-3 w-100" role="alert" v-if="cantidad < 0">* La cantidad no puede <br> ser menor a uno</div>
                </div>
                <div class="mt-4 text-left">
                  <p class="font-weight-bold m-0">Orden de Compra</p>
                  <div class="box-add-file mt-3 text-center">
                    <div class="px-3 py-4" v-show="!fichaTecnica">
                      <label for="fileFichaTecnica"><img src="~@/assets/icons/uploadFile.png" class="img-fluid"> <br> Adjuntar archivo</label>
                      <input type="file" name="file" id="fileFichaTecnica" @change="fileFichaTecnica($event)" class="inputfile" />
                    </div>
                    <div class="col-10 m-auto pt-4" v-show="fichaTecnica">
                      <div>
                        <img :src="fichaTecnica" class="img-fluid m-auto" alt="">
                      </div>
                      <div class="d-flex align-items-center mt-3">
                        <b-button v-b-modal.zoom-file class="color-grayLight col" variant="link"><i class="fas fa-search-plus h3"></i></b-button>
                        <span class="h2 font-weight-light col color-grayLight"> | </span>
                        <b-button class="col" variant="link" @click="deleteFile()"><i class="fas fa-trash-alt text-danger h3"></i></b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
</template>
<script>
/*
import dataproduct from '../../public/product.json'
*/
import Multiselect from 'vue-multiselect'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  name: 'RemissionOrder',
  props: {
    icon: String,
    data: Array,
    dataListas: Array,
    arrayCode: Array,
    extraData: Array
  },
  components: {
    Multiselect
  },
  data () {
    return {
      tag: '',
      tags: [],
      inputstate: true,
      optionsOc: []
    }
  },
  computed: {
    dataReferencias () {
      return this.$store.state.dataReferencias
    },
    module_op () {
      return this.$store.state.storeProduction.module_op
    },
    dataEstado () {
      return this.$store.state.dataEstado
    },
    dataLista () {
      return this.$store.state.dataLista
    },
    dataCode () {
      return this.$store.state.dataCode
    },
    estadoObj () {
      return this.$store.state.storeProduction.dispatchStatus
    },
    listaObj () {
      return this.$store.state.storeProduction.packingsLists
    },
    referenciaObj () {
      return this.$store.state.storeProduction.referencia
    },
    cantidad: {
      set (value) {
        this.$store.state.cantidadPlan = value
      },
      get () {
        return this.$store.state.cantidadPlan
      }
    },
    dateProduction: {
      get () {
        return this.$store.state.storeProduction.date
      },
      set (value) {
        this.$store.state.storeProduction.date = value
      }
    },
    codeOp: {
      set (value) {
        this.$store.state.ProductionOrder = value
      },
      get () {
        return this.$store.state.ProductionOrder
      }
    },
    codeOc: {
      set (value) {
        this.$store.state.ProductionPurchase = value
      },
      get () {
        return this.$store.state.ProductionPurchase
      }
    },
    referencia: {
      set (value) {
        this.$store.state.storeProduction.referencia = value
      },
      get () {
        return this.$store.state.storeProduction.referencia
      }
    },
    referenciaOc: {
      set (value) {
        this.$store.state.storeProduction.referenciaOc = value
      },
      get () {
        return this.$store.state.storeProduction.referenciaOc
      }
    },
    productionBatch: {
      set (value) {
        this.$store.state.productionBatch = value
      },
      get () {
        return this.$store.state.productionBatch
      }
    },
    bases () {
      return this.$store.state.storeProduction.bases
    },
    insumos () {
      return this.$store.state.storeProduction.insumos
    },
    lotes () {
      return this.$store.state.storeProduction.lotes
    },
    fichaTecnica: {
      get () {
        return this.$store.state.storeProduction.fichaTecnica
      },
      set (value) {
        this.$store.state.storeProduction.fichaTecnica = value
      }
    },
    dateBsn: {
      get () {
        return this.$store.state.dataPlanProduccion.date
      },
      set (value) {
        this.$store.state.dataPlanProduccion.date = value
      }
    },
    dateTwoMonthsBefore: {
      get () {
        return this.$store.state.storeProduction.dateTwoMonthsBefore
      }
    },
    dateTwoMonthsAfter: {
      get () {
        return this.$store.state.storeProduction.dateTwoMonthsAfter
      }
    }
  },
  mounted () {
    const self = this
    if (sessionStorage.token) {
      self.$store.state.dataEstado = []
      axios.get('/api_orders/StartProductionOrderaPurchase/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
        .then((response) => {
          this.optionsOc = response.data
        })
      axios.get('/api_orders/StatusRemission/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        response.data.data.forEach(function (item, index) {
          var data = { value: { pk: item.pk }, text: item.name }
          self.$store.commit('actionSetDataEstado', data)
        })
      })
      this.$root.$on('selectRef', () => {
        this.selectReference(self.dispatchStatus)
      })
      self.$store.state.dataLista = []
      axios.get('/api_orders/StartPackingList/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        response.data.data.forEach(function (item, index) {
          var dataListas = { value: { pk: item.pk }, text: item.pk }
          self.$store.commit('actionSetDataLista', dataListas)
        })
      })
      this.$root.$on('selectRef', () => {
        this.selectReference(self.packingsLists)
      })
      self.$store.state.dataCode = []
      axios.get('/api_orders/getorders/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        response.data.data.forEach(function (item, index) {
          var arrayCode = { value: item.code_op, text: item.code_op }
          self.$store.commit('actionSetDataCode', arrayCode)
        })
      })
      this.$root.$on('selectRef', () => {
        this.selectReference(self.referencia)
      })
    }
  },
  methods: {
    getImgUrl: function (pic) {
      return require('../assets/icons/' + pic + '.png')
    },
    changeInput () {
      if (this.inputstate === true) {
        this.inputstate = false
        this.codeOc = ''
      } else {
        this.inputstate = true
        this.codeOc = ''
      }
    },
    selectReference: function (id) {

    },
    fileFichaTecnica: function (event) {
      const self = this
      var reader = new FileReader()
      reader.onload = function (e) {
        self.fichaTecnica = e.target.result
      }
      reader.readAsDataURL(event.target.files[0])
    },
    deleteFile: function () {
      document.getElementById('fileFichaTecnica').value = ''
      this.$store.commit('actionSetFichaTecnica', '')
    },
    close_modal_zoom: function () {
      this.$refs['close-modal'].hide()
    },
    validateRangeDate () {
      const dateChosed = this.$store.state.dataPlanProduccion.date
      const dateBefore = this.dateTwoMonthsBefore
      const dateAfter = this.dateTwoMonthsAfter
      if (!(dateChosed >= dateBefore & dateChosed <= dateAfter)) {
        this.dateProduction = dateBefore
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// Plan de Producción -------------------------------------------------
.box-simple {
  background: #FFF;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.inputs {
  width: 42vh !important;
}
.title {
  margin-top: 1rem;
}
input {
  width: 100%;
  margin-bottom: 25px;
  padding: 8px 12px;
  // box-shadow: 0 1px 2px #0064b7;
  border: none;
  border-radius: 5px;
  &:hover, &:active, &:focus {
    outline: none;
    box-shadow: 0 1px 8px #0064b7;
  }
}
.tags {
  .tag {
      position: relative;
      display: inline-block;
      margin: 0 10px 10px 0;
      padding: 8px 30px 8px 12px;
      color: #fff;
      background: #03185a;
      border: 1px solid #03185a;
      border-radius: 5px;
      cursor: pointer;
      &:after {
        position: absolute;
        right: 8px;
        color: #39c2a1;
        content: '\00d7';
      }
    }
}
</style>
