<template>
  <div class="page controlInventario px-3 py-5 px-md-5">
    <div class="header-page">
      <h3>Control de inventario</h3>
    </div>
    <div class="body-page py-4">
      <div class="sub-page sub-page-start d-flex justify-content-center" :class="{ 'align-items-start': listTrack, 'align-items-center': !listTrack }">
        <!-- Pantalla Inicio Orden de producción -->
        <div class="item-page-list w-100" v-if="listTrack">
          <div>
            <div class="text-left my-3 d-flex justify-content-between">
              <div class="col-sm d-flex justify-content-start">
                <span class="icon-list icon-blue-dark"><i class="fas fa-cogs fa-1x"></i></span> <span class="mx-3 h4">Gestión de inventario</span>
              </div>
            </div>
            <div class="row justify-content-end mt-4 mb-4 filter-class">
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por OP:</p>
                <div>
                  <b-form-input v-model="codeOp" @change="searchOp(codeOp)" placeholder="Ingrese un lote" class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por lote:</p>
                <div>
                  <b-form-input v-model="batch" @change="searchBatch(batch)" placeholder="Ingrese un lote" class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por insumo:</p>
                <div>
                  <b-form-input v-model="codeM" @change="searchCm(codeM)" placeholder="Ingrese un insumo" class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-1 allData text-left">
                <button class="btn-filter-search" @click="setdataOrderStatus()">Refrescar</button>
              </div>
            </div>
            <div class="table-responsive-inventory header">
              <div>
                <b-tabs v-model="indexTab" fill style="background: #fbfbfb;">
                  <b-tab title="Global" active title-link-class="myTabs border border-dark" @click="setdataOrderStatus()">
                    <table v-if="loadTable" class="table-list list-track">
                      <tbody v-if="alldata === true">
                        <tr class="subhead-titles">
                          <td class="font-weight">Código insumo</td>
                          <td class="font-weight">Descripción</td>
                          <td class="font-weight" v-for="(item, a) in arrayTabs" :key="a">Cantidad {{ item }}</td>
                          <td class="font-weight">Cantidad Total</td>
                        </tr>
                        <tr v-for="(k,i) in paginatedListGlobal" :key="i" >
                          <td><b-button class="text-dark font-weight-bold" variant="link" @click="putCurrentTask(k.code)">{{ k.code }}</b-button></td>
                          <td><span class="d-block">{{ k.description_supplies }}</span></td>
                          <td v-for="(a, b) in k.places" :key="b"><span class="d-block">{{ a.quantity_place }}</span></td>
                          <td><span class="d-block">{{ k.quantity }}</span></td>
                        </tr>
                      </tbody>
                      <tbody v-if="filterSupplie === true">
                        <tr class="subhead-titles">
                          <td class="font-weight">Fecha</td>
                          <td class="font-weight">Tipo</td>
                          <td class="font-weight">Código insumo</td>
                          <td class="font-weight">Descripción</td>
                          <td class="font-weight">Código de producción</td>
                          <td class="font-weight">Lote</td>
                          <td class="font-weight">Lugar</td>
                          <td class="font-weight">Cantidad</td>
                        </tr>
                        <tr v-for="(k,i) in paginatedListGlobal" :key="i" >
                          <td><span class="d-block">{{ k.date }}</span></td>
                          <td><span class="d-block">{{ k.type }}</span></td>
                          <td><b-button class="text-dark font-weight-bold" variant="link" @click="putCurrentTask(k.supplies)">{{ k.supplies }}</b-button></td>
                          <td><span class="d-block">{{ k.description_supplies }}</span></td>
                          <td><span class="d-block">{{ k.code_op }}</span></td>
                          <td><span class="d-block">{{ k.batch }}</span></td>
                          <td><span class="d-block">{{ k.place }}</span></td>
                          <td><span class="d-block">{{ k.quantity }}</span></td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-center" v-if="load" style="color: #03185A;">
                      <b-spinner label="Text Centered"></b-spinner>
                    </div>
                    <div class="message" v-if="global.length === 0 && filterSupplie === false">
                      <span>
                        <b-badge variant="danger align-middle">
                          <h6>No existen registros en la base de  datos.</h6>
                        </b-badge>
                      </span>
                    </div>
                    <div class="message" v-if="global.length === 0  && filterSupplie === true">
                      <span>
                        <b-badge variant="danger align-middle">
                          <h6>No existen registros en la base de datos.</h6>
                        </b-badge>
                      </span>
                    </div>
                    <br>
                    <b-pagination v-if="alldata === true" class="list-pagination" pills align="center" hide-ellipsis :total-rows="global.length" v-model="pagglobal" :per-page="NUM_RESULTS" />
                  </b-tab>
                  <b-tab id="tabs" v-for="item in arrayTabs" :key="item" :title="item" @click="postTabs(item)">
                    <table v-if="loadTable" class="table-list list-track">
                      <tbody>
                        <tr class="subhead-titles">
                          <td class="font-weight" style="width: 8%;">Fecha</td>
                          <td class="font-weight" style="width: 8%;">Tipo</td>
                          <td class="font-weight" style="width: 10%;">Código de insumo</td>
                          <td class="font-weight" style="width: 20%;">Descripción</td>
                          <!-- <td class="font-weight" style="width: 20%;">Detalle</td> -->
                          <td class="font-weight" style="width: 6%;">Orden de producción</td>
                          <td class="font-weight" style="width: 8%;">Lote</td>
                          <td class="font-weight" style="width: 5%;">Cantidad</td>
                          <td class="font-weight" style="width: 10%;">Lugar</td>
                          <td class="font-weight" style="width: 10%;">Responsable</td>
                          <td class="font-weight"></td>
                          <td class="font-weight"></td>
                        </tr>
                        <tr v-for="(k,i) in paginatedListTaps" :key="i" >
                          <td><span class="d-block">{{ k.date }}</span></td>
                          <td>
                            <span v-if="formActualizar && idActualizar == i">
                              <multiselect v-model="updateTypeTransaction" :show-labels="false" :options="dataTypeTransaction" label="name" placeholder="Seleccione un tipo"></multiselect>
                            </span>
                            <span v-b-tooltip.hover :title="k.description" v-else>
                              {{ k.type }}
                            </span>
                          </td>
                          <td><span class="d-block">{{ k.supplies }}</span></td>
                          <td><span class="d-block">{{ k.description_supplies }}</span></td>
                          <!-- <td>
                            <span class="d-block" id="disabled-wrapper" style="font-size: 0.8rem;">ver detalle</span>
                            <b-tooltip target="disabled-wrapper">{{ k.description }}</b-tooltip>
                          </td> -->
                          <td>
                            <span v-if="formActualizar && idActualizar == i">
                              <input v-model="updateOrdenProductionTransaction" type="number" class="form-control">
                            </span>
                            <span v-else>
                              {{ k.code_op }}
                            </span>
                          </td>
                          <td>
                            <span v-if="formActualizar && idActualizar == i">
                              <input v-model="updateBatchTransaction" type="number" class="form-control">
                            </span>
                            <span v-else>
                              {{ k.batch }}
                            </span>
                          </td>
                          <td>
                            <span v-if="formActualizar && idActualizar == i">
                              <input v-model="updateQuantityTransaction" type="number" class="form-control">
                            </span>
                            <span v-else>
                              {{ k.quantity }}
                            </span>
                          </td>
                          <td>
                            <span v-if="formActualizar && idActualizar == i">
                              <multiselect v-model="updatePlaceTransaction" :show-labels="false" label="name" :options="placeObject" placeholder="Seleccione un lugar"></multiselect>
                            </span>
                            <span v-else>
                              {{ k.place  }}
                            </span>
                          </td>
                          <td><span class="d-block">{{ k.creator }}</span></td>
                          <td v-if="is_admin()">
                            <div v-if="formActualizar && idActualizar == i">
                              <img src="~@/assets/icons/bien.png" @click="saveData(item, k.transactions_pk, k.supplies)">
                            </div>
                            <div v-else>
                              <button v-if="k.type !== 'transferencia' && k.type !== 'Transferencia' && k.type !== 'TRANSFERENCIA' && k.type !== 'cambio' && k.type !== 'Cmabio' && k.type !== 'CAMBIO'" @click="updateData(i, postTabsArray[i])" class="btn py-0 px-2"><i class="far fa-edit text-success"></i></button>
                            </div>
                          </td>
                          <td v-if="is_admin()">
                            <div v-if="formActualizar && idActualizar == i">
                              <img src="~@/assets/icons/cerrar.png" @click="cancelUpdate()">
                            </div>
                            <div v-else>
                              <button @click="$bvModal.show('modal-delete-transaction');deleteFunction(k.transactions_pk, item);modalListClose();" class="btn py-0 px-2"><i class="far fa-trash-alt text-danger"></i></button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-center" v-if="load" style="color: #03185A;">
                      <b-spinner label="Text Centered"></b-spinner>
                    </div>
                    <div class="message" v-if="postTabsArray.length === 0 && load === false">
                      <span>
                        <b-badge variant="danger align-middle">
                          <h6>No existen registros en la base de datos.</h6>
                        </b-badge>
                      </span>
                    </div>
                    <br>
                    <b-pagination class="list-pagination" pills align="center" hide-ellipsis :total-rows="postTabsArray.length" v-model="pagstorealmacen" :per-page="NUM_RESULTS" />
                  </b-tab>
                </b-tabs>
              </div>
            </div>
            <div v-if="is_admin()" class="my-5">
              <b-button class="btn-action-page py-2" @click="showBlock();" size="lg">Agregar</b-button>
            </div>
          </div>
          <br>
          <br>
        </div>
        <!-- Modal de eliminar -->
        <div class="modal-delete-transaction">
          <b-modal ref="close-delete-modal" id="modal-delete-transaction" centered :hide-footer="true" :hide-header="true" >
            <div class="d-flex flex-column justify-content-center align-items-center">
              <div class="py-5">
                <div class="circle-icon-page d-flex justify-content-center align-items-center">
                  <div>
                    <img src="~@/assets/icons/trash.png" class="img-fluid">
                  </div>
                </div>
                <div class="pt-4 text-center">
                  <h5 class="font-weight-bold color-bluedark">¿Está seguro de que <br> desea eliminar esta transacción?</h5>
                  <div>
                    <b-button  size="lg" class="btn-actions btn-cancel mx-3" @click="close_delete_modal()">Cancelar</b-button>
                    <b-button  size="lg" class="btn-actions btn-delete mx-3" @click="deleteTransaction()">Eliminar</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
    <div class="sidenav-page p-4" :class="{ openSidebar: openOptions }">
      <side-block
        :icon="'track-interna'"
        :tpl="'control-inventario'"
        @closeBlock="close"
        :data="currentData"
      />
    </div>
    <div>
      <b-modal v-if="showModal" ref="close-sub-modal" id="detail-inventory-control" size="xl" :hide-footer="true" :hide-header="true" body-class="">
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="close_sub_modal()">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div class="px-3 mb-3">
          <h4 class="color-bluedark">Detalle control de inventario: {{ codeOfSupplie }}</h4>
          <div class="table-responsive-inventory mt-4" style="text-align: center">
            <!-- <div class="row-modal justify-content-end mb-4" style="text-align: initial;">
              <div class="justify-content-end">
                <p class="font-weight-bold">Filtrar por lugar:</p>
                <b-form-select v-model="place" @change="searchPlace(place)" :options="arrayTabs" class="mb-3">
                  <template #first>
                    <b-form-select-option value="undefined">Todos los lugares</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-1 allData text-left">
                <b-button class="btn-filter-search" @click="putCurrentTask(place)">Refrescar</b-button>
              </div>
            </div> -->
            <table v-if="loadTable" class="table table-list list-track">
              <thead>
                <tr>
                  <th style="height: 3rem;"></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="subhead-titles">
                  <td class="font-weight">Fecha</td>
                  <td class="font-weight">Tipo</td>
                  <td class="font-weight" style="width: 25%;">Detalle</td>
                  <td class="font-weight">Orden de producción</td>
                  <td class="font-weight" style="width: 10%;">Lote</td>
                  <td class="font-weight">Cantidad</td>
                  <td class="font-weight">Lugar</td>
                  <td class="font-weight">Responsable</td>
                </tr>
                <tr v-for="(a,i) in codeList" :key="i" v-show="(pag - 1) * NUM_RESULTS <= i && pag * NUM_RESULTS > i">
                  <td><span class="d-block">{{ a.date }}</span></td>
                  <td><span class="d-block">{{ a.type }}</span></td>
                  <td><span class="d-block">{{ a.description }}</span></td>
                  <td><span class="d-block">{{ a.code_op }}</span></td>
                  <td><span class="d-block">{{ a.batch }}</span></td>
                  <td><span class="d-block">{{ a.quantity }}</span></td>
                  <td><span class="d-block">{{ a.place }}</span></td>
                  <td><span class="d-block">{{ a.creator }}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center" v-if="load" style="color: #03185A;">
            <b-spinner label="Text Centered"></b-spinner>
          </div>
          <div class="message" v-if="codeList.length === 0 && load === false">
            <span>
              <b-badge variant="danger align-middle">
                <h6>No existen registros en la base de datos.</h6>
              </b-badge>
            </span>
          </div>
          <br>
          <b-pagination class="list-pagination" pills align="center" hide-ellipsis :total-rows="codeList.length" v-model="pag" :per-page="NUM_RESULTS" />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import SideBlock from '@/components/SideBlock.vue'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  props: {
    dataListas: Array,
    arrayCode: Array,
    arrayPlace: Array
  },
  data: () => ({
    formActualizar: false,
    idActualizar: -1,
    indexTab: 0,
    load: true,
    NUM_RESULTS: 10, // Numero de resultados por página
    pag: 1, // Página inicial
    pagdci: 1,
    pagstorealmacen: 1,
    pagglobal: 1,
    currentDataSheetURL: '',
    showModal: false,
    listTrack: true,
    currentData: [],
    storealmacen: [],
    global: [],
    production: [],
    batch: '',
    codeM: '',
    codeOp: '',
    place: undefined,
    alldata: '',
    loadTable: true,
    code_op: undefined,
    codeList: [],
    filterTabsOfPlacesVar: null,
    objectTabs: [],
    filterSupplie: false,
    codeOfSupplie: null
  }),
  components: {
    SideBlock,
    Multiselect
  },
  computed: {
    paginatedListTaps () {
      // Calcular la lista paginada
      const startIndex = (this.pagstorealmacen - 1) * this.NUM_RESULTS
      const endIndex = startIndex + this.NUM_RESULTS
      return this.postTabsArray.slice(startIndex, endIndex)
    },
    paginatedListGlobal () {
      // Calcular la lista paginada
      const startIndex = (this.pagglobal - 1) * this.NUM_RESULTS
      const endIndex = startIndex + this.NUM_RESULTS
      return this.global.slice(startIndex, endIndex)
    },
    placeObject () {
      return this.$store.state.storeInventory.placeObject
    },
    dataTypeTransaction () {
      return this.$store.state.dataTypeTransaction
    },
    dataLista () {
      return this.$store.state.dataLista
    },
    openOptions () {
      return this.$store.state.showOptions
    },
    postTabsArray: {
      set (value) {
        this.$store.state.storeInventory.postTabsArray = value
      },
      get () {
        return this.$store.state.storeInventory.postTabsArray
      }
    },
    arrayTabs: {
      set (value) {
        this.$store.state.storeInventory.arrayTabs = value
      },
      get () {
        return this.$store.state.storeInventory.arrayTabs
      }
    },
    updateQuantityTransaction: {
      set (value) {
        this.$store.state.storeInventory.updateQuantityTransaction = value
      },
      get () {
        return this.$store.state.storeInventory.updateQuantityTransaction
      }
    },
    updateBatchTransaction: {
      set (value) {
        this.$store.state.storeInventory.updateBatchTransaction = value
      },
      get () {
        return this.$store.state.storeInventory.updateBatchTransaction
      }
    },
    updateOrdenProductionTransaction: {
      set (value) {
        this.$store.state.storeInventory.updateOrdenProductionTransaction = value
      },
      get () {
        return this.$store.state.storeInventory.updateOrdenProductionTransaction
      }
    },
    updatePlaceTransaction: {
      set (value) {
        this.$store.state.storeInventory.updatePlaceTransaction = value
      },
      get () {
        return this.$store.state.storeInventory.updatePlaceTransaction
      }
    },
    updateTypeTransaction: {
      set (value) {
        this.$store.state.storeInventory.updateTypeTransaction = value
      },
      get () {
        return this.$store.state.storeInventory.updateTypeTransaction
      }
    }
  },
  async mounted () {
    const self = this
    if ((sessionStorage.token && sessionStorage.rol === '1')) {
      self.setdataOrderStatus()
      self.$store.dispatch('getLineListWithTeam')
      self.$root.$on('setdataOrderStatus', () => {
        self.setdataOrderStatus()
      })
    } else {
      self.$router.push({ name: 'Inicio' })
    }
    if (sessionStorage.token) {
      self.$store.dispatch('statusModulesEnabled')
      self.$store.dispatch('atLeastQualityRecordFn')
    }
    await axios.get('/api_inventory/viewPlace/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
      self.filterTabsOfPlacesVar = response.data
      self.objectTabs = self.filterTabsOfPlacesVar.reduce((obj, item) => Object.assign(obj, { [item.name]: item.location }), {})
      self.$store.state.storeInventory.arrayTabs = Object.keys(self.objectTabs)
    })
    self.$store.state.dataLista = []
    axios.get('/api_inventory/viewCondition/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      response.data.forEach(function (item, index) {
        var dataListas = item.name
        self.$store.commit('actionSetDataLista', dataListas)
      })
    })
    self.$store.state.dataTypeTransaction = []
    axios.get('/api_inventory/viewCondition/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      response.data.forEach(function (item, index) {
        var dataListas = { pk: item.pk, name: item.name }
        self.$store.commit('actionDataTypeTransaction', dataListas)
      })
    })
    self.$store.state.storeInventory.placeObject = []
    axios.get('/api_inventory/viewPlace/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
      response.data.forEach(function (item, index) {
        var arrayPlace = { pk: item.pk, name: item.name + ' - ' + item.location }
        self.$store.commit('actionSetPlaceObject', arrayPlace)
      })
    })
  },
  methods: {
    // changePage (page) {
    //   // Cambiar la página actual
    //   this.currentPage = page
    // },
    close_delete_modal () {
      this.$refs['close-delete-modal'].hide()
    },
    modalListClose: function () {
      this.$bvModal.hide('modal-delete-transaction')
    },
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    async saveData (lugar, pkTransaction, code) {
      const self = this
      self.formActualizar = false
      const supplie = code
      if (self.$store.state.storeInventory.updateOrdenProductionTransaction !== '') {
        const data = {
          type_id: self.$store.state.storeInventory.updateTypeTransaction.pk,
          pk_place: self.$store.state.storeInventory.updatePlaceTransaction.pk,
          batch: self.$store.state.storeInventory.updateBatchTransaction,
          quantity: self.$store.state.storeInventory.updateQuantityTransaction,
          pk_transactions: pkTransaction,
          code: self.$store.state.storeInventory.updateOrdenProductionTransaction
        }
        await axios.post('/api_inventory/updateTransactions/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach(function (error) {
              self.announceToast('Advertencia', error.title, 5000, 'warning')
            })
          } else {
            self.announceToast('Éxito en el proceso', 'Se ha actualizado el registro de la transacción del insumo: ' + supplie, 5000, 'success')
          }
        })
      } else {
        const data = {
          type_id: self.$store.state.storeInventory.updateTypeTransaction.pk,
          pk_place: self.$store.state.storeInventory.updatePlaceTransaction.pk,
          batch: self.$store.state.storeInventory.updateBatchTransaction,
          quantity: self.$store.state.storeInventory.updateQuantityTransaction,
          pk_transactions: pkTransaction,
          code: null
        }
        await axios.post('/api_inventory/updateTransactions/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.errors !== undefined && response.data.errors.length > 0) {
            response.data.errors.forEach(function (error) {
              self.announceToast('Advertencia', error.title, 5000, 'warning')
            })
          } else {
            self.announceToast('Éxito en el proceso', 'Se ha actualizado el registro de la transacción del insumo: ' + supplie, 5000, 'success')
          }
        })
      }
      self.$store.state.storeInventory.updateTypeTransaction = { pk: '', name: '' }
      self.load = true
      await axios.post('/api_inventory/sactionsGlobal/', { place: lugar }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        self.postTabsArray = response.data
        self.load = false
      })
    },
    async deleteTransaction () {
      const self = this
      self.load = true
      const pk = self.$store.state.storeInventory.pkTransactionToDel
      const placeDel = self.$store.state.storeInventory.placeDelTransaction
      await axios.post('api_inventory/deleteTransactions/' + pk, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.errors !== undefined && response.data.errors.length > 0) {
          response.data.errors.forEach(function (error) {
            self.announceToast('Advertencia', error.title, 5000, 'warning')
          })
        } else {
          self.announceToast('Éxito en el proceso', 'Se ha eliminado el registro de la transacción.', 5000, 'success')
        }
      })
      await axios.post('/api_inventory/sactionsGlobal/', { place: placeDel }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        self.postTabsArray = response.data
        self.load = false
      })
      self.$refs['close-delete-modal'].hide()
    },
    async deleteFunction (pk, lugar) {
      const self = this
      self.$store.commit('actionPkTransactionToDel', pk)
      self.$store.state.storeInventory.placeDelTransaction = lugar
    },
    cancelUpdate: function () {
      this.formActualizar = false
      this.idActualizar = -1
    },
    updateData: function (i, info) {
      this.idActualizar = i
      this.formActualizar = true
      this.$store.state.storeInventory.editarPk = true
      this.updateQuantityTransaction = info.quantity
      this.updateBatchTransaction = info.batch
      this.updateOrdenProductionTransaction = info.code_op
      this.updatePlaceTransaction.name = info.place
      this.updatePlaceTransaction.pk = info.place_pk
      this.updateTypeTransaction.name = info.type
      this.updateTypeTransaction.pk = info.type_pk
    },
    is_admin () {
      if (sessionStorage.getItem('rol') === '1') {
        return true
      } else {
        return false
      }
    },
    removeText () {
      if (document.getElementsByClassName('textLayer')[0]) {
        document.getElementsByClassName('textLayer')[0].hidden = true
      }
    },
    showBlock: function () {
      this.$store.commit('actionBlockOptions', true)
      this.$parent.overlayBody('on')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    close: function (value) {
      this.$store.commit('actionBlockOptions', value)
      this.$parent.overlayBody('off')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    async putCurrentTask (codeSupplies) {
      const self = this
      self.load = true
      self.codeOfSupplie = codeSupplies
      await axios.post('/api_inventory/sactionsGlobal/', { supplies_code: codeSupplies }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.codeList = response.data
        self.showModal = true
        self.load = false
      }).catch(function (error) {
        console.log(error)
      })
      this.$bvModal.show('detail-inventory-control')
    },
    close_sub_modal () {
      this.$refs['close-sub-modal'].hide()
    },
    async setdataOrderStatus () {
      const self = this
      self.load = true
      await axios.get('/api_inventory/sactionsGlobal/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.loadTable = true
        self.batch = ''
        self.codeM = ''
        self.filterSupplie = false
        self.global = response.data.global
        self.storealmacen = response.data.store
        self.production = response.data.production
        self.alldata = true
        self.indexTab = 0
        self.load = false
      })
    },
    async searchCm (codeSupplies) {
      const self = this
      self.global = []
      self.load = true
      await axios.post('/api_inventory/sactionsGlobal/', { supplies_code: codeSupplies }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.global = response.data
        self.filterSupplie = true
        self.alldata = false
        self.load = false
      })
      self.indexTab = 0
      self.codeM = ''
    },
    async searchBatch (batch) {
      const self = this
      self.global = []
      self.load = true
      await axios.post('/api_inventory/sactionsGlobal/', { batch: batch }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.global = response.data
        self.filterSupplie = true
        self.alldata = false
        self.load = false
      })
      self.indexTab = 0
      self.batch = ''
    },
    async searchOp (op) {
      const self = this
      self.load = true
      self.global = []
      await axios.post('/api_inventory/sactionsGlobal/', { code_op: op }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.global = response.data
        self.filterSupplie = true
        self.alldata = false
        self.load = false
      })
      self.indexTab = 0
      self.codeOp = ''
    },
    async searchPlace (place) {
      const self = this
      self.load = true
      await axios.post('/api_inventory/sactionsGlobal/', { place: place }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.codeList = response.data.data
        self.alldata = false
        self.filterSupplie = true
        self.load = false
        self.place = undefined
        self.indexTab = 0
      }).catch(function (error) {
        console.log(error)
      })
    },
    async postTabs (item) {
      const self = this
      self.load = true
      self.$store.state.storeInventory.itemTransaction = item
      await axios.post('/api_inventory/sactionsGlobal/', { place: item }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        self.postTabsArray = response.data
        self.load = false
      })
    }
  }
}
</script>

<style lang="scss">
  .list-track {
    thead {
      tr {
        background-color: #F5F6F8;
        border-bottom: 1px solid #ccc;
        th.row-op {
          background-color: #39C2A1;
          color: #FFF;
        }
        th.row-oc {
          background-color: #03185A;
          color: #FFF;
        }
      }
    }
    tbody {
      tr.subhead-titles {
        background-color: #FFF;
        border: 0;
      }
      td {
        vertical-align: middle;
        &.multiple-register {
          span {
            border-bottom: 1px solid #8e8e8e;
            padding-bottom: 5px;
            &:last-child {
              border: 0;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .list-track tbody tr.subhead-titles {
      background-color: #FFF;
      border: 0;
      font-size: 13px;
    }
    .table-list tbody tr {
      background-color: #F5F6F8;
      border-left: 6px solid #03185A;
      border-bottom: 8px solid #FFF;
      font-size: 13px;
    }
    .filter-class {
      font-size: 14px;
    }
    .allData {
      margin-right: 5vw;
    }
  }
  @media (max-width: 950.98px) {
    #detail-inventory-control {
      margin-left: 0;
      .modal-dialog {
        max-width: 90%;
        margin-left: 10%;
        margin-right: 10%;
      }
    }
  }
  @media (min-width: 950.98px) {
    #detail-inventory-control, #pdf-modal {
      margin-left: 0;
      .modal-dialog {
        max-width: 90%;
        margin-left: 15%;
        margin-right: 10%;
      }
    }
  }
  @media (max-width: 834px) {
    .row {
      display: flex;
      align-items: center!important;;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    .list-track tbody tr.subhead-titles {
      background-color: #FFF;
      border: 0;
      font-size: 10px;
    }
    .table-list tbody tr {
      background-color: #F5F6F8;
      border-left: 6px solid #03185A;
      border-bottom: 8px solid #FFF;
      font-size: 8px;
    }
    .btn-link {
      font-size: 10px;
    }
    .allData {
      margin-right: 8vw;
    }
    .table-list tbody td {
      padding: 0px 0px;
      /* border-left: 1px solid #ccc; */
      /* vertical-align: middle; */
    }
    .btn-action-page {
      width: 93px;
      height: 40px;
      font-size: 13px;
    }
  }
  .container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
button, input, optgroup, select, textarea {
  padding-left: 10px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.btn-refresh {
  // background-color: #39C2A1;
  background-color: #03185A;
  display: flex;
  box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
  font-weight: 600;
  border-radius: 10px;
  border: 0;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  margin-bottom: 1rem;
}
.my-class-form-control-group{
  display: flex;
  align-items: center;
  margin-left: 60vw;
  padding-bottom: 2rem;
}
.titleFilter {
  margin-right: 11rem;
}
.header {
  margin-top: 5rem !important;
}
.message {
  display: flex;
  justify-content: center;
  align-items: center;
}
.myTabs {
  background-color: #03185A !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #03185A !important;
  background-color: #fff !important;
  border: 1px solid #fff !important;
  font-weight: 600 !important;
}
a:hover {
  color: #fff !important;
  text-decoration: none !important;
}
.nav-tabs .nav-link {
  margin-bottom: -1px !important;
  background-color: #03185A !important;
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
  font-weight: 600 !important;
}
a {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
.font-weight {
  font-weight: 600;
}
.nav-fill .nav-item, .nav-fill>.nav-link {
  flex: 1 1 auto;
  text-align: center;
  border: 1px solid #03185A !important;
}
.row-modal {
  display: flex;
  flex-wrap: wrap;
  margin-right: 36px;
  margin-left: -15px;
}
.table-responsive-inventory {
  display: block;
  width: 100%;
  /* overflow-x: auto; */
  -webkit-overflow-scrolling: touch;
}
.table-list {
  width: 100%;
  text-align: center;
}
.pagination {
    background: #fff!important;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}
</style>
