<template>
    <div class="page analitica px-3 py-5 px-md-5">
        <div class="header-page">
            <h3>Eficacia global de equipos productivos - OEE</h3>
        </div>
        <div class="item-page-list w-100 m-0 bg-warning text-center p-3 font-weight-bold" v-if="recordsLength === false">
          No hay registros
        </div>
        <div class="item-page-list w-100 mt-4">
            <div>
                <div class="col-sm d-flex justify-content-start">
                  <span class="icon-list icon-blue-dark">
                    <i class="fa-solid fa-chart-simple" style="color: #ffffff;"></i>
                  </span>
                  <span class="mx-3 h4" v-if="dataFilters.date == ''">Todas las fechas</span>
                  <span class="mx-3 h4" v-else>{{ dataFilters.date }}</span>
                </div>
                <div class="row justify-content-end mt-2 filter-class">
                  <div class="col-xl-3 text-left">
                    <p class="font-weight-bold">Filtrar por línea:</p>
                    <div>
                      <multiselect class="mb-3" v-model="dataFilters.line" :options="linesList" :multiple="true" @input="getDataByFilters(dataFilters)" placeholder="Seleccione líneas"></multiselect>
                    </div>
                  </div>
                  <div class="col-xl-3 text-left">
                    <p class="font-weight-bold">Filtrar por Referencia:</p>
                    <div>
                      <multiselect class="mb-3" v-model="dataFilters.reference" :options="referencesList" :multiple="true" @input="getDataByFilters(dataFilters)" placeholder="Seleccione referencias">
                      </multiselect>
                    </div>
                  </div>
                    <div class="col-xl-2 allData text-left mx-0">
                      <button class="btn-filter-search w-100" @click="showModalDate()">Filtrar por fechas</button>
                    </div>
                    <div class="col-xl-2 allData text-left mx-0">
                      <button class="btn-filter-search w-100" @click="getDataByFilters(dataFilters)">Refrescar</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row center-items mb-4">
              <div class="row col-12 my-4 center-items">
                <div class="circulo" :style="{'border-color': generalPercentageColor}">
                  <div :style="{'background-color': generalPercentageColor}">
                    <h1>OEE</h1>
                    <h2>{{ generalPercentage }} %</h2>
                  </div>
                </div>
              </div>
              <div class="row col-12">
                <div class="col-md-4" style="border:none 1px solid rgb(222, 226, 230) !important;" v-for="(contentItem, labelItem) in componentObject" v-bind:key="labelItem">
                  <div>
                    <div class="title-indicator text-center h3">{{ contentItem.title }}</div>
                  </div>
                  <div>
                    <div class="title-indicator text-center">{{ contentItem.subtitle }}</div>
                    <div class="title-indicator text-center h3" :style="{color: contentItem.color }">{{ contentItem.obtained_value }} %</div>
                  </div>
                  <div class="p-1 graphic_content rounded-top rounded-bottom">
                    <canvas :id="contentItem.id"></canvas>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex justify-content-center align-items-center row">
                <div class="col-sm-6 col-lg-3" v-for="(timeItem, labelItem) in timeObject" v-bind:key="labelItem">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col">
                        <div class="title-table h5 py-3 bg-blue-dark text-white rounded-top">{{ labelItem }}</div>
                        <div class="h4 py-3 rounded-bottom" style="background-color: #F5F6F8;height: 5rem;font-weight: 600;">{{ timeItem }} min</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-4">
            <div class="container-fluid pt-2 pb-5">
              <div class="row my-4">
                <h2 class="w-100 font-weight-bold">Detalles de los procesos</h2>
              </div>
              <div class="row">
                <div class="col-sm-4 col-lg-4 mx-0 px-1" v-for="(contentItem, labelItem) in componentsReport" v-bind:key="labelItem">
                  <div class="card-title title-table h5 my-0 py-3 px-0 mx-0 bg-blue-dark text-white container-field-color">
                    {{ contentItem.label }}
                  </div>
                  <div class="card-body items-report-card scroll container-field-color p-0" v-if="contentItem.values.length > 0">
                    <input type="text" v-if="contentItem.values.length > 2" class="w-100 search-box-analytic content-report" v-model="contentItem.search" placeholder="Buscar..">
                    <div class="content-report py-2" v-for="(contentReport, indexReport) in filteredItems(contentItem.values, contentItem.search)" v-bind:key="indexReport" @click="assignDataToReport(contentItem.label, contentReport.values)">
                      <p class="m-0 p-2">{{ contentReport.label }}</p>
                    </div>
                  </div>
                  <div class="card-body items-report-card scroll container-field-color p-0" v-else>
                    <p class="h-100 d-flex justify-content-center align-items-center m-0 p-2">Sin novedad</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!-------------------------  Date Modal  --------------->
        <div>
          <b-modal ref="close-date-modal" id="date-modal" size="xl" :hide-footer="true" :hide-header="true" centered>
              <div class="d-flex justify-content-end">
                  <button class="bg-transparent border-0" @click="close_sub_modal()">
                      <i class="far fa-times-circle fa-2x color-grayLight"></i>
                  </button>
              </div>
              <div class="px-3 mb-3">
                  <div class="header-page">
                      <h3>Filtrar por rango de fechas</h3>
                  </div>
                  <div class="container-fluid my-5">
                      <div class="row text-center d-flex justify-content-center">
                          <div class="col-sm-3">
                              <h4>Seleccione una fecha inicial:</h4>
                              <div>
                                  <b-form-input type="date" v-model="dataFilters.start_date" class="mb-3 b-form-input" @change="manageDates(true, false)"></b-form-input>
                              </div>
                          </div>
                          <div class="col-sm-3">
                              <h4>Seleccione una fecha final:</h4>
                              <div>
                                  <b-form-input type="date" v-model="dataFilters.end_date" class="mb-3 b-form-input" @change="manageDates(false, true)"></b-form-input>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="d-flex justify-content-center">
                      <button type="button" class="btn btn-action-page btn-action-page-analitica py-2 btn-secondary btn-lg col-4" @click="cleanFilterDates()">Limpiar fechas</button>
                      <button type="button" class="btn btn-action-page btn-action-page-analitica py-2 btn-secondary btn-lg col-4" @click="applyFilterDates()">Filtrar</button>
                  </div>
              </div>
          </b-modal>
        </div>
      <!-- modal Component Record Report Detail  -------------------------------------------------------------------------------------------->
      <b-modal ref="close-modal-info-report" id="modal-info-report" size="xl" centered :hide-footer="true" :hide-header="true">
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="closeModalInfoReport()">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div class="px-3 py-2 my-2 font-app">
          <div class="wrapper-content-modal">
              <div class="px-4">
                <h3 class="text-center">Detalle de Reporte de Insuficiencia</h3>
                <h6 class="mb-5 text-center font-weight-bold">{{ subtitleReportType.toUpperCase() }}</h6>
                <div class="form-quality container-fluid">
                  <div v-for="(content, index) in recordReport" v-bind:key="index">
                    <div class="row bg-grayLight py-2 rounded justify-content-center">
                      <div class="mb-2 mx-1 text-center container-field-report" v-for="(valueContent, labelContent) in content" v-bind:key="labelContent">
                        <p class="font-weight-bold">{{ valueContent.label }}</p>
                        <p class="form-control">{{ valueContent.value }}</p>
                      </div>
                    </div>
                    <hr class="my-4">
                  </div>
                </div>
              </div>
          </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Chart from 'chart.js'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  data: () => ({
    totalTime: 0,
    availableTime: 0,
    operationalTime: 0,
    deadTime: 0,
    searchQuery: '',
    generalPercentage: 0.0,
    generalPercentageColor: 'gray',
    performancePctg: 0,
    referencesList: [],
    linesList: [],
    timeObject: {},
    componentsReport: [],
    componentObject: {},
    recordsLength: true,
    dataFilters: {
      line: '',
      reference: null,
      date: '',
      start_date: '',
      end_date: ''
    },
    percentageToCreateGraphic: {},
    subtitleReportType: '',
    recordReport: []
  }),
  components: {
    Multiselect
  },
  computed: {
    getLineList () {
      return this.$store.state.lineListWithTeam
    },
    getPerformancePercentage () {
      return this.$store.state.performancePercentage
    }
  },
  async mounted () {
    if (sessionStorage.token && sessionStorage.rol === '1') {
      this.$store.dispatch('getLineListWithTeam')
      this.getDataProcess()
    } else if (sessionStorage.token && sessionStorage.rol === '2') {
      this.$router.push({ name: 'AperturaLinea' })
    } else {
      this.$router.push({ name: 'Inicio' })
    }
  },
  methods: {
    filteredItems (contentItem, searchText) {
      const query = searchText.toLowerCase()
      return contentItem.filter(item => item.label.toLowerCase().includes(query))
    },
    assignDataToReport (reportType, contentItem) {
      this.subtitleReportType = reportType
      this.recordReport = contentItem
      this.$bvModal.show('modal-info-report')
    },
    closeModalInfoReport () {
      this.$refs['close-modal-info-report'].hide()
    },
    assignData: function (dataFullContent) {
      this.timeObject = dataFullContent.time_values
      this.componentObject = dataFullContent.component_values
      this.generalPercentage = dataFullContent.general_percentage
      this.generalPercentageColor = dataFullContent.general_percentage_color
      this.componentsReport = dataFullContent.components_report
      this.recordsLength = dataFullContent.records_len
      this.referencesList = dataFullContent.references_data
      this.linesList = dataFullContent.lines_data
    },
    iterGraphicsData: function () {
      Object.entries(this.componentObject).forEach(([keyItem, contentItem]) => {
        const idElement = document.getElementById(contentItem.id)
        const percentageGraphic = {
          type: 'doughnut',
          data: {
            labels: contentItem.labels_graphic,
            datasets: [{
              label: 'Gauge',
              data: [contentItem.obtained_value, contentItem.missing_value],
              backgroundColor: [
                contentItem.color,
                '#ccc'
              ]
            }]
          },
          options: {
            rotation: -1.0 * Math.PI,
            circumference: Math.PI
          }
        }
        this.percentageToCreateGraphic = new Chart(idElement, percentageGraphic)
      })
    },
    async getDataProcess () {
      const self = this
      await axios.get('/api_analytics/oee_statistics/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.dataFilters = response.data.data_filters
        self.assignData(response.data)
      }).then(function () {
        self.iterGraphicsData()
      })
    },
    manageDates (startDateChanged = false, endDateChanged = false) {
      if (startDateChanged) {
        if (this.dataFilters.start_date === '') {
          this.dataFilters.end_date = ''
        } else if (this.dataFilters.start_date !== '' && this.dataFilters.end_date === '') {
          this.dataFilters.end_date = this.dataFilters.start_date
        }
      } else if (endDateChanged) {
        if (this.dataFilters.end_date === '') {
          this.dataFilters.start_date = ''
        } else if (this.dataFilters.end_date !== '' && this.dataFilters.start_date === '') {
          this.dataFilters.start_date = this.dataFilters.end_date
        }
      }
    },
    applyFilterDates () {
      if (this.dataFilters.start_date > this.dataFilters.end_date) {
        this.announceToast('Inconsistencia en fechas', 'La fecha inicial no debe ser mayor a la fecha final.', 5000, 'danger')
      } else if ((this.dataFilters.end_date === '' || this.dataFilters.start_date === '') && (this.dataFilters.end_date !== '' || this.dataFilters.start_date !== '')) {
        this.announceToast('Inconsistencia en fechas', 'Alguna fecha presenta una inconsistencia que no es entendida.', 5000, 'danger')
      } else {
        this.getDataByFilters(this.dataFilters)
        this.close_sub_modal()
        if (this.dataFilters.start_date === '') {
          this.dataFilters.date = ''
        } else if (this.dataFilters.start_date === this.dataFilters.end_date) {
          this.dataFilters.date = `${this.dataFilters.start_date}`
        } else {
          this.dataFilters.date = `${this.dataFilters.start_date} ~ ${this.dataFilters.end_date}`
        }
      }
    },
    cleanFilterDates () {
      this.dataFilters.start_date = ''
      this.dataFilters.end_date = ''
      this.dataFilters.date = ''
    },
    async getDataByFilters (dataFilters) {
      const self = this
      await axios.post('/api_analytics/oee_statistics/', { line: dataFilters.line, reference: dataFilters.reference, start_date: dataFilters.start_date, end_date: dataFilters.end_date }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.assignData(response.data)
      }).then(function () {
        self.iterGraphicsData()
      })
    },
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    showModalDate () {
      this.$bvModal.show('date-modal')
    },
    close_sub_modal () {
      this.$refs['close-date-modal'].hide()
    },
    is_leader () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
    .center-items {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .title-item {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
    }
    .circulo {
        position:relative;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border:5px solid #39C2A1;
        background: #fff;
    }
    .circulo>div {
        position:absolute;
        width:180px;
        height:180px;
        border-radius: 50%;
        background: #39C2A1;
        margin:auto;
        top: 0; left: 0; bottom: 0; right: 0;
    }
    .circulo > div > h1 {
        position: absolute;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        color: white;
        margin: auto;
        top: 37%;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .circulo > div > h2 {
        position: absolute;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        color: white;
        margin: auto;
        top: 92%;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .list-group {
        max-height: 35vh;
        margin-bottom: 20vh;
        overflow-y: scroll;
    }
    .list-group::-webkit-scrollbar-track {
        border: 1px solid #eeeeee;
        padding: 2px 0;
        background-color: #eeeeee;
    }

    .list-group::-webkit-scrollbar {
        width: 10px;
    }

    .list-group::-webkit-scrollbar-thumb {
        border-radius: 2px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #03185A;
        border: 1px solid #03185A;
    }
    .border-ul {
        border: 1px solid #03185A;
    }
    .list-group-item {
        position: relative;
        display: block;
        padding: 0.75rem 1.25rem;
        background-color: #fff;
        // border: 1px solid #eeeeee;
    }
    /* ------------ QUERYS ------------------ */
    @media (max-width: 834px) {
        .btn-action-page-analitica {
            width: 100%;
            height: 41px;
            font-size: 16px;
        }
    }
    /* ---------------- Multiselect ------------ */
    .multiselect__tags {
        border-color: #39408b;
    }
    .multiselect__placeholder {
        color: #39408b;
    }
    .graphic_content {
        border: solid 0.5px #03185a;
        color: #03185a;
    }
    #date-modal {
      padding: 0 !important;
    }
    .btn-filter-search:hover,
    .btn-filter-search:focus,
    .btn-filter-search:active {
      background-color: #06258b;
    }
    #modal-info-report {
      color: #03185A !important;
    }
    .content-report:hover {
      cursor: pointer;
      background-color: rgba(0,0,0,.1);;
      transition:all 0.5s;
    }
    .container-field-report {
      width: fit-content;
      max-width: 20vw;
    }
    .container-field-report .form-control {
      background-color:#e9ecef;
      border: 1px solid #03185A;
      border-radius: 8px;
      height: fit-content;
      width: 100%;
      max-width: 20vw;
      padding: 1.2vh 2vw;
      word-wrap: break-word;
    }
    .items-report-card {
      overflow-y: overlay;
      height: 270px;
    }
    .content-report{
      word-wrap: break-word;
      transition:all 0.35s;
      border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .content-report p {
      padding: 1vh 1vw;
    }
    .container-field-color {
      background-color: rgb(245, 246, 248);
      font-weight: 600;
    }
  .search-box-analytic,
  .search-box-analytic:focus,
  .search-box-analytic:focus-visible {
    border: 0;
    outline: 2px solid #041549 !important;
  }
</style>
