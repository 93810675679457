<template>
  <div class="page picking px-3 py-5 px-md-5">
    <div class="header-page">
      <h3>Orden de transporte (Picking)</h3>
    </div>
    <div class="body-page py-4">
      <div class="sub-page sub-page-start d-flex justify-content-center" :class="{ 'align-items-start': listTrack, 'align-items-center': !listTrack }">
        <!-- Pantalla Inicio Orden de producción -->
        <div class="item-page-list w-100" v-if="listTrack">
          <div>
            <div class="text-left my-3 d-flex justify-content-between">
              <div class="col-sm d-flex justify-content-start">
                <span class="icon-list icon-blue-dark">
                  <i class="fas fa-cogs fa-1x"></i></span> <span class="mx-3 h4">Gestión de inventario</span>
              </div>
            </div>
            <div class="row justify-content-end mt-4 mb-4">
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por Insumo:</p>
                <div>
                  <b-form-input v-model="suppliesCode" @change="filterSupplies(suppliesCode)" placeholder="Ingrese un Insumo" class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por OP:</p>
                <div>
                  <b-form-input v-model="opCode" @change="filterOp(opCode)" placeholder="Ingrese un codigo OP" class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-2 text-left">
                <p class="font-weight-bold">Filtrar por OT:</p>
                <div>
                  <b-form-input v-model="otCode" @change="searchSupplie(otCode)" placeholder="Ingrese un codigo OT" class="mb-3 b-form-input"></b-form-input>
                </div>
              </div>
              <div class="col-xl-1 allData text-left">
                <button class="btn-filter-search" @click="setdataPicking()">Refrescar</button>
              </div>
            </div>
            <div class="table-responsive">
              <table v-if="loadTable" class="table table-list list-track">
                <thead v-if="activefilter === false">
                  <tr>
                    <th style="height: 3rem;"></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <thead v-if="activefilter">
                  <tr>
                    <th style="height: 3rem;"></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody v-if="activefilter === false">
                  <tr class="subhead-titles">
                    <td class="font-weight">Fecha</td>
                    <td class="font-weight">Código de OT</td>
                    <td class="font-weight">Recibido por</td>
                  </tr>
                  <tr v-for="(k,i) in dataPicking" :key="i" v-show="(pag - 1) * NUM_RESULTS <= i && pag * NUM_RESULTS > i">
                    <td><span class="d-block">{{ k.date }}</span></td>
                    <td><b-button class="text-dark font-weight-bold" variant="link" @click="putCurrentTask(k.code)">{{ k.code }}</b-button></td>
                    <td><span class="d-block">{{k.creator}}</span></td>
                  </tr>
                </tbody>
                <tbody v-if="activefilter">
                <tr class="subhead-titles">
                  <td>Código material</td>
                  <td>Descripción</td>
                  <td style="width: 10%;">Lote</td>
                  <td>Cantidad OT</td>
                  <td>Cantidad Recibida</td>
                  <td style="width: 10%;">OP</td>
                </tr>
                <tr v-for="(k, index) in filterdata" :key="index">
                  <td>{{ k.supplies }}</td>
                  <td>
                    <span>
                      {{ k.Descriptions }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ k.batch }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ k.quantity_ot }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ k.quantity_received }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ k.op }}
                    </span>
                  </td>
                </tr>
              </tbody>
              </table>
            </div>
            <div class="text-center" v-if="load" style="color: #03185A;">
              <b-spinner label="Text Centered"></b-spinner>
            </div>
            <div class="center" v-if="dataPicking.length === 0 && load === false">
              <span><b-badge variant="danger align-middle"><h6>No existen registros en la base de datos.</h6></b-badge></span>
            </div>
            <br>
            <b-pagination class="list-pagination" pills align="center" hide-ellipsis :total-rows="dataPicking.length" v-model="pag" :per-page="NUM_RESULTS" />
            <div v-if="is_admin()" class="my-5">
              <b-button class="btn-action-page py-2" @click="showBlock();" size="lg">Agregar una OT</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidenav-pagebig p-4" :class="{ openSidebar: openOptions }">
      <side-block
        :icon="'track-interna'"
        :tpl="'picking'"
        @closeBlock="close"
        :data="currentData"
      />
    </div>
    <div>
      <b-modal v-if="showModal" ref="close-sub-modal" id="detail-picking" size="xl" :hide-footer="true" :hide-header="true" body-class="">
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="close_sub_modal()">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div class="px-3 mb-3">
          <h4 class="color-bluedark">Detalle Orden de Transporte</h4>
          <div class="table-responsive mt-4 row_data_activitity height-400" @scroll="handleScroll()" ref="contentMassiveData" style="text-align: center">
            <table v-if="loadTable" class="table table-list list-track">
              <thead>
                <tr>
                  <th style="height: 3rem;"></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="subhead-titles">
                  <td>Código material</td>
                  <td>Descripción</td>
                  <td style="width: 10%;">Lote</td>
                  <td>Cantidad OT</td>
                  <td>Cantidad Recibida</td>
                  <td style="width: 10%;">OP</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr v-for="(k, index) in codeList" :key="index" v-show="(pagcodelist - 1) * NUM_RESULTS <= index && pagcodelist * NUM_RESULTS > index">
                  <td>
                    <span v-if="(formUpdateRow || formCreateRow) && idActualizar == index">
                      <multiselect v-model="idSupply" :show-labels="false" class="multiselect-supply" :options="dataMaterial"
                        placeholder="Código"
                        @select="descriptionPiking()">
                      </multiselect>
                    </span>
                    <span v-else>
                      {{ k.supplies }}
                    </span>
                  </td>
                  <td>
                    <span v-if="(formUpdateRow || formCreateRow) && idActualizar == index">
                      {{ descriptionSupply }}
                    </span>
                    <span v-else>
                      {{ k.Descriptions }}
                    </span>
                  </td>
                  <td>
                    <span v-if="(formUpdateRow || formCreateRow) && idActualizar == index">
                      <input v-model="batchUpdate" type="text" class="form-control width-form-control">
                    </span>
                    <span v-else>
                      {{ k.batch }}
                    </span>
                  </td>
                  <td>
                    <span v-if="(formUpdateRow || formCreateRow) && idActualizar == index">
                      <input v-model="quantityOtUpdate" type="number" class="form-control width-form-control">
                    </span>
                    <span v-else>
                      {{ k.quantity_ot }}
                    </span>
                  </td>
                  <td>
                    <span v-if="(formUpdateRow ||  formCreateRow) && idActualizar == index">
                      <input v-model="quantityReceived" type="number" class="form-control width-form-control">
                    </span>
                    <span v-else>
                      {{ k.quantity_received }}
                    </span>
                  </td>
                  <td>
                    <span v-if="(formUpdateRow ||  formCreateRow) && idActualizar == index">
                      <input v-model="order_production" type="number" class="form-control width-form-control">
                    </span>
                    <span v-else>
                      {{ k.op }}
                    </span>
                  </td>
                  <td>
                    <div v-if="formUpdateRow && idActualizar == index">
                      <img src="~@/assets/icons/cheque.png" @click="saveData(k.pk, k.code_ot)">
                    </div>
                    <div v-else-if="formCreateRow && idActualizar == index">
                      <i class="bx bx-plus-circle text-success icon-create-row" @click="confirmCreateRecord(index, codeList[index])"></i>
                    </div>
                    <div v-else-if="!formCreateRow && !formUpdateRow">
                      <button @click="updateData(index, codeList[index])" class="btn py-0 px-2"><i class="far fa-edit text-success"></i></button>
                    </div>
                  </td>
                  <td>
                    <div v-if="formUpdateRow && idActualizar == index">
                      <img src="~@/assets/icons/cerrar.png" @click="cancelUpdate()">
                    </div>
                    <div v-else-if="formCreateRow && idActualizar == index">
                      <i class="bx bx-minus-circle text-danger icon-create-row" @click="cancelCreateRecord(index)"></i>
                    </div>
                    <div v-else-if="!formCreateRow && !formUpdateRow">
                      <button @click="$bvModal.show('delete-picking');modalListClose();deletePicking(k.pk, k.code_ot)" class="btn py-0 px-2"><i class="far fa-trash-alt text-danger"></i></button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <thead v-if="!formCreateRow && !formUpdateRow">
                <tr class="contentTransportOrden">
                  <td colspan="8">
                    <div class="content_add_row d-flex justify-content-center align-items-center" @mousemove="handleMouseOver($event)">
                      <b-button ref="addRowButton" class="action_add_row d-flex justify-content-center align-items-center" @click="createRow()">
                        <i class="bx bx-plus" style="color: #39C2A1;"></i>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
          <div class="text-center" v-if="load" style="color: #03185A;">
            <b-spinner label="Text Centered"></b-spinner>
          </div>
          <div class="center" v-if="codeList.length === 0 && load === false">
            <span><b-badge variant="danger align-middle"><h6>No existen registros en la base de datos.</h6></b-badge></span>
          </div>
          <br>
          <b-pagination class="list-pagination" pills align="center" hide-ellipsis :total-rows="codeList.length" v-model="pagcodelist" :per-page="NUM_RESULTS" />
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal ref="pdf-modal" id="pdf-modal" size="xl" centered :hide-footer="true" :hide-header="true">
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="close_pdf_modal()">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div align="center">
          <pdf :src="currentDataSheetURL" id="pdf-view" :resize="true" @loading="removeText()" :page="1">
            <template slot="loading">
              Cargando ficha técnica...
            </template>
          </pdf>
        </div>
      </b-modal>
    </div>
    <!-- Modal de eliminar -->
    <div class="modal-delete-picking">
      <b-modal ref="close-delete-modal" id="delete-picking" centered :hide-footer="true" :hide-header="true" >
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="py-5">
            <div class="circle-icon-page d-flex justify-content-center align-items-center">
              <div>
                <img src="~@/assets/icons/trash.png" class="img-fluid">
              </div>
            </div>
            <div class="pt-4 text-center">
              <h5 class="font-weight-bold color-bluedark">¿Está seguro de que <br> desea eliminar este insumo <br> de la orden de transporte?</h5>
              <div>
                <b-button  size="lg" class="btn-actions btn-cancel mx-3" @click="close_delete_modal()">Cancelar</b-button>
                <b-button  size="lg" class="btn-actions btn-delete mx-3" @click="deleteSupplie()">Eliminar</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import pdf from 'pdfvuer'
import Multiselect from 'vue-multiselect'
import SideBlock from '@/components/SideBlock.vue'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  data: () => ({
    NUM_RESULTS: 10, // Numero de resultados por página
    pag: 1, // Página inicial
    pagcodelist: 1,
    load: true,
    currentDataSheetURL: '',
    showModal: false,
    listTrack: true,
    searchTrack: '',
    currentTrackItem: [],
    currentData: [],
    urlsheet: '',
    loadTable: true,
    codeOtNow: null,
    idSupply: null,
    descriptionSupply: '',
    batchUpdate: null,
    quantityOtUpdate: null,
    quantityReceived: null,
    suppliesCode: undefined,
    opCode: undefined,
    otCode: undefined,
    activefilter: false,
    order_production: undefined,
    filterdata: [],
    codeList: [],
    formUpdateRow: false,
    formCreateRow: false,
    idActualizar: -1,
    filterOption: null,
    filterOptions: [
      { value: null, text: 'Seleccione un filtro' },
      { value: '1', text: 'Filtro por OT' },
      { value: '2', text: 'Ver todo' }
    ],
    scrollPositionList: 0
  }),
  components: {
    pdf,
    SideBlock,
    Multiselect
  },
  async mounted () {
    if (sessionStorage.token && sessionStorage.rol === '1') {
      this.setdataPicking()
      this.setdataPicking()
      this.$store.dispatch('getLineListWithTeam')
      this.$root.$on('setdataPicking', () => {
        this.setdataPicking()
      })
    } else {
      this.$router.push({ name: 'Inicio' })
    }
    if (sessionStorage.token) {
      this.$store.dispatch('statusModulesEnabled')
      this.$store.dispatch('atLeastQualityRecordFn')
    }
  },
  computed: {
    currentTask () {
      return this.$store.state.storeProduction.currentTask
    },
    openOptions () {
      return this.$store.state.showOptions
    },
    dataPicking: {
      get () {
        return this.$store.state.storeTrack.dataPicking
      },
      set (value) {
        this.$store.state.storeTrack.dataPicking = value
      }
    },
    dataMaterial () {
      return this.$store.state.dataMaterial
    }
  },
  methods: {
    async allData (selectedItems) {
      await this.setdataPicking()
      if (selectedItems === '2') {
        return this.dataPicking
      }
    },
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    updateData: function (i, info) {
      this.getDataMaterial()
      this.$nextTick()
      this.idActualizar = i
      this.formUpdateRow = true
      this.$store.state.storeInventory.editarPk = true
      this.idSupply = info.supplies
      this.descriptionSupply = info.Descriptions
      this.batchUpdate = info.batch
      this.quantityOtUpdate = info.quantity_ot
      this.quantityReceived = info.quantity_received
      this.order_production = info.op
    },
    async saveData (i, code) {
      const self = this
      this.formUpdateRow = false
      if (this.order_production === '') {
        this.order_production = null
      }
      const data = {
        pk_transport: i,
        batch: this.batchUpdate,
        amount_of_transportation: this.quantityOtUpdate,
        received_amount: this.quantityReceived,
        order_production: parseInt(this.order_production)
      }
      await axios.post('/api_inventory/updateinputByTransportOrder/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.errors !== undefined && response.data.errors.length > 0) {
          response.data.errors.forEach(function (error) {
            self.announceToast('Advertencia', error.title, 4000, 'warning')
          })
        } else {
          self.announceToast('Éxito en el proceso', response.data.Status, 4000, 'success')
        }
      }).catch(function (error) {
        self.announceToast(error, 'Campos vacíos. Por favor, vuelva a intentarlo.', 5000, 'danger')
      })
      await this.putCurrentTask(code)
      this.cleanDataFieldsDetail()
    },
    async deleteSupplie () {
      var self = this
      var pk = this.$store.state.storeInventory.pickingToDel
      var code = this.$store.state.storeInventory.codeTransportation
      await axios.post('/api_inventory/deleteinputByTransportOrder/' + pk, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.Status !== 'error') {
          self.announceToast('Éxito', 'Se elimino correctamente.', 2000, 'success')
        }
      }).catch(function (error) {
        self.announceToast(error, 'Error. Algo salio mal. Por favor, vuelva a intentarlo.', 5000, 'danger')
      })
      await this.putCurrentTask(code)
      this.close_delete_modal()
    },
    async cancelUpdate () {
      this.formUpdateRow = false
      this.formCreateRow = false
      this.idActualizar = -1
    },
    async descriptionPiking () {
      const self = this
      await axios.get('/api/supplies/' + self.idSupply, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        if (response.data.description === undefined) {
          this.$bvToast.toast(`El código ${self.idSupply} no contiene una descripción.`, {
            title: 'Código Material',
            variant: 'danger',
            autoHideDelay: 5000,
            solid: true
          })
          this.descriptionSupply = response.data.description
        } else {
          this.descriptionSupply = response.data.description
        }
      }).catch(function (error) {
        console.log(error)
      })
    },
    cleanDataFieldsDetail () {
      this.idActualizar = -1
      this.$store.state.dataMaterial = []
      this.descriptionSupply = ''
      this.idSupply = null
      this.batchUpdate = null
      this.quantityOtUpdate = null
      this.quantityReceived = null
      this.order_production = undefined
    },
    async confirmCreateRecord (index, dataRow) {
      const self = this
      this.formUpdateRow = false
      let allOk = true
      if (this.order_production === '') {
        this.order_production = null
      }
      const data = {
        codeOt: this.codeOtNow,
        idSupply: this.idSupply,
        batch: this.batchUpdate,
        amount_of_transportation: this.quantityOtUpdate,
        received_amount: this.quantityReceived,
        order_production: parseInt(this.order_production)
      }
      await axios.post('/api_inventory/createinputByTransportOrder/', data, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.errors !== undefined && response.data.errors.length > 0) {
          response.data.errors.forEach(function (error) {
            self.announceToast(error.title, error.message, error.time, 'danger')
          })
          allOk = false
        } else {
          self.announceToast('Creación realizada', 'Se ha creado el registro correctamente', 4000, 'success')
        }
      }).catch(function (error) {
        self.announceToast(error, 'Campos vacíos. Por favor, vuelva a intentarlo.', 5000, 'danger')
      })
      this.$nextTick()
      if (allOk) {
        await this.putCurrentTask(this.codeOtNow)
        this.cleanDataFieldsDetail()
      }
    },
    async cancelCreateRecord (index) {
      this.formCreateRow = false
      this.codeList.splice(index, 1)
      this.cleanDataFieldsDetail()
      this.idActualizar = -1
    },
    close_delete_modal () {
      this.$refs['close-delete-modal'].hide()
    },
    deletePicking: function (pk, code) {
      this.$store.commit('actionPickingToDel', pk)
      this.$store.commit('actioncodeTransportation', code)
    },
    modalListClose: function () {
      this.$bvModal.hide('modal-delete-picking')
    },
    is_admin () {
      if (sessionStorage.getItem('rol') === '1') {
        return true
      } else {
        return false
      }
    },
    removeText () {
      if (document.getElementsByClassName('textLayer')[0]) {
        document.getElementsByClassName('textLayer')[0].hidden = true
      }
    },
    async searchSupplie (otCode) {
      await this.setdataPicking()
      if (otCode !== 'undefined') {
        var array = []
        this.dataPicking.forEach(function (item, index) {
          if (parseInt(item.code) === parseInt(otCode)) {
            array.push(item)
          }
        })
      }
      this.dataPicking = array
    },
    showBlock: function () {
      this.getDataMaterial()
      this.$store.commit('actionBlockOptions', true)
      this.$parent.overlayBody('on')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    close: function (value) {
      this.$store.commit('actionBlockOptions', value)
      this.$parent.overlayBody('off')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    updateTrack () {
      this.$store.state.storeTrack.updateTrack = true
    },
    is_leader () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    },
    close_modal: function () {
      this.$refs['close-sub-modal'].hide()
      this.formCreateRow = false
    },
    async putCurrentTask (task) {
      const self = this
      self.codeOtNow = task
      self.load = true
      await axios.post('/api_inventory/viewtransportOrder/', { code_Ot: task }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.codeList = response.data.data
        self.showModal = true
        self.load = false
        self.formUpdateRow = false
        self.formCreateRow = false
        self.cleanDataFieldsDetail()
      }).catch(function (error) {
        console.log(error)
      })
      this.$bvModal.show('detail-picking')
    },
    async filterOp (op) {
      const self = this
      await axios.post('/api_inventory/viewtransportOrder/', { code_op: op }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.filterdata = response.data.data
        self.activefilter = true
      }).catch(function (error) {
        console.log(error)
      })
    },
    async filterSupplies (supplies) {
      const self = this
      await axios.post('/api_inventory/viewtransportOrder/', { supplies: supplies }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.filterdata = response.data.data
        self.activefilter = true
      }).catch(function (error) {
        console.log(error)
      })
    },
    infoLoaded () {
      if (this.codeList.length !== 0) {
        return true
      } else {
        return false
      }
    },
    close_sub_modal () {
      this.$refs['close-sub-modal'].hide()
      this.formCreateRow = false
    },
    close_pdf_modal () {
      this.$refs['pdf-modal'].hide()
    },
    async setdataPicking () {
      const self = this
      self.load = true
      self.dataPicking = []
      await axios.get('/api_inventory/viewtransportOrder/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.dataPicking = response.data.data
        self.otCode = ''
        self.suppliesCode = ''
        self.opCode = ''
        self.activefilter = false
        self.load = false
      })
    },
    handleMouseOver (event) {
      if (this.$refs.contentMassiveData && event.target.querySelector('.action_add_row')) {
        const withDataContainerDiv = this.$refs.contentMassiveData.getBoundingClientRect().width
        const lengthPositionXMouse = this.scrollPositionList + event.clientX - ((window.innerWidth - withDataContainerDiv) / 2) - 40
        event.target.querySelector('.action_add_row').style.left = `${lengthPositionXMouse}px`
      }
    },
    handleScroll () {
      const container = this.$refs.contentMassiveData
      this.scrollPositionList = container.scrollLeft
    },
    async getDataMaterial () {
      const self = this
      self.$store.state.dataMaterial = []
      axios.get('/api/supplies/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        response.data.forEach(function (item, index) {
          var arrayCode = item.id
          self.$store.commit('actionSetDataMaterial', arrayCode)
        })
      })
    },
    async createRow () {
      this.getDataMaterial()
      this.idActualizar = this.codeList.length
      this.codeList.push(
        { supplies: null, Descriptions: '', batchUpdate: null, quantityOtUpdate: null, op: null, reference: null, batch: null, process: null, amount: '' }
      )
      this.formCreateRow = true
      await this.$nextTick()
      if (this.codeList && this.codeList.length > this.NUM_RESULTS) {
        this.pagcodelist = Math.ceil(this.codeList.length / this.NUM_RESULTS)
      }
    }
  }
}
</script>

<style lang="scss" scope>
  .list-track {
    thead {
      tr {
        background-color: #F5F6F8;
        border-bottom: 1px solid #ccc;
        th.row-op {
          background-color: #39C2A1;
          color: #FFF;
        }
        th.row-oc {
          background-color: #03185A;
          color: #FFF;
        }
      }
    }
    tbody {
      tr.subhead-titles {
        background-color: #FFF;
        border: 0;
      }
      td {
        vertical-align: middle;
        &.multiple-register {
          span {
            border-bottom: 1px solid #8e8e8e;
            padding-bottom: 5px;
            &:last-child {
              border: 0;
            }
          }
        }
      }
    }
  }
  @media (max-width: 950.98px) {
    #detail-task {
      margin-left: 0;
      .modal-dialog {
        max-width: 90%;
        margin-left: 10%;
        margin-right: 10%;
      }
    }
  }
  @media (min-width: 950.98px) {
    #detail-task, #pdf-modal {
      margin-left: 0;
      .modal-dialog {
        max-width: 90%;
        margin-left: 15%;
        margin-right: 10%;
      }
    }
  }
  .container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
button, input, optgroup, select, textarea {
  padding-left: 10px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.btn-refresh {
  // background-color: #39C2A1;
  background-color: #03185A;
  display: flex;
  box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
  font-weight: 600;
  border-radius: 10px;
  border: 0;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  margin-bottom: 1rem;
}
.my-class-form-control-group{
  display: flex;
  align-items: center;
  margin-left: 60vw;
  padding-bottom: 2rem;
}
.titleFilters {
  margin-right: 5rem;
}
.font-weight {
  font-weight: 600;
}
.width-form-control {
  min-width: 100px;
}
.multiselect-supply {
  min-width: 160px;
}
input.width-form-control[type=number]::-webkit-inner-spin-button,
input.width-form-control[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.width-form-control[type=number] {
  -moz-appearance:textfield;
}

.icon-create-row {
  font-size: x-large;
}
.icon-create-row:hover {
  cursor: pointer;
}
.contentTransportOrden{
  background-color: transparent !important;
}
.contentTransportOrden td {
  padding: 10px 0 !important;
}
.row_data_activitity {
  flex-direction: column;
  width: 100%;
}
.height-400 {
  min-height: 400px;
}
.content_add_row {
  position: relative;
  height: 8px;
  right: 0;
  left: 0;
  border-bottom: 3px solid #39C2A1;
  height: 16px;
  margin-bottom: 16px;
}
.content_add_row button {
  padding: 0;
  background-color: white;
  width: 80px;
  height: 28px;
  font-size: 20px;
  border: 2px solid #39C2A1;
  position: absolute;
  bottom: -16px;
  border-radius: 15%;
  transform: rotate(0deg);
  transition: left 0.1s ease;
  cursor: pointer;
}
.content_add_row button:hover,
.content_add_row button:active,
.content_add_row button:focus,
.content_add_row button:focus-visible {
  transition: left 0.1s ease;
  background-color: white !important;
  border: 2px solid #39C2A1 !important;
  cursor: pointer;
}
.content_add_row button i {
  transform: rotate(0deg);
  transition: transform 0.25s ease-out;
  cursor: pointer;
}
.content_add_row button:hover i {
  transform: rotate(90deg);
  transition: transform 0.25s ease;
  cursor: pointer;
}
</style>
