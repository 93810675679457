<template>
  <div class="form-plan-produccion">
    <div>
      <div class="row box-option mx-2">
        <div class="col-md-6 py-4 pr-4">
          <div class="text-left">
            <p class="font-weight-bold">Referencia</p>
            <div class="pb-5">
              <multiselect v-model="referencia" :options="dataReferencias"
                :disabled="this.$store.state.editarReferenciaPlan ? '' : false" @select="selectReference(referencia)"
                placeholder="Seleccione una referencia" label="ref" track-by="ref">
              </multiselect>
            </div>
            <div class="form-group row">
              <label for="example-date-input" class="col-2 col-form-label font-weight-bold">Fecha</label>
              <div class="col-10">
                <input class="form-control" v-model="dateProduction" type="date" :disabled="this.$store.state.editarReferenciaPlan ? '': false">
              </div>
            </div>
            <div class="row align-items-center pt-3">
              <div class="col-5 text-left">
                <p class="font-weight-bold m-0">Cantidad</p>
              </div>
              <div class="col-7">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-btn variant="info" @click="cantidadMin()"><i class="fas fa-caret-left"></i></b-btn>
                  </b-input-group-prepend>
                  <b-form-input type="number" min="0" v-model="cantidad" @change="updateHour(),selectReference(referencia)"></b-form-input>
                  <b-input-group-append>
                    <b-btn variant="info" @click="cantidadMax()"><i class="fas fa-caret-right"></i></b-btn>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="alert alert-danger mt-4 px-3 w-100" role="alert" v-if="cantidad < 0">* La cantidad no puede <br> ser menor a uno</div>
            </div>
            <div class="row align-items-center mt-4">
              <div class="col-5 text-left">
                <p class="font-weight-bold m-0">Horas de producción</p>
              </div>
              <div class="col-7">
                <b-form-input  class="horas-input" v-model="horas" disabled></b-form-input>
              </div>
            </div>
            <div class="mt-4 text-left">
              <p class="font-weight-bold m-0">Ficha técnica</p>
              <div class="box-add-file mt-3 text-center">
                <div class="px-3 py-4" v-show="!fichaTecnica">
                  <label for="fileFichaTecnica"><img src="~@/assets/icons/uploadFile.png" class="img-fluid"> <br> Adjuntar archivo</label>
                  <input type="file" name="file" id="fileFichaTecnica" @change="fileFichaTecnica($event)" class="inputfile" />
                </div>
                <div class="col-10 m-auto pt-4" v-show="fichaTecnica">
                  <div>
                    <img :src="fichaTecnica" class="img-fluid m-auto" alt="">
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <b-button v-b-modal.zoom-file class="color-grayLight col" variant="link"><i class="fas fa-search-plus h3"></i></b-button>
                    <span class="h2 font-weight-light col color-grayLight"> | </span>
                    <b-button class="col" variant="link" @click="deleteFile()"><i class="fas fa-trash-alt text-danger h3"></i></b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 py-4 border-custom-left">
          <div class="text-left">
            <p class="font-weight-bold border-bottom pb-2">Nombre</p>
            <div class="p-3 box-simple" style="height:120px;">
              {{referencia.nombre}}
            </div>
          </div>
          <div class="text-left mt-4" v-if="bases.length">
            <p class="font-weight-bold border-bottom pb-2">Bases</p>
             <table class="w-100 table-bases">
                <tbody>
                  <tr v-for="base in bases" :key="base.reference">
                    <td><strong>{{base.reference}}</strong></td>
                    <td>{{base.name}}</td>
                  </tr>
                </tbody>
              </table>
          </div>
          <div class="text-left mt-4" v-if="insumos.length">
            <p class="font-weight-bold border-bottom pb-2">Insumos</p>
             <table class="w-100 table-bases">
                <tbody>
                  <!--  -->
                  <tr v-for="insumo in insumos" :key="insumo.id">
                    <td><strong>{{insumo.id}}</strong></td>
                    <td>{{insumo.name}}</td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
import dataproduct from '../../public/product.json'
*/
import Multiselect from 'vue-multiselect'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url

export default {
  name: 'FormPlanProduccion_familia',
  props: {
    icon: String,
    data: Array,
    extraData: Array
  },
  components: {
    Multiselect
  },
  data () {
    return {}
  },
  computed: {
    dataReferencias () {
      return this.$store.state.dataReferencias
    },
    referenciaObj () {
      return this.$store.state.storeProduction.referencia
    },
    dateProduction: {
      get () {
        return this.$store.state.storeProduction.date
      },
      set (value) {
        this.$store.state.storeProduction.date = value
      }
    },
    cantidad: {
      set (value) {
        this.$store.state.cantidadPlan = value
      },
      get () {
        return this.$store.state.cantidadPlan
      }
    },
    horas: {
      set (value) {
        this.$store.state.horasPlan = value
      },
      get () {
        return this.$store.state.horasPlan
      }
    },
    referencia: {
      set (value) {
        this.$store.state.storeProduction.referencia = value
      },
      get () {
        return this.$store.state.storeProduction.referencia
      }
    },
    bases () {
      return this.$store.state.storeProduction.bases
    },
    insumos () {
      return this.$store.state.storeProduction.insumos
    },
    fichaTecnica: {
      get () {
        return this.$store.state.storeProduction.fichaTecnica
      },
      set (value) {
        this.$store.state.storeProduction.fichaTecnica = value
      }
    }
  },
  mounted () {
    const self = this
    if (sessionStorage.token) {
      self.$store.state.dataReferencias = []
      axios
        .get('/api/product/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
        .then(function (response) {
          response.data.forEach(function (item, index) {
            var data = { nombre: item.name, ref: item.reference }
            self.$store.commit('actionSetDataReferencias', data)
          })
        })
    }
  },
  methods: {
    getImgUrl: function (pic) {
      return require('../assets/icons/' + pic + '.png')
    },
    cantidadMin: function () {
      const self = this
      if (this.cantidad !== 0) {
        this.cantidad--
      }
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
    },
    updateHour: function () {
      const self = this
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
    },
    cantidadMax: function () {
      const self = this
      this.cantidad++
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
    },
    selectReference: function (id) {
      const self = this
      self.$store.state.referenciaPlan = id.ref
      axios.get('/api/productinfo/' + id.ref, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.$store.commit('actionSetBases', response.data.Bases)
        self.$store.commit('actionSetInsumos', response.data.Supplies)
        self.$store.commit('actionSetLotes', response.data.Batchs)
        if (self.referencia != null) {
          self.ppk_standard = response.data.Product.ppk_standard
          var personStandard = self.ppk_standard
          var hours = (self.cantidad * 60 / personStandard) / 60
          self.$store.state.horasPlan = Number(hours).toFixed(2)
        }
      })
    },
    fileFichaTecnica: function (event) {
      const self = this
      var reader = new FileReader()
      reader.onload = function (e) {
        self.fichaTecnica = e.target.result
      }
      reader.readAsDataURL(event.target.files[0])
    },
    deleteFile: function () {
      document.getElementById('fileFichaTecnica').value = ''
      this.$store.commit('actionSetFichaTecnica', '')
    },
    close_modal_zoom: function () {
      this.$refs['close-modal'].hide()
    }
  }
}
</script>

<style lang="scss" scoped>
  // Plan de Producción -------------------------------------------------
  .box-simple {
    background: #FFF;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  .inputs {
    width: 42vh !important;
  }
  .title {
    margin-top: 1rem;
  }
  input {
    width: 100%;
    margin-bottom: 25px;
    padding: 8px 12px;
    // box-shadow: 0 1px 2px #0064b7;
    border: none;
    border-radius: 5px;
    &:hover, &:active, &:focus {
      outline: none;
      box-shadow: 0 1px 8px #0064b7;
    }
  }
  .tags {
    .tag {
        position: relative;
        display: inline-block;
        margin: 0 10px 10px 0;
        padding: 8px 30px 8px 12px;
        color: #fff;
        background: #03185a;
        border: 1px solid #03185a;
        border-radius: 5px;
        cursor: pointer;
        &:after {
          position: absolute;
          right: 8px;
          color: #39c2a1;
          content: '\00d7';
        }
      }
  }
</style>
