import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from '../views/Inicio.vue'
import Produccion from '../views/PlanProduccion.vue'
import Linea from '../views/AperturaLinea.vue'
import Trazabilidad from '../views/Trazabilidad.vue'
import Actividad from '../views/Actividad.vue'
// import Indicadores from '../views/Indicadores.vue'
// We import the component from the file EstadoLinea
import Estado from '../views/EstadoLinea.vue'
import ControlCalidad from '../views/ControlCalidad.vue'
import Orden from '../views/OrdenProduccion.vue'
import OrdenCompra from '../views/OrdenCompra.vue'
import NotaDespacho from '../views/NotaDespacho.vue'
import ListaEmpaque from '../views/ListaEmpaque.vue'
import Picking from '../views/Picking.vue'
import ControlInventario from '../views/ControlInventario.vue'
import IndicadoresPrueba from '../views/IndicadoresPrueba.vue'
import Costos from '../views/Costos.vue'
import Analitica from '../views/Analitica.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/plan-produccion',
    name: 'PlanProduccion',
    component: Produccion
  },
  {
    path: '/apertura-linea',
    name: 'AperturaLinea',
    component: Linea
  },
  {
    path: '/trazabilidad',
    name: 'Trazabilidad',
    component: Trazabilidad
  },
  {
    path: '/actividad',
    name: 'Actividad',
    component: Actividad
  },
  // {
  //   path: '/indicadores',
  //   name: 'Indicadores',
  //   component: Indicadores
  // },
  // We indicate the route that will have in the browser
  {
    path: '/estado-linea',
    name: 'Estado',
    component: Estado
  },
  {
    path: '/control-calidad',
    name: 'ControlCalidad',
    component: ControlCalidad
  },
  {
    path: '/orden-produccion',
    name: 'Orden',
    component: Orden
  },
  {
    path: '/orden-compra',
    name: 'OrdenCompra',
    component: OrdenCompra
  },
  {
    path: '/nota-despacho',
    name: 'NotaDespacho',
    component: NotaDespacho
  },
  {
    path: '/lista-empaque',
    name: 'ListaEmpaque',
    component: ListaEmpaque
  },
  {
    path: '/picking',
    name: 'Picking',
    component: Picking
  },
  {
    path: '/control-inventario',
    name: 'ControlInventario',
    component: ControlInventario
  },
  {
    path: '/indicadores',
    name: 'IndicadoresPrueba',
    component: IndicadoresPrueba
  },
  {
    path: '/costos',
    name: 'Costos',
    component: Costos
  },
  {
    path: '/analitica',
    name: 'Analitica',
    component: Analitica
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
