<template>
  <div class="page apertura-linea px-3 py-5 px-md-5">
    <div class="header-page">
      <div class="col-9"><h3>Apertura de línea</h3></div>
      <div>
        <div class="justify-content-md-end mb-4 button-modal">
          <div class="col-xl-3">
            <b-button v-if="is_admin() || is_rol4()" class="btn-action-page" @click="$bvModal.show('history-by-lines')" size="lg">Histórico por líneas</b-button>
          </div>
          <div class="col-xl-3">
            <b-button class="btn-action-page" @click="$bvModal.show('modal-bulk-load')" size="lg">Carga masiva</b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="body-page py-4">
      <div class="sub-page sub-page-start d-flex justify-content-center" :class="{ 'align-items-start': listTeam, 'align-items-center': !listTeam }">
        <div class="item-page-new" v-if="!listTeam">
          <div class="circle-icon-page d-flex justify-content-center align-items-center">
            <div>
              <img src="~@/assets/pages/team.png">
            </div>
          </div>
          <div class="pt-4">
            <h5 class="font-weight-bold">Cree una nueva línea</h5>
          </div>
          <div class="pt-3">
            <b-button class="btn-action-page py-2" @click="showBlock()" size="lg">Agregar línea</b-button>
          </div>
        </div>
        <div class="item-page-list w-100" v-if="listTeam">
          <div>
            <div class="text-left my-3">
              <span class="icon-list icon-blue-dark"><i class="fas fa-cogs fa-1x"></i></span> <span class="mx-3 h4">Líneas</span>
            </div>
            <div class="px-4">
              <table class="w-100 table-list">
                <thead>
                  <tr>
                    <th style="width:80px;font-weight: 600;">Línea</th>
                    <th style="width:100px;font-weight: 600;" class="border-left-head">Lider</th>
                    <th style="width:280px;font-weight: 600;" class="border-left-head">Equipo</th>
                    <th style="width:25px;font-weight: 600;" class="text-center"> Editar</th>
                    <th v-if="!is_leader()" style="width:25px;font-weight: 600;" class="text-center">Eliminar</th>
                    <th v-if="is_leader()" style="width:25px;font-weight: 600;" class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in infoUsers" :key="i">
                    <td> <span class="h5">{{item.name_line}}</span></td>
                    <td class="column-center">
                      <img src="~@/assets/icons/leader.png" class="rounded-circle avatar-user-team" alt="">
                      <div>{{ item.leader.first_name }} {{ item.leader.last_name }}</div>
                    </td>
                    <td class="py-3">
                      <div class="row">
                        <div v-for="itemUser in item.team" :key="itemUser.id" class="col-6">
                          <img src="~@/assets/icons/user.png" class="rounded-circle avatar-user-team" alt="">
                          <p class="my-2">{{itemUser.first_name}} {{itemUser.last_name}}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button class="btn" @click="showBlock();setInfoForm(item.line);emitLoadDataToForm();updateLine();"><i class="far fa-edit text-success"></i></button>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button v-if="!is_leader()" class="btn" @click="$bvModal.show('delete-task'); TeamToDel(item.line)"><i class="far fa-trash-alt text-danger"></i></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="!have_team() && is_leader() || !is_leader()" class="my-5">
                <b-button class="btn-action-page py-2" @click="showBlock();" size="lg">Agregar nueva línea</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidenav-page p-4" :class="{ openSidebar: openOptions}">
      <side-block
        :icon="'team-interna'"
        :tpl="'team'"
        :data="simpleUser"
        @closeBlock="close"
      />
    </div>
    <!-- Modal histórico por líneas -->
    <b-modal id="history-by-lines" size="xl" no-close-on-backdrop centered :hide-footer="true" :hide-header="true">
      <div class="px-3 my-5">
        <div class="text-right box-close-block" @click="$bvModal.hide('history-by-lines');resetDateLinesList()">
          <i class="far fa-times-circle fa-2x btn-close"></i>
        </div>
        <div class="d-flex align-items-left justify-content-left pb-4">
          <div class="h5 text-left color-bluedark font-weight-bold">Fecha de búsqueda</div>
        </div>
        <div>
          <div class="row">
            <div class="col">
              <input type="date" class="form-control" v-model="dateLines">
            </div>
            <div class="col">
              <b-button class="btn-action-filter" @click="getInfoLineAdmin(dateLines)">Buscar</b-button>
            </div>
          </div>
        </div>
        <div class="item-page-list mt-5" v-if="listTeam">
          <div>
            <div class="text-left my-3" v-if="this.dateLinesList.length > 0">
              <span class="icon-list icon-blue-dark mb-3"><i class="fas fa-cogs fa-1x"></i></span><span class="mx-3 h5">Líneas de producción del día <span class="font-weight-bold ml-2">{{ dateLines }}</span></span>
            </div>
            <div class="px-4" v-if="this.dateLinesList.length > 0">
              <table class="w-100 table-list">
                <thead>
                  <tr>
                    <th style="width:40px;font-weight: 600;">Línea</th>
                    <th style="width:80px;font-weight: 600;" class="border-left-head">Lider</th>
                    <th style="width:200px;font-weight: 600;" class="border-left-head">Equipo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in dateLinesList" :key="i">
                    <td>
                      <span class="h5 px-1 py-5 w-100 h-100 d-inline-block block-user-to-select" :class="{ 'active-lineuser-select': checkSelectLineUserRecordLine(i) }" @click="selectLineUserRecordsByLine(i)">
                        <span class="position-relative d-inline-block">
                          <span class="px-2">{{item.name_line}}</span>
                          <img src="~@/assets/icons/check.png" v-if="checkSelectLineUserRecordLine(i)" class="rounded-circle active-lineuser-icon" alt="">
                        </span>
                      </span>
                    </td>
                    <td class="column-center">
                      <div class="column align-items-center justify-content-center">
                        <div v-for="(itemUser, itemUserIndex) in item.leader" :key="itemUserIndex" class="col-6 p-1 w-100 d-inline-block block-user-to-select" @click="assignLeaderSelectedLineUser(i, itemUserIndex)" :class="{ 'active-lineuser-select': itemUser.checked }">
                          <span class="position-relative d-inline-block">
                            <img src="~@/assets/icons/leader.png" class="rounded-circle avatar-user-team" alt="">
                            <img src="~@/assets/icons/check.png" v-if="itemUser.checked" class="rounded-circle active-lineuser-icon" alt="">
                          </span>
                          <p class="my-2">{{itemUser.first_name}} {{itemUser.last_name}}</p>
                        </div>
                      </div>
                    </td>
                    <td class="py-3">
                      <div class="row align-items-center justify-content-center">
                        <div v-for="(itemUser, i) in item.team" :key="i" class="col-5 block-user-to-select" :class="{ 'active-lineuser-select': itemUser.checked }" @click="itemUser.checked = !itemUser.checked">
                          <span class="position-relative d-inline-block">
                            <img src="~@/assets/icons/user.png" class="rounded-circle avatar-user-team" alt="">
                            <img src="~@/assets/icons/check.png" class="rounded-circle active-lineuser-icon" alt="" v-if="itemUser.checked">
                          </span>
                          <p class="my-2">{{itemUser.first_name}} {{itemUser.last_name}}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="my-5">
                <div class="d-flex justify-content-center align-items-center">
                  <b-form-checkbox class="py-2 my-2" switch id="inactiveTodayLines" v-model="inactiveTodayLines" value="true" unchecked-value="false">Inactivar los registros de hoy</b-form-checkbox>
                </div>
                <b-button class="btn-action-page py-2" @click="bulkLoadOflines(dateLines)" size="lg">Seleccionar como base de líneas actuales</b-button>
              </div>
            </div>
            <div  class="center" v-if="this.dateLinesList.length === 0">
              <span><b-badge variant="danger align-middle" style="display: flex;justify-content: center;"><h6>No existen registros en la base de datos.</h6></b-badge></span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-bulk-load" size="xl" no-close-on-backdrop centered :hide-footer="true" :hide-header="true">
      <div class="px-3 my-5">
        <div class="text-right box-close-block" @click="$bvModal.hide('modal-bulk-load')">
          <i class="far fa-times-circle fa-2x btn-close"></i>
        </div>
        <div class="d-flex align-items-center justify-content-center pb-4">
          <div class="h3 text-center color-bluedark font-weight-bold">Carga masiva de usuarios</div>
        </div>
        <div class="form-group align-items-center text-center">
          <label for="formFileSm" class="form-label font-weight-bold">Seleccione el archivo a cargar</label>
          <input class="form-control form-control-sm" id="fileUsers" type="file" />
        </div>
        <div class="d-flex text-center justify-content-around col">
          <div class="text-center mt-3">
            <b-button class="btn-tracking" @click="template_download();" style="margin: 5%; padding:5%;">Descargar Plantilla</b-button>
          </div>
          <div class="text-center mt-3">
            <b-button class="btn-tracking" @click="bulkLoadUsers();" style="margin: 5%; padding:5%;">{{ textButtonLoad }}</b-button>
          </div>
          <div class="text-center mt-3">
            <b-button class="btn-tracking" @click="syncUsersSheet();" style="margin: 5%; padding:5%;">Sincronizar Usuarios</b-button>
          </div>
          <div class="text-center mt-3">
            <b-button id="btnOpenGuide" class="btn-tracking" @click="open_guide_to_upload_users_csv();" style="margin: 5%; padding:5%;">Abrir Instructivo</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="modal-delete-task">
      <b-modal ref="close-modal" id="delete-task" centered :hide-footer="true" :hide-header="true" >
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="py-5">
                <div class="circle-icon-page d-flex justify-content-center align-items-center">
                  <div>
                    <img src="~@/assets/icons/trash.png" class="img-fluid">
                  </div>
                </div>
                <div class="pt-4 text-center">
                  <h5 class="font-weight-bold color-bluedark">¿Está seguro de que <br> desea eliminar la ĺínea?</h5>
                  <div class="mt-4">
                    <b-button  size="lg" class="btn-actions btn-cancel mx-3" @click="setInfoForm();close_modal();">Cancelar</b-button>
                    <b-button  size="lg" class="btn-actions btn-delete mx-3" @click="delTeam()">Eliminar</b-button>
                  </div>
                </div>
            </div>
          </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import SideBlock from '@/components/SideBlock.vue'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  data: () => ({
    textButtonLoad: 'Cargar Usuarios',
    listTeam: true,
    inactiveTodayLines: true,
    dateLines: null
  }),
  components: {
    SideBlock
  },
  computed: {
    openOptions () {
      return this.$store.state.showOptions
    },
    infoUsers () {
      return this.$store.state.storeLine.infoUsers
    },
    simpleUser () {
      return this.$store.state.storeLine.simpleUser
    },
    dateLinesList: {
      set (value) {
        this.$store.state.storeInventory.dateLinesList = value
      },
      get () {
        return this.$store.state.storeInventory.dateLinesList
      }
    }
  },
  async mounted () {
    const self = this
    if ((sessionStorage.token && sessionStorage.rol === '1') || sessionStorage.rol === '2' || sessionStorage.rol === '4') {
      if (sessionStorage.rol === '2') {
        axios
          .get('/api/getInfoLine/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
          .then(function (response) {
            self.$store.commit('actionSetinfoUsers', response.data)
          })
        self.$store.state.storeLine.simpleUser = []
        await axios.get('/api/availableUsers/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.$store.state.storeLine.simpleUser = response.data
          // response.data.forEach(function (item, index) {
          //   var nameFull = item.first_name + ' ' + item.last_name
          //   self.simpleUser.push({
          //     id: item.id,
          //     name: nameFull,
          //     avatar: '~@/assets/icons/user.png'
          //   })
          // })
        })
      } else {
        axios
          .get('/api/getInfoLineAdmin/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
          .then(function (response) {
            self.$store.commit('actionSetinfoUsers', response.data)
          })
        self.$store.state.storeLine.simpleUser = []
        axios.get('/api/availableUsers/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.$store.state.storeLine.simpleUser = response.data
          // response.data.forEach(function (item, index) {
          //   var nameFull = item.first_name + ' ' + item.last_name
          //   self.simpleUser.push({
          //     id: item.id,
          //     name: nameFull,
          //     avatar: '~@/assets/icons/user.png'
          //   })
          // })
        })
      }
    } else {
      this.$router.push({ name: 'Inicio' })
    }
    if (sessionStorage.token) {
      this.$store.dispatch('statusModulesEnabled')
      this.$store.dispatch('atLeastQualityRecordFn')
    }
  },
  methods: {
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    assignLeaderSelectedLineUser: function (dateLinesListindex, itemUserIndex) {
      for (let i = 0; i < this.dateLinesList[dateLinesListindex].leader.length; i++) {
        this.dateLinesList[dateLinesListindex].leader[i].checked = false
      }
      this.dateLinesList[dateLinesListindex].leader[itemUserIndex].checked = true
    },
    selectLineUserRecordsByLine: function (dateLinesListindex) {
      const status = this.checkSelectLineUserRecordLine(dateLinesListindex)
      if (!status) {
        if (this.dateLinesList[dateLinesListindex].leader.length > 0) {
          this.dateLinesList[dateLinesListindex].leader[0].checked = true
        }
        for (let i = 0; i < this.dateLinesList[dateLinesListindex].team.length; i++) {
          this.dateLinesList[dateLinesListindex].team[i].checked = true
        }
      }
      if (status) {
        for (let i = 0; i < this.dateLinesList[dateLinesListindex].leader.length; i++) {
          this.dateLinesList[dateLinesListindex].leader[i].checked = false
        }
        for (let i = 0; i < this.dateLinesList[dateLinesListindex].team.length; i++) {
          this.dateLinesList[dateLinesListindex].team[i].checked = false
        }
      }
    },
    checkSelectLineUserRecordLine: function (dateLinesListindex) {
      return this.dateLinesList[dateLinesListindex].leader.some(item => item.checked)
    },
    is_admin () {
      if (sessionStorage.getItem('rol') === '1') {
        return true
      } else {
        return false
      }
    },
    async syncUsersSheet () {
      const self = this
      self.announceToast(
        'Sincronizando usuarios',
        'Sincronizando con archivo de usuarios..',
        10000,
        'warning'
      )
      await axios.post('/api_user/syncUsersSheet/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.console_error !== undefined && response.data.console_error.length > 0) {
          console.log(response.data.console_error)
        }
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.announceToast(
            'Sincronización de usuarios exitosa',
            'Se ha sincronizado de forma exitosa los usuarios.',
            10000,
            'success'
          )
        }
      })
    },
    async bulkLoadUsers () {
      const self = this
      var file = document.getElementById('fileUsers').files[0]
      if (file != null) {
        this.textButtonLoad = 'Cargando...'
        var data = new FormData()
        data.append('file', file)
        await axios.post('/api/bulkLoadUsers/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'token ' + sessionStorage.getItem('token')
          }
        }).then(function (response) {
          if (response.data.errors === 0) {
            self.$bvToast.toast('Se han cargado todos los usuarios.', {
              title: 'Carga Éxitosa',
              variant: 'success',
              autoHideDelay: 5000,
              solid: true
            })
          } else if (response.data.errors === 1) {
            self.$bvToast.toast('No se han podido cargar todos los usuarios, puede que ya estén registrados.', {
              title: 'Carga Incompleta',
              variant: 'warning',
              autoHideDelay: 5000,
              solid: true
            })
          } else if (response.data.errors === 2) {
            self.$bvToast.toast('La extensión del archivo que está tratando de subir es incorrecta, debe subir solo archivos con extensión csv.', {
              title: 'Carga Incorrecta',
              variant: 'danger',
              autoHideDelay: 5000,
              solid: true
            })
          }
          self.$bvModal.hide('modal-bulk-load')
          self.textButtonLoad = 'Cargar Usuarios'
        }).catch(function (error) {
          console.log(error)
        })
        await axios.get('/api/availableUsers/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.$store.state.storeLine.simpleUser = response.data
          // response.data.forEach(function (item, index) {
          //   var nameFull = item.first_name + ' ' + item.last_name
          //   self.simpleUser.push({
          //     id: item.id,
          //     name: nameFull,
          //     avatar: '~@/assets/icons/user.png'
          //   })
          // })
        })
      } else {
        this.$bvToast.toast('Debes seleccionar un archivo para cargar.', {
          title: 'Archivo no seleccionado',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      }
    },
    template_download () {
      const data = `identification,first_name,last_name,rol,working_capacity,telephone,email,password
` /* <-- this(`) inverted quote was left here in order to avoid spaces once bulkUploadFormat.csv file is created */
      const blob = new Blob([data], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'bulkUploadFormat.csv'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    open_guide_to_upload_users_csv () {
      const urltemplate = url + 'media/DataSheets/Instructivo_carga_masiva_usuarios.pdf' // Ruta relativa al archivo PDF dentro de la carpeta pública
      window.open(urltemplate, '_blank')
    },
    have_team () {
      try {
        if (this.infoUsers.length === 0) {
          return false
        } else {
          return true
        }
      } catch {
        if (Array.isArray(this.infoUsers)) {
          return false
        } else {
          return true
        }
      }
    },
    is_leader () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    },
    is_rol4 () {
      if (sessionStorage.rol === '4') {
        return true
      } else {
        return false
      }
    },
    updateLine: function () {
      this.$store.commit('actionSetUpdateLine', true)
    },
    showBlock: function () {
      this.$store.commit('actionBlockOptions', true)
      this.showOptions = true
      this.listTeam = true
      this.$parent.overlayBody('on')
    },
    setInfoForm: function (line) {
      const self = this
      var lineId = line
      this.$store.commit('actionSetSelectedLine', line)
      this.$store.state.storeLine.setTeam = {}
      var auxTeam = []
      this.$store.state.storeLine.infoUsers.forEach(function (item, index) {
        if (item.line === lineId) {
          var nameLeader = item.leader.first_name + ' ' + item.leader.last_name
          self.$store.commit('actionSetLeader', {
            id: item.leader.id,
            name: nameLeader,
            avatar: '~@/assets/icons/user.png'
          })
          item.team.forEach(function (item, index) {
            var nameFull = item.first_name + ' ' + item.last_name
            auxTeam.push({
              id: item.id,
              name: nameFull,
              avatar: '~@/assets/icons/user.png'
            })
          })
        }
      })
      self.$store.commit('actionSetTeamFull', auxTeam)
    },
    close: function (value) {
      this.$store.commit('actionBlockOptions', value)
      this.$parent.overlayBody('off')
    },
    close_modal: function () {
      this.searchList = ''
      this.$refs['close-modal'].hide()
    },
    TeamToDel (lineid) {
      this.$store.commit('actionSetTeamToDel', lineid)
    },
    emitLoadDataToForm () {
      this.$root.$emit('loadDataToForm')
    },
    async delTeam () {
      const self = this
      var line = this.$store.state.storeLine.lineTeamToDel
      await axios.post('/api/delteamfromline/', {
        line_id: line
      }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        console.log(response)
      })
      if (this.is_leader()) {
        axios.get('/api/getInfoLine/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.$store.commit('actionSetinfoUsers', response.data)
        })
        this.close_modal()
      } else {
        axios.get('/api/getInfoLineAdmin/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
          .then(function (response) {
            self.$store.commit('actionSetinfoUsers', response.data)
          })
        this.close_modal()
        this.$store.state.storeLine.simpleUser = []
        await axios.get('/api/availableUsers/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          self.$store.state.storeLine.simpleUser = response.data
          // response.data.forEach(function (item, index) {
          //   var nameFull = item.first_name + ' ' + item.last_name
          //   self.$store.state.storeLine.simpleUser.push({
          //     id: item.id,
          //     name: nameFull,
          //     avatar: '~@/assets/icons/user.png'
          //   })
          // })
        })
        await axios.get('/api/getAvailableLines/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          var auxLineList = []
          response.data.json_lines.forEach(function (item, index) {
            auxLineList.push({ value: item.id, text: item.id })
          })
          self.$store.commit('actionSetLineList', auxLineList)
        })
      }
    },
    async getInfoLineAdmin (dateLine) {
      const self = this
      await axios.post('/api/getInfoLineAdmin/', { date: dateLine }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.dateLinesList = response.data
      })
    },
    resetDateLinesList () {
      const self = this
      self.dateLinesList = []
      self.dateLines = null
    },
    async bulkLoadOflines (date) {
      const self = this
      await axios.post('/api/bulkLoadOflines/', { team_list: self.dateLinesList, inactive_today_lines: self.inactiveTodayLines }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.announceToast('Líneas actualizadas', 'Se actualizó las líneas correctamente.', 2000, 'success')
      })
      if (sessionStorage.rol === '2' || sessionStorage.rol === '4') {
        axios
          .get('/api/getInfoLine/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
          .then(function (response) {
            self.$store.commit('actionSetinfoUsers', response.data)
          })
      }
      if (sessionStorage.rol === '1') {
        axios
          .get('/api/getInfoLineAdmin/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
          .then(function (response) {
            self.$store.commit('actionSetinfoUsers', response.data)
          })
      }
    }
  }
}
</script>

<style lang="scss">
.btn-action-page {
  background-color: #39C2A1;
}
  .avatar-user-team {
    width: 35px;
    height: 35px;
    border: 2px solid #03185A;
  }

  .avatar-leader {
    width: 100px;
    height: 100px;
    border: 5px solid #00B2B2;
  }

  .avatar-team {
    height: 71px;
    width: 71px;
    border: 3px solid #5900B2;
  }

  .box-search-leader {
    background-color: #ECECFB;
    border-radius: 10px !important;
    border: 1px solid #DBDBEA;
  }
  .button-modal {
    display: flex;
  }
  .btn-action-filter {
    background-color: #39C2A1;
    /* display: flex; */
    margin: auto;
    /* justify-content: center; */
    /* align-items: center; */
    box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
    font-weight: 600;
    border-radius: 10px;
    border: 0;
    /* padding: 15px 30px; */
    font-size: 1.2rem;
  }
  .table-list {
    text-align: center;
  }
  @media (max-width: 834px) {
    .btn-action-page {
      width: 132px;
      height: 61px;
      font-size: 13px;
    }
  }
  @media (max-width: 725px) {
    .btn-action-page {
      width: 130px;
      height: 40px;
      font-size: 13px;
    }
  }
  .block-user-to-select {
    border-radius: 10px;
  }
  .block-user-to-select:hover {
    cursor: pointer;
  }
  .active-lineuser-select {
    background-color: #d4faeb;
  }
  .active-lineuser-icon {
    width: 18px;
    position: absolute;
    bottom: -9px;
    right: -9px;
}
</style>
