<template>
  <div class="remission-order">
    <div>
      <div class="row box-option mx-4 center">
        <div class="col-md-10 py-4">
          <div class="text-left">
            <p class="font-weight-bold">Número de OC</p>
            <div class="py-3">
              <input type="number" v-model="codeOc" id="codeOcNote"
                :disabled="this.$store.state.editarReferenciaPlan ? '' : false">
            </div>
            <p class="font-weight-bold">Número de OP</p>
            <div class="py-3">
              <multiselect v-model="codeSelect" id="codeSelectNote" :options="dataCode"
              :disabled="this.$store.state.editarReferenciaPlan ? '' : false" placeholder="Seleccione un código de OP" @select="getProductOrderProduction(codeSelect)">
              </multiselect>
            </div>
            <p class="font-weight-bold">Referencia - lote</p>
            <div class="py-3">
              <multiselect class="mb-3" v-model="referenciaRemision" :options="list_referencias"
                @select="getBatchOrderProduction(referenciaRemision)" label="text"
                placeholder="Seleccione una referencia">
              </multiselect>
            </div>
            <p class="font-weight-bold">Lote verificado</p>
            <div class="py-3">
              <input type="number" v-model="productionBatch" id="productionBatchNote" disabled>
            </div>
            <p class="font-weight-bold">Código SIESA</p>
            <div class="py-3">
              <input type="text" v-model="codeSiesa" id="codeSiesa">
            </div>
            <p class="font-weight-bold">Estado despacho</p>
            <div class="py-3">
              <multiselect v-model="dispatchStatus" id="dispatchStatusNote" :options="dataEstado" placeholder="Seleccione un estado" label="text" track-by="text"></multiselect>
            </div>
            <div class="row align-items-center pt-3 pb-5">
              <div class="col-6 text-left">
                <p class="font-weight-bold m-0">Cantidad</p>
              </div>
              <div class="col-6">
                <b-form-input style="margin: 0; text-align: center;" type="number" min="0" v-model="cantidad"></b-form-input>
              </div>
              <div class="alert alert-danger mt-4 px-3 w-100" role="alert" v-if="cantidad < 0">* La cantidad no puede
                <br> ser menor
                a uno</div>
            </div>
            <p class="font-weight-bold">Listas de empaque</p>
            <div class="py-3" style="width: 100%; display: flex;">
              <multiselect v-model="packingsLists" id="codeSelectNote" :options="dataLista" placeholder="Seleccione un código de empaque"></multiselect>
              <b-button class="btn-action-page py-2" style="width: 10%;" size="sm" id="addRow"
                @click="addObservation()">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </b-button>
            </div>
            <div class="text-left py-3" v-if="addobservation">
              <div class="row">
                <div class="col">
                  <p class="font-weight-bold">Observaciones</p>
                  <div class="py-4">
                    <textarea v-model="observations" id="observations" style="width: 100%;resize: none; height: 200px;"></textarea>
                  </div>
                </div>
                <div class="col align-self-center">
                  <b-button v-if="addobservation" class="btn-action-page py-2" size="lg" @click="addPackingsLists()">Agregar lista</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*
import dataproduct from '../../public/product.json'
*/
import Multiselect from 'vue-multiselect'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  name: 'RemissionOrder',
  props: {
    icon: String,
    data: Array,
    dataListas: Array,
    arrayCode: Array,
    extraData: Array
  },
  components: {
    Multiselect
  },
  data () {
    return {
      tag: '',
      tags: [],
      addobservation: false
    }
  },
  computed: {
    observations: {
      set (value) {
        this.$store.state.storeProduction.observations = value
      },
      get () {
        return this.$store.state.storeProduction.observations
      }
    },
    list_referencias () {
      return this.$store.state.list_referencias
    },
    module_op () {
      return this.$store.state.storeProduction.module_op
    },
    dataEstado () {
      return this.$store.state.dataEstado
    },
    dataLista () {
      return this.$store.state.dataLista
    },
    dataCode () {
      return this.$store.state.dataCode
    },
    estadoObj () {
      return this.$store.state.storeProduction.dispatchStatus
    },
    listaObj () {
      return this.$store.state.storeProduction.packingsLists
    },
    codeLista () {
      return this.$store.state.storeProduction.codeSelect
    },
    cantidad: {
      set (value) {
        this.$store.state.cantidadPlan = value
      },
      get () {
        return this.$store.state.cantidadPlan
      }
    },
    referenciaRemision: {
      set (value) {
        this.$store.state.referenciaRemision = value
      },
      get () {
        return this.$store.state.referenciaRemision
      }
    },
    codeSelect: {
      set (value) {
        this.$store.state.storeProduction.codeSelect = value
      },
      get () {
        return this.$store.state.storeProduction.codeSelect
      }
    },
    codeOc: {
      set (value) {
        this.$store.state.ProductionPurchase = value
      },
      get () {
        return this.$store.state.ProductionPurchase
      }
    },
    codeSiesa: {
      set (value) {
        this.$store.state.codeSiesa = value
      },
      get () {
        return this.$store.state.codeSiesa
      }
    },
    dispatchStatus: {
      set (value) {
        this.$store.state.storeProduction.dispatchStatus = value
      },
      get () {
        return this.$store.state.storeProduction.dispatchStatus
      }
    },
    packingsLists: {
      set (value) {
        this.$store.state.storeProduction.packingsLists = value
      },
      get () {
        return this.$store.state.storeProduction.packingsLists
      }
    },
    productionBatch: {
      set (value) {
        this.$store.state.productionBatch = value
      },
      get () {
        return this.$store.state.productionBatch
      }
    },
    bases () {
      return this.$store.state.storeProduction.bases
    },
    insumos () {
      return this.$store.state.storeProduction.insumos
    },
    lotes () {
      return this.$store.state.storeProduction.lotes
    },
    fichaTecnica: {
      get () {
        return this.$store.state.storeProduction.fichaTecnica
      },
      set (value) {
        this.$store.state.storeProduction.fichaTecnica = value
      }
    }
  },
  mounted () {
    const self = this
    if (sessionStorage.token) {
      self.$store.state.dataLista = []
      axios
        .get('/api_orders/StartPackingList/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
        .then((response) => {
          response.data.data.forEach(function (item, index) {
            self.$store.state.dataLista.push(item.pk)
          })
        })
      this.$root.$on('selectRef', () => {
        this.selectReference(self.packingsLists)
      })
      self.$store.state.dataCode = []
      axios.get('/api/getRefListProductionControl/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        self.$store.state.dataCode = response.data
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  methods: {
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    getImgUrl: function (pic) {
      return require('../assets/icons/' + pic + '.png')
    },
    async addObservation () {
      if (this.addobservation === true) {
        this.addobservation = false
        this.$store.state.storeProduction.observations = ''
      } else {
        this.addobservation = true
      }
    },
    addPackingsLists () {
      const self = this
      var observations = this.$store.state.storeProduction.observations
      axios
        .post('/api_orders/CreatePackingList/', { observations: observations }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
        .then(function (response) {
          if (response.data.Status === 'exito') {
            self.announceToast('Éxito', 'Se agrego correctamente la lista de empaque.', 2000, 'success')
            self.$store.state.dataLista = []
            axios
              .get('/api_orders/StartPackingList/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
              .then(function (response) {
                response.data.data.forEach(function (item, index) {
                  self.$store.state.dataLista.push(item.pk)
                })
                for (const elemento of response.data.data) {
                  self.$store.state.storeProduction.packingsLists = elemento.pk
                  break // Sale del bucle después de guardar el primer elemento
                }
                self.$store.state.storeProduction.observations = ''
                self.addobservation = false
              })
          } else {
            self.announceToast('Error', 'La lista de empaque no fue creada intente de nuevo.', 2000, 'danger')
          }
        })
    },
    async getBatchOrderProduction (referenceValue) {
      this.productionBatch = null
      if (referenceValue.lote) {
        this.productionBatch = referenceValue.lote
      } else {
        this.productionBatch = null
      }
    },
    async getProductOrderProduction (value) {
      this.referenciaRemision = null
      this.productionBatch = null
      this.$store.state.list_referencias = []
      await axios.post('/api_orders/getReferencesByOp/', { OP: value }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        response.data.forEach((item, index) => {
          this.$store.state.list_referencias.push({ ref: item.product_code__reference, lote: item.batch, text: item.product_code__reference + ' - ' + item.batch })
        })
      }).catch((error) => {
        console.error(error)
      })
    },
    fileFichaTecnica: function (event) {
      const self = this
      var reader = new FileReader()
      reader.onload = function (e) {
        self.fichaTecnica = e.target.result
      }
      reader.readAsDataURL(event.target.files[0])
    },
    deleteFile: function () {
      document.getElementById('fileFichaTecnica').value = ''
      this.$store.commit('actionSetFichaTecnica', '')
    },
    close_modal_zoom: function () {
      this.$refs['close-modal'].hide()
    }
  }
}
</script>
<style lang="scss" scoped>
  // Plan de Producción -------------------------------------------------
  .box-simple {
    background: #FFF;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  .multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    margin-right: 20px;
    min-height: 40px;
    text-align: left;
    color: #35495e;
}
  .inputs {
    width: 42vh !important;
  }
  .title {
    margin-top: 1rem;
  }
  input {
    width: 100%;
    margin-bottom: 25px;
    padding: 8px 12px;
    // box-shadow: 0 1px 2px #0064b7;
    border: none;
    border-radius: 5px;
    &:hover, &:active, &:focus {
      outline: none;
      box-shadow: 0 1px 8px #0064b7;
    }
  }
  .tags {
    .tag {
        position: relative;
        display: inline-block;
        margin: 0 10px 10px 0;
        padding: 8px 30px 8px 12px;
        color: #fff;
        background: #03185a;
        border: 1px solid #03185a;
        border-radius: 5px;
        cursor: pointer;
        &:after {
          position: absolute;
          right: 8px;
          color: #39c2a1;
          content: '\00d7';
        }
      }
  }
</style>
