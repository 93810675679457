<template>
  <div>
    <input class="custom-select" type="text" v-model="search" @input="changeSearch()" />
    <div class="autocomplete-result" v-show="isOpen">
      <div v-for="(result, i) in results" :key="i">
        <div class="d-flex align-items-center bg-white p-2 border" @click="setResult(result);">
          <div>
            <img src="~@/assets/icons/user.png" class="rounded-circle avatar-user-team mr-3" alt="avatar">
          </div>
          <div>
            {{result.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Autocomplete',
  props: {
    data: Array,
    typeData: String
  },
  data () {
    return {
      search: '',
      results: [],
      isOpen: false
    }
  },
  methods: {
    changeSearch: function () {
      this.isOpen = true
      this.filterResults()
    },
    filterResults: function () {
      const self = this
      this.results = this.data.filter(function (value, index, arr) {
        if (value.name.toLowerCase().indexOf(self.search.toLowerCase()) > -1) {
          return arr
        }
      })
    },
    setResult: function (result) {
      if (this.typeData === 'leader') {
        this.$store.commit('actionSetLeader', result)
        this.$emit('closeSearchLeader', false)
      } else if (this.typeData === 'team') {
        this.$store.commit('actionSetTeam', result)
        this.$emit('closeSearchTeam', false)
      }
      this.isOpen = false
    }
  }
}
</script>
