<template>
  <div class="page indicadores px-3 py-5 px-md-5">
    <div class="header-page">
      <h3>Costos del día <span style="margin-left: 5vh;">{{ this.$store.state.storeCost.dateInfo }}</span></h3>
    </div>
    <div class="py-4">
      <div :class="{ 'align-items-start': listTrack, 'align-items-center': !listTrack }">
        <!-- Pantalla Inicio Costos-->
        <div class="item-page-list w-100" v-if="listTrack">
          <div>
            <div class="row justify-content-end text-left my-3 d-flex">
              <div class="col-xl-2" v-if="this.$store.state.storeCost.show_btn_return !== false">
                <b-button class="btn-action-back py-2" size="lg" @click="costModuleFunction()">Volver a {{ this.$store.state.storeCost.dateOfToday }}</b-button>
              </div>
              <div class="col-xl-2">
                <b-button class="btn-action-cost py-2" @click="$bvModal.show('price-construction'); priceConstructionFunction()" size="lg">Construcción de precios</b-button>
              </div>
              <div class="col-xl-2">
                <b-button class="btn-action-cost py-2" @click="$bvModal.show('reference-cost'); personalCostReferenceSuppliesFunction()" size="lg">Insumos por referencia</b-button>
              </div>
              <div class="col-xl-2">
                <b-button class="btn-action-cost py-2" @click="$bvModal.show('staff-costs'); personalCostFunction()" size="lg">Costo del personal</b-button>
              </div>
              <div class="col-xl-2">
                <b-button class="btn-action-cost py-2" @click="$bvModal.show('view-per-day');graphicModal()" size="lg">Histórico por fechas</b-button>
              </div>
            </div>
            <div class="table-responsive mt-5">
              <table v-if="loadTable" class="table list-track" v-bind:class="{'table-list': noExistRecordsMain }">
                <thead>
                  <tr>
                    <th class="text-right" style="height: 3rem;" colspan="10">{{ lenRowsCostModule }} registros</th>
                  </tr>
                </thead>
                <tbody v-if="costModule.length > 0">
                  <tr class="subhead-titles">
                    <td>N° OP</td>
                    <td>Referencia</td>
                    <td>Descripción</td>
                    <td>Ctd. en Und</td>
                    <td>Valor Caja Venta</td>
                    <td>Vr Total Caja</td>
                    <td>Costo Caja</td>
                    <td>Costo Total Caja</td>
                    <td>Utilidad</td>
                    <td>% Utilidad</td>
                  </tr>
                  <tr v-for="(k, i) in costModule" :key="i" :class="{'total-tr': k.type_row === 'total'}">
                    <td v-if="k.type_row === 'normal'">
                      <span class="d-block">{{ k.n_op }}</span>
                    </td>
                    <td v-else-if="k.type_row === 'total'" class="total-transparent">
                      <span class="d-block">{{ k.n_op }}</span>
                    </td>
                    <td v-if="k.type_row === 'normal'">
                      <span class="d-block">{{ k.id }}</span>
                    </td>
                    <td v-else-if="k.type_row === 'total'" class="total-transparent">
                      <span class="d-block">{{ k.id }}</span>
                    </td>
                    <td v-if="k.type_row === 'normal'">
                      <span class="d-block">{{ k.name }}</span>
                    </td>
                    <td v-else-if="k.type_row === 'total'" class="total-transparent">
                      <span class="d-block">{{ k.name }}</span>
                    </td>
                    <td v-if="k.type_row === 'normal'">
                      <span class="d-block">{{ k.quantity_unds }}</span>
                    </td>
                    <td v-else-if="k.type_row === 'total'" class="total">
                      <span class="d-block">{{ k.quantity_unds }}</span>
                    </td>
                    <td v-if="k.type_row === 'normal'">
                      <span class="d-block">{{ k.box_sale_value }}</span>
                    </td>
                    <td v-else-if="k.type_row === 'total'" class="total">
                      <span class="d-block">{{ k.box_sale_value }}</span>
                    </td>
                    <td v-if="k.type_row === 'normal'">
                      <span class="d-block">{{ k.total_box_sale_value }}</span>
                    </td>
                    <td v-else-if="k.type_row === 'total'" class="total">
                      <span class="d-block">{{ k.total_box_sale_value }}</span>
                    </td>
                    <td v-if="k.type_row === 'normal'">
                      <span class="d-block">{{ k.box_cost_value }}</span>
                    </td>
                    <td v-else-if="k.type_row === 'total'" class="total">
                      <span class="d-block">{{ k.box_cost_value }}</span>
                    </td>
                    <td v-if="k.type_row === 'normal'">
                      <span class="d-block">{{ k.total_box_cost_value }}</span>
                    </td>
                    <td v-else-if="k.type_row === 'total'" class="total">
                      <span class="d-block">{{ k.total_box_cost_value }}</span>
                    </td>
                    <td v-if="k.type_row === 'normal'">
                      <span class="d-block">{{ k.utility }}</span>
                    </td>
                    <td v-else-if="k.type_row === 'total'" class="total">
                      <span class="d-block">{{ k.utility }}</span>
                    </td>
                    <td v-if="k.type_row === 'normal'">
                      <span class="d-block">{{ k.percentage_utility }}</span>
                    </td>
                    <td v-else-if="k.type_row === 'total'" class="total">
                      <span class="d-block">{{ k.percentage_utility }}</span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="bg-danger text-light">
                      No existen registros
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr class="separator">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body-page py-2">
      <div class="row no-gutters">
        <!-- Ventas totales VS Costos totales -->
        <!-- <div class="col-md-6 my-4" v-if="loadCumplimiento"> -->
        <div class="col-md-6 my-4">
          <div>
            <div class="title-indicator text-center">
              Ventas totales VS Costos totales
            </div>
          </div>
          <div class="p-4">
            <canvas id="CostPriceGraphic"></canvas>
            <!-- <CostPriceGraphic></CostPriceGraphic> -->
          </div>
        </div>
        <!-- VS % Utilidad -->
        <!-- <div class="col-md-6 my-4" v-if="loadCumplimiento"> -->
        <div class="col-md-6 my-4">
          <div>
            <div class="title-indicator text-center">
              % Utilidad
            </div>
          </div>
          <!-- <div class="p-4">
            <UtilityGraphic></UtilityGraphic>
          </div> -->
          <div class="p-4">
            <canvas id="UtilityGraphic"></canvas>
          </div>
        </div>
      </div>
    </div>
    <!-- View per day -->
    <b-modal ref="view-per-day" id="view-per-day" centered size="xl" no-close-on-backdrop :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="close_sub_modal()">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div class="px-3 mb-3">
        <h4 class="color-bluedark">Visualización por día</h4>
        <hr>
        <div class="row justify-content-start mt-5">
          <div class="col-3">
            <p>Fecha inicial:</p>
            <div>
              <input v-model="initDate" class="form-control custom-input" type="date">
            </div>
          </div>
          <div class="col-3">
            <p>Fecha final:</p>
            <div>
              <input v-model="endDate" class="form-control custom-input" type="date">
            </div>
          </div>
          <div class="col-1 allData">
            <b-button class="btn-action-back py-2" size="lg" @click="filterViewPerDay(initDate, endDate)">Filtrar</b-button>
          </div>
          <div class="table-responsive mt-4" style="text-align: center">
            <table v-if="loadTable" class="table table-list list-track">
              <thead>
                <tr>
                  <th style="height: 3rem;"></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="subhead-titles">
                  <td>Fecha</td>
                  <td>CTD. En Und</td>
                  <td>Ingresos Total Caja</td>
                  <td>Gastos Total Caja</td>
                  <td>Utilidad</td>
                  <td>% Utilidad</td>
                  <td></td>
                </tr>
                <tr v-for="(k,i) in ViewPerDay" :key="i">
                  <td><span class="d-block">{{ k.date }}</span></td>
                  <td><span class="d-block">{{ k.quantity_unds }}</span></td>
                  <td><span class="d-block">{{ k.sale_total }}</span></td>
                  <td><span class="d-block">{{ k.cost_total }}</span></td>
                  <td><span class="d-block">{{ k.utility }}</span></td>
                  <td><span class="d-block">{{ k.percentage_utility }}</span></td>
                  <td>
                    <button class="btn py-0 px-2" @click="costModuleFunction(k.date)"><img src="~@/assets/icons/ojo.png" alt=""></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="my-4 justify-content-center" v-if="loadCumplimiento"> -->
        <div class="my-4 justify-content-center" v-if="loadCumplimiento">
          <div class="p-4 mt-4">
            <canvas id="CostPriceGraphicModal"></canvas>
            <!-- <CostPriceGraphic></CostPriceGraphic> -->
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Staff costs -->
    <b-modal ref="staff-costs" id="staff-costs" centered size="xl" :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="close_sub_modal()">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div class="px-3 mb-3">
        <h4 class="color-bluedark">Costo del personal</h4>
        <hr>
        <div class="row justify-content-start mt-4">
          <div class="col-3">
            <b-button class="button-view-per-day py-2" @click="$bvModal.show('charges-modal');personalCostJobTitleFunction()" size="lg">Cargos</b-button>
          </div>
          <div class="col-3">
            <b-button class="button-view-per-day py-2" @click="$bvModal.show('historical-by-collaborator'); personalCostHistoricFunction()" size="lg">Histórico por colaborador</b-button>
          </div>
          <div class="col-3">
            <b-button class="button-view-per-day py-2" @click="$bvModal.show('benefits-costs');personalCostServiceCostFunction()" size="lg">Costos prestacionales</b-button>
          </div>
          <div class="col-3" v-if="this.$store.state.storeCost.show_btn_add">
            <b-button class="button-view-per-day py-2" @click="$bvModal.show('add-another-collaborator'); personalCostAddCollabFunction()" size="lg">Añadir colaborador</b-button>
          </div>
          <div class="table-responsive mt-4" style="text-align: center">
            <table v-if="loadTable" class="table table-list list-track">
              <thead>
                <tr>
                  <th style="height: 3rem;"></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="subhead-titles">
                  <td>ID Colaborador</td>
                  <td>Colaborador</td>
                  <td>Cargo</td>
                  <td>Horas Laboradas</td>
                  <td>Valor Hora</td>
                  <td>Valor Total</td>
                </tr>
                <tr v-for="(k,i) in personalCost" :key="i" :class="{'total-tr': k.type_row === 'total'}">
                  <td v-if="k.type_row === 'normal'">
                    <span class="d-block">{{ k.id }}</span>
                  </td>
                  <td v-else-if="k.type_row === 'total'" class="total-transparent">
                    <span class="d-block">{{ k.id }}</span>
                  </td>
                  <td v-if="k.type_row === 'normal'">
                    <span class="d-block">{{ k.name }}</span>
                  </td>
                  <td v-else-if="k.type_row === 'total'" class="total-transparent">
                    <span class="d-block">{{ k.name }}</span>
                  </td>
                  <td v-if="k.type_row === 'normal'">
                    <span class="d-block">{{ k.job_title }}</span>
                  </td>
                  <td v-else-if="k.type_row === 'total'" class="total-transparent">
                    <span class="d-block">{{ k.job_title }}</span>
                  </td>
                  <td v-if="k.type_row === 'normal'">
                    <span class="d-block">{{ k.laboral_hours }}</span>
                  </td>
                  <td v-else-if="k.type_row === 'total'" class="total">
                    <span class="d-block">{{ k.laboral_hours }}</span>
                  </td>
                  <td v-if="k.type_row === 'normal'">
                    <span class="d-block">{{ k.hour_value }}</span>
                  </td>
                  <td v-else-if="k.type_row === 'total'">
                    <span class="d-block">{{ k.hour_value }}</span>
                  </td>
                  <td v-if="k.type_row === 'normal'">
                    <span class="d-block">{{ k.total_value }}</span>
                  </td>
                  <td v-else-if="k.type_row === 'total'" class="total">
                    <span class="d-block">{{ k.total_value }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="d-grid gap-3 d-md-flex justify-content-md-end row justify-content-start">
          <ul class="d-block">
            <li class="py-2 px-3 width-li text-center">Valor hora general: {{ this.$store.state.storeCost.general_hour_value }}</li>
          </ul>
        </div>
      </div>
    </b-modal>
    <!-- Reference cost -->
    <b-modal ref="reference-cost" id="reference-cost" centered size="xl" :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="close_sub_modal()">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div class="px-3 mb-3">
        <h4 class="color-bluedark">Insumos para referencias</h4>
        <hr>
        <div class="row justify-content-end mt-4">
          <div class="col-3">
            <p>Filtrar por ID o Nombre completo:</p>
            <div>
              <b-form-input v-model="idSuppliesReference" class="mb-3 b-form-input"></b-form-input>
            </div>
          </div>
          <div class="col-2 allData">
            <b-button id="idSuppliesReference" class="btn-action-back py-2" size="lg" @click="personalCostReferenceSuppliesFunction(idSuppliesReference)">Buscar</b-button>
          </div>
          <div class="table-responsive mt-4" style="text-align: center">
            <table v-if="loadTable" class="table table-list list-track">
              <thead>
                <tr>
                  <th style="height: 3rem;"></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="subhead-titles">
                  <td>ID Insumo</td>
                  <td>Insumo</td>
                  <td>Precio de venta</td>
                  <td>Precio de costo</td>
                </tr>
                <tr v-for="(k,i) in personalCostReferenceSupplies" :key="i">
                  <td><span class="d-block">{{ k.id }}</span></td>
                  <td><span class="d-block">{{ k.name }}</span></td>
                  <td><span class="d-block">{{ k.sale_price }}</span></td>
                  <td><span class="d-block">{{ k.cost_price }}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Historical by collaborator -->
    <b-modal ref="historical-by-collaborator" id="historical-by-collaborator" centered size="xl" :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="close_sub_modal()">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div class="px-3 mb-3">
        <h4 class="color-bluedark">Histórico por colaborador</h4>
        <hr>
        <div>
          <div class="row mt-5">
            <div class="col-3">
              <p>Fecha inicial:</p>
              <input class="mb-3 b-form-input form-control" @change="personalCostHistoricReset()" v-model="initDateCollaborator" type="date">
            </div>
            <div class="col-3">
              <p>Fecha final:</p>
              <input class="mb-3 b-form-input form-control" @change="personalCostHistoricReset()" v-model="endDateCollaborator" type="date">
            </div>
            <div class="col-3" v-if="showSelectCollaborator">
              <p>Colaborador</p>
              <b-form-select v-model="selectCollaborator" :options="$store.state.storeCost.listCollaboratorHistory">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ defaultValueSelect }}</b-form-select-option>
              </template>
            </b-form-select>
            </div>
            <div class="col-3" style="margin-top: 2.5rem!important;">
              <b-button class="btn-action-cost py-2" size="lg" @click="personalCostHistoricFunction()">{{ btnTextHistory }}</b-button>
            </div>
          </div>
          <!-- <div class="p-4 mt-5">
            <PersonalCostHistoric :chartdata="historyCollaboratorGraph"></PersonalCostHistoric>
          </div> -->
          <div class="p-4 mt-5">
            <canvas id="PersonalCostHistoric"></canvas>
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <div class="col-2">
            <b-button class="btn-action-back py-2" size="lg" @click="personalCostFunction();$bvModal.show('staff-costs');$bvModal.hide('historical-by-collaborator')">Volver</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Add another collaborator -->
    <b-modal ref="add-another-collaborator" id="add-another-collaborator" centered size="xl" :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="close_sub_modal()">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div class="px-3 mb-3">
        <h4>Añadir otro colaborador</h4>
        <hr>
        <div>
          <div class="row mt-5">
            <div class="col-3">
              <p>Filtrar por CC o Nombre completo:</p>
              <div>
                <b-form-input v-model="identificationCollaborator" class="mb-3 b-form-input"></b-form-input>
              </div>
            </div>
            <div class="col-2" style="margin-top: 2.5rem!important;">
              <b-button id="identificationCollaborator" class="btn-action-cost py-2" size="lg" @click="personalCostAddCollabFunction(identificationCollaborator)">Filtrar</b-button>
            </div>
            <div class="table-res mt-4" style="text-align: center;">
              <table v-if="loadTable" class="table table-list list-track">
                <thead>
                  <tr>
                    <th style="height: 3rem;"></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="subhead-titles">
                    <td>CC Colaborador</td>
                    <td>Colaborador</td>
                    <td>Cargo</td>
                    <td>Hora Laboradas</td>
                    <td>Valor Hora</td>
                    <td>Valor Total</td>
                    <td>Seleccionar</td>
                  </tr>
                  <tr v-for="(k, i) in personalCostAddCollab" :key="i">
                    <td><span class="d-block">{{ k.id }}</span></td>
                    <td><span class="d-block">{{ k.name }}</span></td>
                    <td><span class="d-block">{{ k.job_title }}</span></td>
                    <td><span class="d-block">{{ k.laboral_hours }}</span></td>
                    <td><span class="d-block">{{ k.hour_value }}</span></td>
                    <td><span class="d-block">{{ k.total_value }}</span></td>
                    <td><label class="form-checkbox"><input type="checkbox" @click="changeValueSelectAddCollaborator(k.selected)" v-model="k.selected"><i class="form-icon"></i></label></td>
                  </tr>
                </tbody>
              </table>
              <div class="row justify-content-center mt-4">
                <div class="col-2">
                  <b-button class="btn-action-back py-2" size="lg" @click="personalCostFunction();$bvModal.show('staff-costs');$bvModal.hide('add-another-collaborator')">Volver</b-button>
                </div>
                <div class="col-4" v-if="this.collaboratorSelect">
                  <b-button class="btn-action-cost py-2" size="lg" @click="personalSaveAddCollabFunction()">Añadir colaboradores</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Price construction -->
    <b-modal ref="price-construction" id="price-construction" centered size="xl" :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="close_sub_modal()">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div class="px-3 mb-3">
        <h4>Construcción de precios</h4>
        <hr>
        <div class="row justify-content-start mt-5">
          <div class="col-3">
            <p>Valor/Hora Centro</p>
            <div>
              <b-form-input class="mb-3 b-form-input" v-model="this.$store.state.storeCost.sale_value_hour" disabled></b-form-input>
            </div>
          </div>
          <div class="col-3">
            <p>Valor/Hora PPK</p>
            <div>
              <b-form-input class="mb-3 b-form-input" v-model="this.$store.state.storeCost.cost_value_hour" disabled></b-form-input>
            </div>
          </div>
        </div>
        <div class="table-responsive mt-4" style="text-align: center;">
          <div class="d-flex" id="typeFilter">
            <div class="text-center col-md-6" :class="{ 'row-cost' : this.$store.state.storeCost.type_price === 'cost' }"><b-button @click="btnCostPrice()" variant="links">Construcción de precios de costo</b-button></div>
            <div class="text-center col-md-6" :class="{ 'row-cost' : this.$store.state.storeCost.type_price === 'sale' }"><b-button @click="btnSalePrice()" variant="links">Construcción de precios de venta</b-button></div>
          </div>
          <table v-if="loadTable" class="table table-list list-track">
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr class="subhead-titles">
                <td>COD</td>
                <td>Descripción</td>
                <td>Und X Caja</td>
                <td>Cajas</td>
                <td>Precio Neto X Caja</td>
                <td>Precio Neto Total Cajas</td>
                <td></td>
              </tr>
              <tr v-for="(k,i) in priceConstructionMainTable" :key="i" :class="{'total-tr': k.type_row === 'total'}">
                <td v-if="k.type_row === 'normal'">
                  <span class="d-block">{{ k.id }}</span>
                </td>
                <td v-else-if="k.type_row === 'total'" class="total-transparent">
                  <span class="d-block">{{ k.id }}</span>
                </td>
                <td v-if="k.type_row === 'normal'">
                  <span class="d-block">{{ k.name }}</span>
                </td>
                <td v-else-if="k.type_row === 'total'" class="total-transparent">
                  <span class="d-block">{{ k.name }}</span>
                </td>
                <td v-if="k.type_row === 'normal'">
                  <span class="d-block">{{ k.box_unds }}</span>
                </td>
                <td v-else-if="k.type_row === 'total'" class="total-transparent">
                  <span class="d-block">{{ k.box_unds }}</span>
                </td>
                <td v-if="k.type_row === 'normal'">
                  <span class="d-block">{{ k.quantity_unds }}</span>
                </td>
                <td v-else-if="k.type_row === 'total'" class="total">
                  <span class="d-block">{{ k.quantity_unds }}</span>
                </td>
                <td v-if="k.type_row === 'normal'">
                  <span class="d-block">{{ k.box_value }}</span>
                </td>
                <td v-else-if="k.type_row === 'total'" class="total">
                  <span class="d-block">{{ k.box_value }}</span>
                </td>
                <td v-if="k.type_row === 'normal'">
                  <span class="d-block">{{ k.total_box_value }}</span>
                </td>
                <td v-else-if="k.type_row === 'total'" class="total">
                  <span class="d-block">{{ k.total_box_value }}</span>
                </td>
                <td v-if="k.type_row === 'normal'">
                  <button class="btn py-0 px-2" @click="$bvModal.show('detail-price-construction');priceConstructionDetail(k.id)"><img src="~@/assets/icons/ojo.png" alt=""></button>
                </td>
                <td v-else-if="k.type_row === 'total'" class="total-transparent">
                  <button class="btn py-0 px-2"></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <!-- Detail price construction -->
    <b-modal ref="detail-price-construction" id="detail-price-construction" centered size="xl" :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="close_sub_modal()">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div class="px-3 mb-3">
        <h4 class="color-bluedark">Construcción de precios</h4>
        <hr>
        <h5>{{ this.$store.state.storeCost.record.title }}</h5>
        <div class="mt-4">
          <div class="row">
            <div class="col">
              <p>Referencia:</p>
              <div>
                <input v-model="this.$store.state.storeCost.record.id" class="form-control mb-2" type="text" disabled>
              </div>
              <p>Descripción:</p>
              <div>
                <input v-model="this.$store.state.storeCost.record.name" class="form-control mb-2" type="text" disabled>
              </div>
              <p>Unidades en cada caja:</p>
              <div>
                <input v-model="this.$store.state.storeCost.record.box_unds" class="form-control mb-2" type="text" disabled>
              </div>
            </div>
            <div class="col">
              <p>Cantidad total:</p>
              <div>
                <input v-model="this.$store.state.storeCost.record.total_standard" class="form-control mb-2" type="text" disabled>
              </div>
              <p>Cantidad unitaria:</p>
              <div>
                <input v-model="this.$store.state.storeCost.record.unit_standard" class="form-control mb-2" type="text" disabled>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive mt-5" style="text-align: center;">
          <table v-if="loadTable" class="table table-list list-track">
            <thead>
              <tr>
                <th style="height: 3rem;"></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="subhead-titles">
                <td>ID Insumo</td>
                <td>Insumo</td>
                <td>Cantidad</td>
                <td>Precio Unitario</td>
                <td>Precio Total</td>
              </tr>
              <tr v-for="(k, i) in supplies_record" :key="i" :class="{'total-tr': k.type_row === 'total'}">
                <td v-if="k.type_row === 'normal'">
                  <span class="d-block">{{ k.id }}</span>
                </td>
                <td v-else-if="k.type_row === 'total'" class="total-transparent">
                  <span class="d-block">{{ k.id }}</span>
                </td>
                <td v-if="k.type_row === 'normal'">
                  <span class="d-block">{{ k.name }}</span>
                </td>
                <td v-else-if="k.type_row === 'total'" class="total-transparent">
                  <span class="d-block">{{ k.name }}</span>
                </td>
                <td v-if="k.type_row === 'normal'">
                  <span class="d-block">{{ k.quantity }}</span>
                </td>
                <td v-else-if="k.type_row === 'total'" class="total">
                  <span class="d-block">{{ k.quantity }}</span>
                </td>
                <td v-if="k.type_row === 'normal'">
                  <span class="d-block">{{ k.unit_price }}</span>
                </td>
                <td v-else-if="k.type_row === 'total'" class="total">
                  <span class="d-block">{{ k.unit_price }}</span>
                </td>
                <td v-if="k.type_row === 'normal'">
                  <span class="d-block">{{ k.total_price }}</span>
                </td>
                <td v-else-if="k.type_row === 'total'" class="total">
                  <span class="d-block">{{ k.total_price }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <!-- Benefits costs -->
    <b-modal ref="benefits-costs" id="benefits-costs" centered size="xl" :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="close_sub_modal()">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div class="px-3 mb-3">
        <h4 class="color-bluedark">Costos del personal / Costos prestacionales</h4>
        <hr>
        <div class="row justify-content-start mt-5">
          <div class="table-res mt-4" style="text-align: center;">
            <table v-if="loadTable" class="table table-list list-track">
              <thead>
                <tr>
                  <th style="height: 3rem;"></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="subhead-titles">
                  <td>Costo prestacional</td>
                  <td>%</td>
                  <td>Valor</td>
                </tr>
                <tr v-for="(k,i) in personalCostServiceCost" :key="i" :class="{'total-tr': k.type_row === 'total' || k.type_row === 'day' || k.type_row === 'hour'}">
                  <td v-if="k.type_row === 'normal'">
                    <span class="d-block">{{ k.name }}</span>
                  </td>
                  <td v-else-if="k.type_row === 'total'" colspan="3" class="total">
                    <span class="d-block">Total: <span class="total-cost ">{{ k.value }}</span></span>
                  </td>
                  <td v-if="k.type_row === 'normal'">
                    <span class="d-block">{{ k.percentage }}</span>
                  </td>
                  <td v-else-if="k.type_row === 'day'" colspan="3" class="total">
                    <span class="d-block">Costo por día: <span class="totalCost">{{ k.value }}</span></span>
                  </td>
                  <td v-if="k.type_row === 'normal'">
                    <span class="d-block">{{ k.value }}</span>
                  </td>
                  <td v-else-if="k.type_row === 'hour'" colspan="3" class="total">
                    <span class="d-block">Costo por hora: <span class="totalCost">{{ k.value }}</span></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row justify-content-center mt-4">
              <div class="col-2">
                <b-button class="btn-action-back py-2" size="lg" @click="personalCostFunction();$bvModal.show('staff-costs');$bvModal.hide('benefits-costs')">Volver</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Charges Modal -->
    <b-modal ref="charges-modal" id="charges-modal" centered size="xl" :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="close_sub_modal()">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div class="px-3 mb-3">
        <h4>Costos del personal / Cargos</h4>
        <hr>
        <div class="row justify-content-start mt-5">
          <div class="table-res mt-4" style="text-align: center;">
            <table v-if="loadTable" class="table table-list list-track">
              <thead>
                <tr>
                  <th style="height: 3rem;"></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="subhead-titles">
                  <td>ID Cargo</td>
                  <td>Cargo</td>
                  <td>Categoría</td>
                  <td>Valor Hora</td>
                  <td>Horas Laboradas</td>
                  <td>Valor Total Día</td>
                </tr>
                <tr v-for="(k,i) in personalCostJobTitle" :key="i">
                  <td><span class="d-block">{{ k.id }}</span></td>
                  <td><span class="d-block">{{ k.name }}</span></td>
                  <td><span class="d-block">{{ k.category }}</span></td>
                  <td><span class="d-block">{{ k.value_hours }}</span></td>
                  <td><span class="d-block">{{ k.work_hours }}</span></td>
                  <td><span class="d-block">{{ k.value_per_hours }}</span></td>
                </tr>
              </tbody>
            </table>
            <div class="row justify-content-center mt-4">
              <div class="col-2">
                <b-button class="btn-action-back py-2" size="lg" @click="personalCostFunction();$bvModal.show('staff-costs');$bvModal.hide('charges-modal')">Volver</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    </div>
</template>

<script>
import Chart from 'chart.js'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  components: {},
  data: () => ({
    collaboratorSelect: false,
    btnTextHistory: '',
    selectedAddCollaborator: false,
    initDateCollaborator: '0000-00-00',
    endDateCollaborator: '0000-00-00',
    defaultValueSelect: '',
    showSelectCollaborator: false,
    validateShowSelectCollaborator: true,
    initDate: null,
    endDate: null,
    pruebita: null,
    identificationCollaborator: '',
    idSuppliesReference: '',
    noExistRecordsMain: false,
    selectCollaborator: null,
    referencia: '',
    fechaInicio: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
    fechaFin: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
    selectedLine: '',
    listTrack: true,
    loaded: false,
    loadTable: true,
    selected_line: -1,
    line_list: [],
    auxDataCumplimiento: {},
    loadCumplimiento: false,
    graphicsCost: null,
    graphicsSecond: null,
    graphicsModal: null,
    costPersonal: null,
    lenRowsCostModule: 0,
    dataGraphics: {
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Ventas',
            data: [],
            backgroundColor: ['#00B285', '#00B285', '#00B285', '#00B285', '#00B285', '#00B285', '#00B285', '#00B285'],
            borderWidth: 1
          },
          {
            label: 'Costos',
            data: [],
            backgroundColor: ['#03185A', '#03185A', '#03185A', '#03185A', '#03185A', '#03185A', '#03185A', '#03185A'],
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true
      }
    },
    dataUtility: {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: 'Utilidad',
          data: [],
          backgroundColor: ['#00B285', '#00B285', '#00B285', '#00B285', '#00B285', '#00B285', '#00B285', '#00B285'],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true
      }
    },
    CostPriceGraphicModal: {
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Ventas',
            data: [],
            backgroundColor: ['#00B285', '#00B285', '#00B285', '#00B285', '#00B285', '#00B285', '#00B285', '#00B285'],
            borderWidth: 1
          },
          {
            label: 'Costos',
            data: [],
            backgroundColor: ['#03185A', '#03185A', '#03185A', '#03185A', '#03185A', '#03185A', '#03185A', '#03185A'],
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true
      }
    },
    PersonalCostHistoric: {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: 'Porcentaje',
          data: [],
          backgroundColor: ['#00B285', '#00B285', '#00B285', '#00B285', '#00B285', '#00B285', '#00B285', '#00B285'],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true
      }
    },
    viewPerDay: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ['#363D8A', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800', '#363D8A', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800', '#363D8A', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800']
        }
      ]
    },
    historyCollaboratorGraph: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ['#363D8A', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800', '#363D8A', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800', '#363D8A', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800']
        }
      ]
    }
  }),
  computed: {
    dateInfo () {
      return this.$store.state.storeCost.dateInfo
    },
    lineList () {
      return this.$store.state.storeLine.lineList
    },
    dataReferencias () {
      return this.$store.state.dataReferencias
    },
    costModule: {
      get () {
        return this.$store.state.storeCost.costModule
      },
      set (value) {
        this.$store.state.storeCost.costModule = value
      }
    },
    ViewPerDay: {
      get () {
        return this.$store.state.storeCost.ViewPerDay
      },
      set (value) {
        this.$store.state.storeCost.ViewPerDay = value
      }
    },
    priceConstructionMainTable: {
      get () {
        return this.$store.state.storeCost.priceConstructionMainTable
      },
      set (value) {
        this.$store.state.storeCost.priceConstructionMainTable = value
      }
    },
    supplies_record: {
      get () {
        return this.$store.state.storeCost.supplies_record
      },
      set (value) {
        this.$store.state.storeCost.supplies_record = value
      }
    },
    personalCost: {
      get () {
        return this.$store.state.storeCost.personalCost
      },
      set (value) {
        this.$store.state.storeCost.personalCost = value
      }
    },
    personalCostAddCollab: {
      get () {
        return this.$store.state.storeCost.personalCostAddCollab
      },
      set (value) {
        this.$store.state.storeCost.personalCostAddCollab = value
      }
    },
    personalCostJobTitle: {
      get () {
        return this.$store.state.storeCost.personalCostJobTitle
      },
      set (value) {
        this.$store.state.storeCost.personalCostJobTitle = value
      }
    },
    personalCostServiceCost: {
      get () {
        return this.$store.state.storeCost.personalCostServiceCost
      },
      set (value) {
        this.$store.state.storeCost.personalCostServiceCost = value
      }
    },
    personalCostReferenceSupplies: {
      get () {
        return this.$store.state.storeCost.personalCostReferenceSupplies
      },
      set (value) {
        this.$store.state.storeCost.personalCostReferenceSupplies = value
      }
    }
  },
  async mounted () {
    const self = this
    self.costModuleFunction()
    self.viewPerDayFunction()
    const utility = document.getElementById('UtilityGraphic')
    self.graphicsCost = new Chart(utility, self.dataUtility)
    const cost = document.getElementById('CostPriceGraphic')
    self.graphicsSecond = new Chart(cost, self.dataGraphics)
    if (sessionStorage.token && sessionStorage.rol === '1') {
      await this.$store.dispatch('getInfoProduction')
    } else if (sessionStorage.token && sessionStorage.rol === '2') {
      this.$router.push({ name: 'AperturaLinea' })
    } else {
      this.$router.push({ name: 'Inicio' })
    }
    if (sessionStorage.token) {
      this.$store.dispatch('atLeastQualityRecordFn')
      this.validateStatusCostModule()
    }
  },
  methods: {
    validateStatusCostModule () {
      const self = this
      self.$store.dispatch('statusModulesEnabled').then(function () {
        if (self.$store.state.storeCost.statusCostModule !== true) {
          self.$router.push({ name: 'Inicio' })
        }
      })
    },
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    close_sub_modal () {
      this.$refs['view-per-day'].hide()
      this.$refs['staff-costs'].hide()
      this.$refs['reference-cost'].hide()
      this.$refs['historical-by-collaborator'].hide()
      this.$refs['add-another-collaborator'].hide()
      this.$refs['price-construction'].hide()
      this.$refs['detail-price-construction'].hide()
      this.$refs['benefits-costs'].hide()
      this.$refs['charges-modal'].hide()
    },
    async costModuleFunction (date = false) {
      const self = this
      self.costModule = []
      const dataDateInfo = {}
      if (date) {
        self.noExistRecordsMain = true
        dataDateInfo.dateInfo = date
        self.$store.state.storeCost.dateInfo = date
      } else {
        self.noExistRecordsMain = false
      }
      await axios.post('/api_cost/costModule/', dataDateInfo, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.initDateCollaborator = response.data.date_info
          self.endDateCollaborator = response.data.date_info
          self.$store.state.storeCost.dateInfo = response.data.date_info
          self.$store.state.storeCost.show_btn_return = response.data.show_btn_return
          self.dataGraphics.data.datasets[0].data = response.data.graphics.cost_price_graphic.datasets[0].data
          self.dataGraphics.data.datasets[1].data = response.data.graphics.cost_price_graphic.datasets[1].data
          self.dataGraphics.data.labels = response.data.graphics.cost_price_graphic.labels
          self.dataUtility.data.datasets[0].data = response.data.graphics.utility_graphic.datasets[0].data
          self.dataUtility.data.labels = response.data.graphics.utility_graphic.labels
          self.CostPriceGraphicModal.data.datasets[0].data = response.data.graphics.cost_price_graphic.datasets[0].data
          self.CostPriceGraphicModal.data.datasets[0].backgroundColor = response.data.graphics.cost_price_graphic.datasets[0].backgroundColor
          self.CostPriceGraphicModal.data.datasets[1].data = response.data.graphics.cost_price_graphic.datasets[1].data
          self.CostPriceGraphicModal.data.datasets[1].backgroundColor = response.data.graphics.cost_price_graphic.datasets[1].backgroundColor
          self.CostPriceGraphicModal.data.labels = response.data.graphics.cost_price_graphic.labels
          self.costModule = response.data.records
          self.lenRowsCostModule = response.data.len_rows
          self.graphicsCost.update()
          self.graphicsSecond.update()
        }
      })
      if (date) {
        self.$bvModal.hide('view-per-day')
      }
    },
    assignDataToHistoricGraphicModal (content) {
      const self = this
      // const objectGraphic = self.CostPriceGraphicModal.data
      if (content !== false) {
        self.CostPriceGraphicModal.data.labels = content.labels
        self.CostPriceGraphicModal.data.datasets[0].data = content.datasets[0].data
        self.CostPriceGraphicModal.data.datasets[0].backgroundColor = content.datasets[0].backgroundColor
        self.CostPriceGraphicModal.data.datasets[1].data = content.datasets[1].data
        self.CostPriceGraphicModal.data.datasets[1].backgroundColor = content.datasets[1].backgroundColor
      } else {
        self.CostPriceGraphicModal.data.labels = []
        self.CostPriceGraphicModal.data.datasets[0].data = []
        self.CostPriceGraphicModal.data.datasets[1].data = []
      }
      self.loadCumplimiento = true
      return Promise.resolve('Success')
    },
    async graphicModal (content = false) {
      const self = this
      await self.assignDataToHistoricGraphicModal(content).then(function (response) {
        const modal = document.getElementById('CostPriceGraphicModal')
        if (modal) {
          self.graphicsModal = new Chart(modal, self.CostPriceGraphicModal)
          self.graphicsModal.update()
        }
      })
    },
    async viewPerDayFunction () {
      const self = this
      self.ViewPerDay = []
      await axios.post('/api_cost/dateHistory/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.initDate = response.data.init_date
          self.endDate = response.data.end_date
          response.data.records.forEach(function (item, index) {
            self.ViewPerDay = response.data.records
          })
          self.graphicModal(response.data.graphics)
        }
      })
    },
    async filterViewPerDay (initDate, endDate) {
      const self = this
      self.ViewPerDay = []
      await axios.post('/api_cost/consultRangeHistory/', { initDate: initDate, endDate: endDate }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.ViewPerDay = response.data.records
          self.graphicModal(response.data.graphics)
        }
      })
    },
    async priceConstructionFunction (type = false) {
      const self = this
      self.priceConstructionMainTable = []
      if (type) {
        await axios.post('/api_cost/priceConstruction/', { dateInfo: self.$store.state.storeCost.dateInfo, type: type }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            response.data.records.forEach(function (item, index) {
              self.priceConstructionMainTable = response.data.records
              self.$store.state.storeCost.sale_value_hour = response.data.sale_value_hour
              self.$store.state.storeCost.cost_value_hour = response.data.cost_value_hour
              self.$store.state.storeCost.type_price = response.data.type_active
            })
          }
        })
      } else {
        await axios.post('/api_cost/priceConstruction/', { dateInfo: self.$store.state.storeCost.dateInfo }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            response.data.records.forEach(function (item, index) {
              self.priceConstructionMainTable = response.data.records
              self.$store.state.storeCost.sale_value_hour = response.data.sale_value_hour
              self.$store.state.storeCost.cost_value_hour = response.data.cost_value_hour
              self.$store.state.storeCost.type_price = response.data.type_active
            })
          }
        })
      }
    },
    btnCostPrice () {
      const self = this
      self.$store.state.storeCost.type_price = 'cost'
      self.priceConstructionFunction(self.$store.state.storeCost.type_price)
    },
    btnSalePrice () {
      const self = this
      self.$store.state.storeCost.type_price = 'sale'
      self.priceConstructionFunction(self.$store.state.storeCost.type_price)
    },
    async priceConstructionDetail (reference) {
      const self = this
      self.supplies_record = []
      await axios.post('/api_cost/priceConstructionDetail/', { dateInfo: self.$store.state.storeCost.dateInfo, type: self.$store.state.storeCost.type_price, reference: reference }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.supplies_record = response.data.supplies_record
          self.$store.state.storeCost.record.id = response.data.record.id
          self.$store.state.storeCost.record.name = response.data.record.name
          self.$store.state.storeCost.record.box_unds = response.data.record.box_unds
          self.$store.state.storeCost.record.unit_standard = response.data.record.unit_standard
          self.$store.state.storeCost.record.total_standard = response.data.record.total_standard
          self.$store.state.storeCost.record.title = response.data.record.text_type
        }
      })
    },
    async personalCostFunction () {
      const self = this
      self.personalCost = []
      await axios.post('/api_cost/personalCost/', { dateInfo: self.$store.state.storeCost.dateInfo }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.personalCost = response.data.record
          self.$store.state.storeCost.show_btn_add = response.data.show_btn_add
          self.$store.state.storeCost.general_hour_value = response.data.general_hour_value
        }
      })
    },
    async personalCostHistoricFunction () {
      setTimeout(() => {
        const self = this
        const personalCost = document.getElementById('PersonalCostHistoric')
        self.costPersonal = new Chart(personalCost, self.PersonalCostHistoric)
        self.personalCostHistoric = {}
        const infoSend = { dateInfo: self.$store.state.storeCost.dateInfo, initDate: self.initDateCollaborator, endDate: self.endDateCollaborator, user: self.selectCollaborator }
        if (self.selectCollaborator !== null && self.selectCollaborator !== '') {
          infoSend.user = self.selectCollaborator
        }
        axios.post('/api_cost/personalCostHistoric/', infoSend, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            // self.historyCollaboratorGraph = response.data.graphic
            self.PersonalCostHistoric.data.labels = response.data.graphic.labels
            self.PersonalCostHistoric.data.datasets[0].data = response.data.graphic.datasets[0].data
            self.$store.state.storeCost.listCollaboratorHistory = response.data.record
            self.defaultValueSelect = response.data.value_default
            self.showSelectCollaborator = response.data.show_select_collab
            if (self.validateShowSelectCollaborator === false) {
              self.validateShowSelectCollaborator = true
            } else {
              self.validateShowSelectCollaborator = false
              self.btnTextHistory = 'Mostrar colaboradores'
            }
          }
        })
      }, 1000)
    },
    personalCostHistoricReset () {
      const self = this
      self.showSelectCollaborator = true
      self.validateShowSelectCollaborator = true
      self.selectCollaborator = null
      self.btnTextHistory = 'Mostrar colaboradores'
    },
    async personalCostAddCollabFunction (id = false) {
      const self = this
      self.personalCostAddCollab = []
      const dataToSend = { infoSearch: id, dateInfo: self.$store.state.storeCost.dateInfo }
      await axios.post('/api_cost/personalCostAddCollab/', dataToSend, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.personalCostAddCollab = response.data.record
        }
      })
      document.getElementById('identificationCollaborator').value = ''
    },
    changeValueSelectAddCollaborator (selected) {
      const self = this
      if (selected) {
        self.collaboratorSelect = false
      } else {
        self.collaboratorSelect = true
      }
    },
    async personalSaveAddCollabFunction () {
      const self = this
      const infoSend = { dateInfo: self.$store.state.storeCost.dateInfo, record: self.personalCostAddCollab }
      if (self.collaboratorSelect) {
        await axios.post('/api_cost/personalCostSaveCollab/', infoSend, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.error !== undefined && response.data.error.length > 0) {
            response.data.error.forEach(function (error) {
              self.announceToast(error.title, error.content, error.time, 'danger')
            })
          } else {
            self.announceToast('Registro exitoso', 'Se han añadido los colaboradores.', 5000, 'success')
          }
        })
      }
    },
    async personalCostJobTitleFunction () {
      const self = this
      const infoSend = { dateInfo: self.$store.state.storeCost.dateInfo }
      await axios.post('/api_cost/personalCostJobTitle/', infoSend, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.personalCostJobTitle = response.data.record
        }
      })
    },
    async personalCostServiceCostFunction () {
      const self = this
      const infoSend = { dateInfo: self.$store.state.storeCost.dateInfo }
      await axios.post('/api_cost/personalCostServiceCost/', infoSend, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.personalCostServiceCost = response.data.record
        }
      })
      // const modal = document.getElementById('CostPriceGraphicModal')
      // self.graphicsModal = new Chart(modal, self.CostPriceGraphicModal)
      // self.graphicsModal.update()
    },
    async personalCostReferenceSuppliesFunction (id = false) {
      const self = this
      const infoSend = { dateInfo: self.$store.state.storeCost.dateInfo, infoSearch: id }
      await axios.post('/api_cost/personalCostReferenceSupplies/', infoSend, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.error !== undefined && response.data.error.length > 0) {
          response.data.error.forEach(function (error) {
            self.announceToast(error.title, error.content, error.time, 'danger')
          })
        } else {
          self.personalCostReferenceSupplies = response.data.record
        }
      })
      document.getElementById('idSuppliesReference').value = ''
    }
  }
}
</script>

<style lang="scss">
  .custom-modal-class {
    width: 84vw;
  }
  #typeFilter {
    div.row-cost {
      background-color: #363D8A;
      flex-direction: column;
      border: 1px solid #363D8A;
      button {
        color: #FFF;
      }
    }
    div:not(.row-cost) {
      background-color: #FFF;
      flex-direction: column;
      border: 1px solid #363D8A;
      button {
        color: #363D8A;
      }
    }
  }
  .title-indicator {
    font-weight: bold;
  }
  .subtitle-indicator {
    font-size: 1rem;
    font-weight: 500;
  }
  .show-item-details {
    cursor: pointer;
  }
  #modal-details {
    margin-left: 40px;
  }
  .btn-action-back {
    background-color: #03185A;
    color: #FFF;
    justify-content: left;
    align-items: left;
    box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
    font-weight: 600;
    border-radius: 10px;
    border: 0;
    padding: 15px 30px;
    font-size: 0.9vw;
    &:hover {
      background-color: #1d2c5f;
    }
  }
  #view-per-day .modal-dialog {
    width: 94%
  }
  .button-view-per-day {
    background-color: #03185A;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
    font-weight: 600;
    border-radius: 10px;
    border: 0;
    padding: 15px 30px;
    font-size: 0.8vw;
  }
  .custom-input {
    border: 1px solid #363D8A;
    border-radius: 8px;
    height: 45px;
    width: 13vw;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
  }
  .total {
    background-color: #03185A;
    color: white;
    font-weight: 600;
  }
  .total-tr, .total-transparent {
    background-color: transparent !important;
    border: none !important;
  }
  .separator {
    text-align: left;
    border-bottom: 2px solid #CACAD9;
    font-weight: 600;
    margin-top: 10vh;
  }
  .btn-action-cost {
    background-color: #39C2A1;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
    font-weight: 600;
    border-radius: 10px;
    border: 0;
    padding: 15px 30px;
    font-size: 0.9vw;
  }
  .width-li {
    width: 18vw;
    background-color: #03185A;
    color: #FFF;
    font-weight: 600;
    font-size: 1rem;
  }
  .table-res {
    display: block;
    width: 100%;
  }
  .totalCost {
    margin-left: 23vw;
  }
  .total-cost {
    margin-left: 26.5vw;
  }
  /*----------------------------------------------------------- */
  @media (max-width: 1199px) {
    .btn-action-cost {
      background-color: #39C2A1;
      display: flex;
      margin: auto;
      justify-content: center;
      align-items: center;
      box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25) inset;
      font-weight: 600;
      border-radius: 10px;
      border: 0;
      padding: 28px 30px;
      font-size: 0.9vw;
      width: 262px;
      height: 51px;
      font-size: 18px;
      margin-bottom: 1rem;
    }
  }
</style>
