<template>
  <div class="form-trazabilidad">
    <div>
      <div class="row box-option mx-2">
        <div class="col-md-12 py-4 pr-4">
          <!-- Production Order -->
          <div class="text-left">
            <p class="font-weight-bold">Orden de Producción</p>
            <div>
              <b-input class="custom-select" v-if="data.length" v-model="data[0].ref"></b-input>
              <multiselect class="mb-3" v-if="!data.length" v-model="selectedOP" :options="getProductionOrder()"
                @select="getProductOrderProduction(selectedOP)"
                placeholder="Seleccione un numero de op" :disabled="this.$store.state.storeTrack.updateTrack ? '': false">
              </multiselect>
            </div>
          </div>
          <!-- Referencia y Lote -->
          <div class="text-left" style="display: none;" :class="{ 'show_content': this.$store.state.storeTrack.updateTrack }" ref="selProductOp">
            <!-- Referencias por OP -->
            <p class="font-weight-bold">Referencias - lote</p>
            <multiselect v-if="this.$store.state.storeTrack.updateTrack === false" class="mb-3" v-model="selectedRef" :options="refReferencesList"
              @select="getActivatedBases(selectedRef),getBatchOrderProduction(selectedRef)" label="text"
              placeholder="Seleccione una referencia - lote" :disabled="this.$store.state.storeTrack.updateTrack ? '': false">
            </multiselect>
            <input v-else class="form-control mb-3" type="text" v-model="selectedRef" disabled>
          </div>
          <!-- Lote -->
          <div class="text-left" style="display: none" :class="{ 'show_content': this.$store.state.storeTrack.updateTrack }" ref="selReferenceLote">
            <p class="font-weight-bold">Lote</p>
            <input class="form-control mb-3" type="text" v-model="selectedLote" disabled>
          </div>
          <!-- Bases -->
          <div class="text-left mt-4">
             <table class="w-100 table-bases">
                <thead>
                  <tr>
                    <th><p class="font-weight-bold m-0">Bases</p></th>
                    <th><span>Orden de PB</span></th>
                    <th><span>Lote logístico de PB</span></th>
                    <th><span>Lote de PB</span></th><!--Aquí estaba orden de producción-->
                    <th><span>Día Juliano</span></th>
                    <th><span>Foto</span></th>
                  </tr>
                </thead>
                <!-- NEW -->
                <tbody v-if="!data.length">
                  <tr v-for="(n, i) in bases.bases" :key="i">
                    <td><strong>{{ n.refBase }}</strong></td>
                    <td class="text-center">
                      <b-form-input @change="ordenProduction(i, $event)" :value="getOrdenProduccion(i)"></b-form-input>
                    </td>
                    <td class="text-center">
                      <b-form-input @change="loteLogistico(i, $event)" :value="getLoteLogistico(i)"></b-form-input>
                    </td>
                    <td class="text-center">
                      <b-form-input @change="lote(i, $event)" :value="getLote(i)"></b-form-input>
                    </td>
                    <td class="text-center">
                      <b-form-input @change="julianDayChange(i, $event)" :value="getJulianDay(i)"></b-form-input>
                    </td>
                    <td>
                      <div class="image-upload text-center">
                        <label :for="'file-input-' + i" :id="'file-label-' + i">
                          <div ref="icon" :data-key="i" v-if="getImagesPreview(i) == null">
                            <i class="fas fa-camera fa-2x color-grayLight"></i>
                          </div>
                          <img :src="getImagesPreview(i)" :id="'foto-' + i" v-if="getImagesPreview(i) != null" class="imagedisplay">
                        </label>
                        <input :id="'file-input-' + i" @change="previewFiles($event, i)" type="file" />
                        <!--The style tag in this line allows us to give the icon a hidden visibility.-->
                        <form :id="'formImage-' + i" enctype="multipart/form-data" action="" style="visibility: hidden" class="file">
                        </form>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  name: 'FormTrazabilidad',
  props: {
    icon: String,
    data: Array,
    extraData: Array,
    closeBox: Function
  },
  components: {
    Multiselect
  },
  data () {
    return {
      cantidad: 10,
      varRecognize: 'sssss',
      horas: 2,
      auxLineList: [],
      referencias: null,
      refReferencesList: [],
      dataTrack: [],
      input: {
        lote: [],
        loteLogistico: [],
        ordenProduction: [],
        julian_day: []
      },
      files: []
    }
  },
  computed: {
    julianDay: {
      set (value) {
        this.$store.state.storeTrack.julianDay = value
      },
      get () {
        return this.$store.state.storeTrack.julianDay
      }
    },
    selectedRef: {
      set (value) {
        this.$store.state.storeTrack.selectedRef = value
      },
      get () {
        return this.$store.state.storeTrack.selectedRef
      }
    },
    selectedOP: {
      set (value) {
        this.$store.state.storeTrack.selectedOP = value
      },
      get (value) {
        return this.$store.state.storeTrack.selectedOP
      }
    },
    selectedLote: {
      set (value) {
        this.$store.state.storeTrack.selectedLote = value
      },
      get (value) {
        return this.$store.state.storeTrack.selectedLote
      }
    },
    bases: {
      set (value) {
        this.$store.state.storeTrack.bases = value
      },
      get () {
        return this.$store.state.storeTrack.bases
      }
    },
    selectedLine: {
      set (value) {
        this.$store.state.storeTrack.selectedLine = value
      },
      get () {
        return this.$store.state.storeTrack.selectedLine
      }
    },
    getLineListWithTeam () {
      return this.$store.state.lineListWithTeam
    }
  },
  async mounted () {
    const self = this
    await this.$store.dispatch('getInfoProduction')
    // await this.$store.state.storeProduction.infoProduction.data.forEach(function (item, index) {
    //   var referenceAux = item.reference
    //   var basesAux = []
    //   console.log(item.bases)
    //   item.bases.forEach(function (item, index) {
    //     basesAux.push({ refBase: item.reference })
    //   })
    //   self.dataTrack.push({ ref: referenceAux, bases: basesAux })
    // })
    await axios.get('/api/getjulianday/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      self.julianDay = response.data.julian_day
    })
  },
  methods: {
    async getBatchOrderProduction (referenceValue) {
      this.$refs.selReferenceLote.style.display = ''
      this.selectedLote = null
      if (referenceValue.lote) {
        this.selectedLote = referenceValue.lote
      } else {
        this.selectedLote = null
      }
    },
    async getProductOrderProduction (value) {
      const self = this
      this.refReferencesList = []
      await axios.post('/api_orders/getReferencesByOp/', { OP: value }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        response.data.forEach((item, index) => {
          // this.refReferencesList.push({ ref: item.product_code__reference, lote: item.batch, text: item.product_code__reference + ' - ' + item.batch })
          this.refReferencesList.push({ ref: item.product_code__reference, lote: item.batch, text: item.product_code__reference + ' - ' + item.batch })
        })
        self.$refs.selProductOp.style.display = ''
      }).catch(function (error) {
        console.error(error)
      })
    },
    getJulianDay (i) {
      const self = this
      if (this.$store.state.storeTrack.input.julian_day[i] === undefined) {
        this.$store.state.storeTrack.input.julian_day[i] = self.julianDay
        return self.julianDay
      } else {
        return this.$store.state.storeTrack.input.julian_day[i]
      }
    },
    getImgUrl: function (pic) {
      return require('../assets/icons/' + pic + '.png')
    },
    getLote (index) {
      return this.$store.state.storeTrack.input.lote[index]
    },
    getLoteLogistico (index) {
      return this.$store.state.storeTrack.input.loteLogistico[index]
    },
    getOrdenProduccion (index) {
      return this.$store.state.storeTrack.input.ordenProduction[index]
    },
    lote: function (index, value) {
      this.$store.commit('actionSetInputLote', { index, value })
    },
    loteLogistico: function (index, value) {
      this.$store.commit('actionSetInputLoteLogistico', { index, value })
    },
    ordenProduction: function (index, value) {
      this.$store.commit('actionSetInputordenProduction', { index, value })
    },
    julianDayChange (index, value) {
      this.$store.commit('actionSetInputJulianDay', { index, value })
    },
    getProductionOrder () {
      try {
        const arrAux = []
        this.$store.state.storeProduction.infoProduction.data.forEach(function (item, index) {
          arrAux.push(item.OP)
        })
        return arrAux
      } catch (e) {
        return []
      }
    },
    getRefProduction () {
      try {
        const arrAux = [this.selectedRef]
        return arrAux
      } catch (e) {
        return []
      }
    },
    activeBases: function (contentOp) {
      const self = this
      this.dataTrack.forEach(function (item, index) {
        if (item.ref === contentOp.ref) {
          self.$set(self.bases, 'bases', item.bases)
        }
      })
    },
    async getActivatedBases (contentOp) {
      const self = this
      // this.dataTrack.forEach(function (item, index) {
      //   console.log(item)
      //   if (item.ref === contentOp.ref) {
      //     self.$set(self.bases, 'bases', item.bases)
      //   }
      // })
      await axios.post('/api/getBasesReference/', { reference: contentOp.ref }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        self.$set(self.bases, 'bases', response.data.basesList)
      }).catch(function (error) {
        console.error(error)
      })
    },
    previewFiles: function (event, index) {
      this.files[index] = event.target.files
      const thisPreviewFiles = this
      const files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return
      }
      this.createImage(files[0], index, thisPreviewFiles)
    },
    createImage: function (file, index, thisPreviewFiles) {
      const reader = new FileReader()
      const self = this
      reader.onload = (e) => {
        self.$set(self.$store.state.storeTrack.imagesPreview, index, e.target.result)
      }
      reader.readAsDataURL(file)
      this.validateReadImage(index, thisPreviewFiles, file)
    },
    getImagesPreview: function (i) {
      const self = this
      if (self.$store.state.storeTrack.imagesPreview[i] != null) {
        return self.$store.state.storeTrack.imagesPreview[i]
      } else {
        return null
      }
    },
    async validateReadImage (index, thisPreviewFiles, file) {
      const selfThis = this
      await axios.post('/api_readimage/validateFunction/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        if (response.data.value_return) {
          selfThis.getReconize(index, thisPreviewFiles, file)
        }
      })
    },
    async getReconize (index, thisPreviewFiles, image = false) {
      thisPreviewFiles.$bvToast.toast('Se esta procesando la imagen', {
        title: 'Trazabilidad',
        variant: 'warning',
        autoHideDelay: 5000,
        solid: true
      })
      const self = this
      let parentIndexinputs
      this.varRecognize = image
      const fileImage = document.getElementById('file-input-' + index)
      const formDataImage = new FormData()
      formDataImage.append('imageFile', fileImage.files[0])
      await axios.post('/api_readimage/getImage/', formDataImage, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        parentIndexinputs = document.getElementsByClassName('table-bases')[0].lastElementChild.children[index].children
        if (response.data.Referencia !== undefined && response.data.Referencia !== parentIndexinputs[0].innerText) {
          self.$bvToast.toast('No concuerda la referencia ' + response.data.Referencia + ' de la foto que ha tomado con la línea que ha elegido', {
            title: 'Trazabilidad',
            variant: 'danger',
            autoHideDelay: 5000,
            solid: true
          })
        } else {
          const valueLote = response.data.Lote !== undefined ? (response.data.Lote) : (parentIndexinputs[1].firstElementChild.value)
          parentIndexinputs[1].firstElementChild.value = valueLote
          self.$store.state.storeTrack.input.lote[index] = valueLote
          const valueLoteLogistico = response.data.Lote_Logistico !== undefined ? (response.data.Lote_Logistico) : (parentIndexinputs[2].firstElementChild.value)
          self.$store.state.storeTrack.input.loteLogistico[index] = valueLoteLogistico
          parentIndexinputs[2].firstElementChild.value = valueLoteLogistico
          const valueOrden = response.data.Orden !== undefined ? (response.data.Orden) : (parentIndexinputs[3].firstElementChild.value)
          parentIndexinputs[3].firstElementChild.value = valueOrden
          self.$store.state.storeTrack.input.loteLogistico[index] = valueOrden
          if (response.data.Referencia !== undefined) {
            self.$bvToast.toast('Se ha procesado la imagen con exíto', {
              title: 'Trazabilidad',
              variant: 'success',
              autoHideDelay: 5000,
              solid: true
            })
          } else {
            self.$bvToast.toast('Se ha procesado la imagen con exíto, pero no se ha podido reconocer la referencia.', {
              title: 'Trazabilidad',
              variant: 'success',
              autoHideDelay: 5000,
              solid: true
            })
          }
          self.lote(index, valueLote)
          self.loteLogistico(index, valueLoteLogistico)
          self.ordenProduction(index, valueLote)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .box-simple {
    background: #FFF;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  .image-upload>input {
    display: none;
  }
  //This class allows us to use the z-index to hide the icon
  .imagedisplay {
    height: auto;
    width: 100px;
    border: 1px solid #bbb;
    position: static;
    z-index: 3;
  }
  .file {
    position: absolute;
    z-index: 1;
  }
  .show_content {
    display: inherit !important;
  }
</style>
