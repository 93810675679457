<template>
  <div class="page inicio px-3 py-5 px-md-5">
    <div class="py-4 d-flex justify-content-center align-items-center h-100">
      <div class="logo-intro" v-if="!formLogin">
        <div class="mb-5 logo-inicio animate__slow">
          <img src="~@/assets/logoFull.png" class="img-fluid col-md-7">
        </div>
      </div>
      <div class="form-login animate__animated animate__fadeIn" v-if="formLogin">
        <div class="p-4">
          <div class="mb-4">
            <h2 class="color-yellow mb-4">Inicio de sesión</h2>
            <p class="text-white">Bienvenid@ para ingresar a IPRO inicie sesión <br> con su nombre de usuario y su contraseña</p>
          </div>
          <b-form @submit="sendLogin()" @submit.stop.prevent class="p-4 box-form" novalidate>
            <b-form-input class="mb-3" v-model="username" @focus="cleanField()" type="text" placeholder="Email" required></b-form-input>
            <b-form-input class="mb-3" v-model="password" @focus="cleanField()" type="password" :state="validation" placeholder="Contraseña" required></b-form-input>
            <b-form-invalid-feedback :state="validation">
              <strong> La contraseña o usuario son incorrectos, por favor verifique e intente de nuevo</strong>
            </b-form-invalid-feedback>
            <b-button type="submit" :class="{'disabled bg-grayLight' : !username || !password, 'bg-yellow' : username && password  }" class="btn-action-page col-md-6 text-dark font-weight-bold p-2 mt-5">Ingresar</b-button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  data: () => ({
    formLogin: false,
    username: '',
    password: '',
    validation: null,
    userFake: {
      username: 'ipro',
      password: '123456'
    }
  }),
  computed: {
    cookieSesion () {
      return this.$store.state.cookieSesion
    }
  },
  mounted () {
    const logoInicio = document.querySelector('.logo-inicio')
    logoInicio.classList.add('animate__animated', 'animate__fadeInUp')
    logoInicio.addEventListener('animationend', () => {
      this.formLogin = true
    })
    if (sessionStorage.token && sessionStorage.rol === '1') {
      this.$router.push({ name: 'PlanProduccion' })
    } else if (sessionStorage.token && sessionStorage.rol === '2') {
      this.$router.push({ name: 'AperturaLinea' })
    }
  },
  methods: {
    sendLogin: function () {
      const self = this
      axios.post('/api-token-auth/', {
        username: this.username,
        password: this.password
      }).then(function (response) {
        if (response.data.token != null) {
          self.$store.dispatch('statusModulesEnabled')
          self.$store.dispatch('atLeastQualityRecordFn')
          sessionStorage.setItem('token', response.data.token)
          axios.get('/api_user/user_info', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
            if (response.data.rol === 4 || response.data.rol === 2 || response.data.rol === 1) {
              sessionStorage.setItem('user_id', response.data.id)
              sessionStorage.setItem('rol', response.data.rol)
              sessionStorage.setItem('username', (response.data.first_name + ' ' + response.data.last_name))
              self.$store.state.sessionUsername = sessionStorage.getItem('username')
              if (response.data.rol === 1) {
                self.$router.push({ name: 'PlanProduccion' })
              } else {
                self.$router.push({ name: 'AperturaLinea' })
              }
            } else {
              sessionStorage.removeItem('token')
              self.validation = false
            }
          })
        }
      }).catch(function (error) {
        console.log(error)
        self.validation = false
      })
    },
    cleanField: function () {
      this.validation = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-inicio{
    transition: all 2s;
    opacity: 1;
    &.inicio-hide {
      opacity: 0 !important;
    }
  }
  .form-login .box-form {
    border: 1px solid #FDAB3D;
    border-radius: 10px;
  }
  .form-login input {
    border: 0;
    border-bottom: 1px solid #FDAB3D;
    border-radius: 0;
    background-color: transparent;
    font-size: 1.5rem;
    color: #FDAB3D;
  }
  .form-login .box-form ::placeholder {
    color: rgb(189 189 189 / 65%) !important;
    opacity: 1;
    text-align: center;
  }
  .form-login .box-form ::-ms-input-placeholder {
    color: rgb(189 189 189 / 65%) !important;
    text-align: center;
  }
  .form-login .box-form ::-ms-input-placeholder {
    color: rgb(189 189 189 / 65%) !important;
    text-align: center;
  }
  .disabled {
    pointer-events: none
  }
</style>
