<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <div class="page indicadores px-3 py-5 px-md-5">
    <div class="header-page">
      <h3>Indicadores</h3>
    </div>
    <div class="row mt-4 center-items" v-if="module_op !== false">
      <div class="col-xl-3 col-md-6 mb-4">
        <div>
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col mr-2">
                <div class="title-table h6 py-3 bg-blue-dark text-white">Tiempo de producción disponible</div>
                <div class="h3 py-3" style="background-color: #F5F6F8; height: 5rem;">{{
                  dataProductividad.tiempo_disponible
                }} hras</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 mb-4">
        <div>
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="title-table h6 py-3 bg-blue-dark text-white">Tiempo de producción real</div>
                <div class="h3 py-3" style="background-color: #F5F6F8;height: 5rem;">{{
                  dataProductividad.tiempo_produccion_real
                }} hras</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 mb-4">
        <div>
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="title-table h6 py-3 bg-blue-dark text-white">Productividad</div>
                <div class="h3 py-3" style="background-color: #F5F6F8;height: 5rem;">{{
                  dataProductividad.productividad
                }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body-page py-4">
      <div class="row no-gutters">
        <!-- inicio -->
        <div class="col-md-6 my-4" style="border: 1px solid #dee2e6!important;">
          <div>
            <div class="title-indicator text-center">Porcentaje de cumplimiento por línea y referencia</div>
            <div class="subtitle-indicator text-center">Producción de {{ this.$store.state.graphicsIndicator.dateReference }}</div>
          </div>
          <div class="row justify-content-center mt-5" align="center">
            <div class="col-5 text-left">
              <div>
                <b-form-input v-model="dateReferences" class="mb-3 b-form-input" type="date"></b-form-input>
              </div>
            </div>
            <div class="col-1 text-left">
              <button class="btn-filter-search" @click="percentagePerReference()">Buscar</button>
            </div>
          </div>
          <div class="p-4">
            <canvas id="myChart"></canvas>
            <div class="py-3">
              <div class="mb-2">
                <small>Ver detalle</small>
              </div>
              <div class="d-flex">
                <div class="col px-1" v-for="(item, i) in chardata.data.datasets[0].data" :key="i">
                  <div class="show-item-details border text-white py-2"
                    :style="'background:' + chardata.data.datasets[0].backgroundColor[i] + ';'"
                    @click="showDetails(i);">{{ item }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 my-4" style="border: 1px solid #dee2e6!important;">
          <div>
            <div class="title-indicator text-center">
              Producción global por rango de fechas
            </div>
            <div class="subtitle-indicator text-center">
              Porcentaje de cumplimiento
            </div>
            <div class="row justify-content-center mt-5" align="center">
              <div class="col-4 text-left">
                <div>
                  <b-form-input v-model="initial_date" class="mb-3 b-form-input" type="date"></b-form-input>
                </div>
              </div>
              <div class="col-4 text-left">
                <div>
                  <b-form-input v-model="final_date" class="mb-3 b-form-input" type="date"></b-form-input>
                </div>
              </div>
              <div class="col-1 text-left">
                <button class="btn-filter-search" @click="percentagePerMonthFunction()">Buscar</button>
              </div>
            </div>
          </div>
          <div class="p-4">
            <canvas id="graphics"></canvas>
          </div>
        </div>
        <!-- final -->
        <div class="col-md-6 my-4" style="border: 1px solid #dee2e6!important;">
          <div>
            <div class="title-indicator text-center">Porcentaje de cumplimiento por mes</div>
              <div class="row justify-content-center mt-5" align="center">
                <div class="col-5 text-left">
                  <div>
                    <b-form-select v-model="select_option" :options="optionsYear" class="mb-3">
                      <template #first></template>
                    </b-form-select>
                  </div>
                </div>
                <div class="col-1 text-left">
                  <button class="btn-filter-search" @click="mothGraphicFunction()">Buscar</button>
                </div>
              </div>
          </div>
          <div class="p-4">
            <canvas id="months"></canvas>
          </div>
        </div>
        <div class="col-md-6 my-4" style="border: 1px solid #dee2e6!important;">
          <div>
            <div class="title-indicator text-center">
              Tiempo de produccíon
            </div>
            <div class="subtitle-indicator text-center">
              {{ new Date().toJSON().slice(0, 10).replace(/-/g, '-') }}
            </div>
            <div class="p-4">
              <canvas id="piechart"></canvas>
            </div>
          </div>
        </div>
        <!-- lo nuevo -->
        <div class="col-md-6 my-4" style="border: 1px solid #dee2e6!important;">
          <div>
            <div class="title-indicator text-center">Top 10 referencias mas mas maquiladas</div>

            <div class="row justify-content-center mt-5" align="center">
              <div class="col-4 text-left">
                <div>
                  <b-form-input v-model="top_ref_initial_date" class="mb-3 b-form-input" type="date"></b-form-input>
                </div>
              </div>
              <div class="col-4 text-left">
                <div>
                  <b-form-input v-model="top_ref_final_date" class="mb-3 b-form-input" type="date"></b-form-input>
                </div>
              </div>
              <div class="col-1 text-left">
                <button class="btn-filter-search" @click="topTenReferences()">Buscar</button>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-3" align="center">
          </div>
          <div class="p-4">
            <canvas id="myTopRef"></canvas>
            <div class="py-3">
              <div class="d-flex">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 my-4" style="border: 1px solid #dee2e6!important;">
          <div>
            <div class="title-indicator text-center">
              Cantidades maquiladas por referencia en lo que va del año
            </div>
            <div class="title-indicator text-center" style="font-weight: normal;">
             la referencia mas maquilada es: {{ aux.ref }}
            </div>
            <div class="row justify-content-center mt-5" align="center">
              <div class="col-4 text-left">
                <div>
                  <!-- <b-form-input v-model="reference" placeholder="Ingrese una referencia" class="mb-3 b-form-input"></b-form-input> -->
                  <multiselect class="mb-3 b-form-input" :max-height="150" v-model="reference" :show-labels="true" :options="dataReferencias || []" placeholder="Referencia" label="ref" @select="refManufacturedCurrentYear(reference)"></multiselect>
                </div>
              </div>
              <!-- <div class="col-1 text-left">
                <button class="btn-filter-search" @click="refManufacturedCurrentYear(reference)">Buscar</button>
              </div> -->
            </div>
          </div>
          <div class="p-4">
            <canvas id="refManCurrentYear"></canvas>
          </div>
        </div>
        <!-- hasta aqui -->
      </div>
      <!-- hasta aqui van las graficas -->
      <div style="padding-bottom: 2rem;margin-top: 2rem;">
        <b-tabs fill style="background: #fbfbfb;">
          <b-tab title="Reportes de producción" active title-link-class="myTabs border border-dark">
            <table class="table table-list list-track" style="margin: 0 0 0 0;">
              <tbody>
                <tr>
                  <td style="width: 50%;">Control de actividades</td>
                  <td style="width: 50%;"><b-button class="btn-action-page py-2" @click="$bvModal.show('modal-control-pro')" size="lg">Descargar Reporte</b-button></td>
                </tr>
                <tr>
                  <td v-if="module_op === true" style="width: 50%;">Órdenes de producción</td>
                  <td v-if="module_op === false" style="width: 50%;">Referencias</td>
                  <td style="width: 50%;"><b-button class="btn-action-page py-2" size="lg" @click="$bvModal.show('modal-control-ref')">Descargar Reporte</b-button></td>
                </tr>
              </tbody>
            </table>
          </b-tab>
          <b-tab title="Reporte nota de despacho" title-link-class="myTabs border border-dark" v-if="module_op === true">
              <table class="table table-list list-track" style="margin: 0 0 0 0;">
                <tbody>
                  <tr>
                    <td style="width: 50%;">Nota de despacho</td>
                    <td style="width: 50%;">
                      <b-button class="btn-action-page py-2" size="lg" @click="$bvModal.show('modal-remision')">Descargar Reporte</b-button>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50%;">Orden de producción</td>
                    <td style="width: 50%;">
                      <b-button class="btn-action-page py-2" size="lg" @click="$bvModal.show('modal-op')">Descargar Reporte</b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
            <b-tab title="Reporte inventario" title-link-class="myTabs border border-dark" v-if="module_op === true">
              <table class="table table-list list-track" style="margin: 0 0 0 0;">
                <tbody>
                  <tr>
                    <td style="width: 50%;">Transacciones</td>
                    <td style="width: 50%;">
                      <b-button class="btn-action-page py-2" size="lg" @click="ReportTransactions()">Descargar Reporte</b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
        </b-tabs>
      </div>
      <!-- <div style="padding-bottom: 2rem;">
        <table class="w-100 table-list-production">
          <thead class="border-top">
            <tr>
              <th scope="col" class="border-left-head" style="width: 51.5rem;">Reporte</th>
              <th scope="col" class="border-left-head">Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Reporte de control de actividades</td>
              <td><b-button class="btn-action-page py-2" @click="$bvModal.show('modal-control-pro')" size="lg">Descargar
                  Reporte</b-button></td>
            </tr>
            <tr>
              <td>Reporte de referencias</td>
              <td><b-button class="btn-action-page py-2" size="lg" @click="$bvModal.show('modal-control-ref')">Descargar
                  Reporte</b-button></td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
    <!-- modal-control actividad -------------------------------------------------------------------------------------------->
    <b-modal ref="modal-control-pro" id="modal-control-pro" centered size="xl" :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="$bvModal.hide('modal-control-pro')">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div align="center">
        <p class="font-weight-bold">Línea</p>
        <b-form-select v-model="selectedLine" :options="lineList" class="mb-3">
          <template #first>
            <b-form-select-option :value="''">Todas</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <div class="row" align="center">
        <div class="col-6">
          <p class="font-weight-bold">Fecha de inicio</p><input class="form-control" v-model="fechaInicio" type="date"
            :disabled="this.$store.state.editarReferenciaPlan ? '' : false">
        </div>
        <div class="col-6">
          <p class="font-weight-bold">Fecha final</p><input class="form-control" type="date" v-model="fechaFin"
            :disabled="this.$store.state.editarReferenciaPlan ? '' : false">
        </div>
      </div>
      <br>
      <div align="center">
        <b-button class="btn-action-page py-2" @click="descargarReporte()" size="lg">Descargar Reporte</b-button>
      </div>
    </b-modal>
    <!-- modal-referencias -------------------------------------------------------------------------------------------->
    <b-modal ref="modal-control-ref" id="modal-control-ref" centered size="xl" :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="$bvModal.hide('modal-control-ref')">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div align="center">
        <div class="text-center">
          <p class="font-weight-bold">Referencia</p>
          <div class="py-3">
            <b-form-select v-model="referencia" :options="dataReferencias" class="mb-3">
              <template #first>
                <b-form-select-option :value="''">Todas</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
        <p class="font-weight-bold">Línea</p>
        <b-form-select v-model="selectedLine" :options="lineList" class="mb-3">
          <template #first>
            <b-form-select-option :value="''">Todas</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <div class="row" align="center">
        <div class="col-6">
          <p class="font-weight-bold">Fecha de inicio</p><input class="form-control" v-model="fechaInicio" type="date"
            :disabled="this.$store.state.editarReferenciaPlan ? '' : false">
        </div>
        <div class="col-6">
          <p class="font-weight-bold">Fecha final</p><input class="form-control" type="date" v-model="fechaFin"
            :disabled="this.$store.state.editarReferenciaPlan ? '' : false">
        </div>
      </div>
      <br>
      <div align="center">
        <b-button class="btn-action-page py-2" @click="descargarReporteReferencia()" size="lg">Descargar
          Reporte</b-button>
      </div>
    </b-modal>
    <!-- modal-details -------------------------------------------------------------------------------------------->
    <b-modal id="modal-details" ref="close-modal" size="xl" no-close-on-backdrop centered :hide-footer="true"
      :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="close_modal()">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div class="px-3 my-5">
        <div class="title-indicator text-center"> Producción de {{
          this.$store.state.graphicsIndicator.dateReference
        }}
          de la {{ this.chardata.data.labels[selected_line] }}</div>
        <div class="py-4 char-details">
          <canvas id="modalDetail"></canvas>
        </div>
      </div>
    </b-modal>
    <!-- modal-remision --------------------------------------------->
    <b-modal ref="modal-remision" id="modal-remision" centered size="xl" :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="$bvModal.hide('modal-remision')">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div class="row" align="center">
        <div class="col-6">
          <p class="font-weight-bold">Fecha de inicio</p>
          <input class="form-control" v-model="fechaInicio" type="date"
            :disabled="this.$store.state.editarReferenciaPlan ? '': false">
        </div>
        <div class="col-6">
          <p class="font-weight-bold">Fecha final</p>
          <input class="form-control" type="date" v-model="fechaFin"
            :disabled="this.$store.state.editarReferenciaPlan ? '': false">
        </div>
      </div>
      <br>
      <div align="center">
        <b-button class="btn-action-page py-2" @click="ReportRemission()" size="lg">Descargar Reporte</b-button>
      </div>
    </b-modal>
    <!-- modal-op --------------------------------------------------->
    <b-modal ref="modal-op" id="modal-op" centered size="xl" :hide-footer="true" :hide-header="true">
      <div class="d-flex justify-content-end">
        <button class="bg-transparent border-0" @click="$bvModal.hide('modal-op')">
          <i class="far fa-times-circle fa-2x color-grayLight"></i>
        </button>
      </div>
      <div align="center">
        <p class="font-weight-bold">Mes</p>
        <b-form-select v-model="monthOp2" :options="options" class="mb-3">
          <template #first>
            <b-form-select-option :value="''">Todas</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <br>
      <div align="center">
        <b-button class="btn-action-page py-2" @click="ReportProductionOrder(monthOp2)" size="lg">Descargar Reporte</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import Chart from 'chart.js'
import Multiselect from 'vue-multiselect'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  components: {
    Multiselect
  },
  data: () => ({
    referencia: '',
    fechaInicio: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
    fechaFin: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
    selectedLine: '',
    loaded: false,
    selected_line: -1,
    line_list: [],
    ref_manufactured: [],
    dateReference: '',
    reference: '',
    aux: [],
    initial_date: null,
    top_ref_initial_date: null,
    top_ref_final_date: null,
    charDataVariable: null,
    charDataTopReference: null,
    charDataRefManCurrentYear: null,
    perentageVariable: null,
    modalDetailReference: null,
    monthCompliance: null,
    piechartGraphic: null,
    final_date: null,
    select_option: '',
    monthOp2: null,
    options: [
      { value: null, text: 'Seleccione un mes' },
      { value: '1', text: 'Enero' },
      { value: '2', text: 'Febrero' },
      { value: '3', text: 'Marzo' },
      { value: '4', text: 'Abril' },
      { value: '5', text: 'Mayo' },
      { value: '6', text: 'Junio' },
      { value: '7', text: 'Julio' },
      { value: '8', text: 'Agosto' },
      { value: '9', text: 'Septiembre' },
      { value: '10', text: 'Octubre' },
      { value: '11', text: 'Noviembre' },
      { value: '12', text: 'Diciembre' }
    ],
    chardata: {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: 'Porcentaje',
          data: [],
          code: [],
          borderWidth: 1,
          backgroundColor: ['#ff9800', '#00C875', '#9e9e9e', '#00BCD4', '#363D8A', '#F44336', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800', '#363D8A', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800']
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true
      }
    },
    topTen: {
      type: 'horizontalBar', // Cambiado de 'bar' a 'horizontalBar'
      data: {
        labels: [],
        datasets: [{
          label: 'Unidades',
          data: [],
          code: [],
          borderWidth: 2,
          backgroundColor: ['#ff9800', '#00C875', '#9e9e9e', '#00BCD4', '#363D8A', '#F44336', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800']
        }]
      },
      options: {
        scales: {
          yAxes: [{ // Cambiado de yAxes a xAxes
            ticks: {
              barPercentage: 0.5
            },
            elements: {
              rectangle: {
                borderSkipped: 'left'
              }
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true
      }
    },
    charDataRefManCurY: {
      type: 'line',
      data: {
        labels: [],
        datasets: [{
          label: 'Unidades',
          data: [],
          code: [],
          borderWidth: 1,
          borderColor: 'rgba(255, 152, 0, 1)'
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true
      }
    },
    percentagePerMonth: {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: 'Porcentaje',
          data: [],
          borderWidth: 1,
          backgroundColor: ['#F44336', '#00B285', '#00C875', '#00BCD4', '#ff9800', '#363D8A', '#00FAC8', '#9e9e9e', '#00BCD4', '#ff9800', '#363D8A', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800']
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true
      }
    },
    chardataModal: {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: 'Porcentaje',
          data: [],
          borderWidth: 1,
          backgroundColor: ['#00B285', '#ff9800', '#9e9e9e', '#00BCD4', '#363D8A', '#F44336', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800', '#363D8A', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800']
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true
      }
    },
    chardataMonth: {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: 'Porcentaje',
          data: [],
          borderWidth: 1,
          backgroundColor: ['#ff9800', '#00B285', '#9e9e9e', '#00BCD4', '#363D8A', '#FF4D4D', '#00FAC8', '#9e9e9e', '#00BCD4', '#ff9800', '#363D8A', '#00B285', '#9e9e9e', '#00BCD4', '#ff9800']
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true
      }
    },
    pieChart: {
      type: 'pie',
      data: {
        labels: ['Tiempo Productivo', 'Tiempo Muerto'],
        datasets: [{
          label: '',
          data: [],
          borderWidth: 1,
          backgroundColor: ['#00B285', '#F44336']
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true
      }
    },
    optionsYear: [
      { value: '', text: 'Seleccione un año' },
      { value: 2023, text: '2023' },
      { value: 2022, text: '2022' }
    ],
    loadTiempoProductivo: false,
    loadProductividad: false,
    dataProductividad: {
      tiempo_disponible: 98,
      tiempo_produccion_real: 80,
      familia: 96,
      ppk: 80,
      productividad: 98
    }
  }),
  computed: {
    lineList () {
      return this.$store.state.storeLine.lineList
    },
    dataReferencias () {
      return this.$store.state.dataReferencias
    },
    module_op () {
      return this.$store.state.storeProduction.module_op
    },
    dateReferences: {
      set (value) {
        this.$store.state.graphicsIndicator.dateReference = value
      },
      get () {
        return this.$store.state.graphicsIndicator.dateReference
      }
    }
  },
  async mounted () {
    const self = this
    // self.$store.state.dataReferencias = []
    const ctx = document.getElementById('myChart')
    const topTenRef = document.getElementById('myTopRef')
    const refManufature = document.getElementById('refManCurrentYear')
    const graphics = document.getElementById('graphics')
    const months = document.getElementById('months')
    const pie = document.getElementById('piechart')
    self.monthCompliance = new Chart(months, self.chardataMonth)
    self.charDataVariable = new Chart(ctx, self.chardata)
    self.charDataTopReference = new Chart(topTenRef, self.topTen)
    self.charDataRefManCurrentYear = new Chart(refManufature, self.charDataRefManCurY)
    self.perentageVariable = new Chart(graphics, self.percentagePerMonth)
    self.piechartGraphic = new Chart(pie, self.pieChart)
    self.percentagePerReference()
    self.percentagePerMonthFunction()
    self.mothGraphicFunction()
    self.topTenReferences()
    self.getReferences()
    self.topReference()
    if (sessionStorage.token && sessionStorage.rol === '1') {
      await self.$store.dispatch('getInfoProduction')
      await axios.get('/api_reports/productivity/').then(function (response) {
        self.pieChart.data.datasets[0].data.push(response.data.PROGRAMADA)
        if (response.data['NO PROGRAMADA']) {
          self.pieChart.data.datasets[0].data.push(response.data['NO PROGRAMADA'])
        }
        self.loadTiempoProductivo = true
        self.piechartGraphic.update()
      })
      await axios.get('/api/line/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        var auxLineList = []
        response.data.forEach(function (item, index) {
          auxLineList.push({ value: item.id, text: item.id })
        })
        self.$store.commit('actionSetLineList', auxLineList)
      })
      // await axios.get('/api_reports/getTimeProductivity/').then(function (response) {
      //   self.dataProductividad.tiempo_disponible = response.data.available_time
      //   self.dataProductividad.tiempo_produccion_real = response.data.real_time
      //   self.dataProductividad.productividad = response.data.productivity
      //   self.loadProductividad = true
      // })
      // await axios.get('/api/product/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      //   response.data.forEach(function (item, index) {
      //     var data = { value: { nombre: item.name, ref: item.reference }, text: item.reference }
      //     self.$store.commit('actionSetDataReferencias', data)
      //   })
      // })
    } else if (sessionStorage.token && sessionStorage.rol === '2') {
      this.$router.push({ name: 'AperturaLinea' })
    } else {
      this.$router.push({ name: 'Inicio' })
    }
    if (sessionStorage.token) {
      this.$store.dispatch('statusModulesEnabled')
      this.$store.dispatch('atLeastQualityRecordFn')
    }
  },
  methods: {
    close_modal: function () {
      this.$refs['close-modal'].hide()
      this.loaded = false
      this.chardataModal.data.datasets[0].data = []
    },
    showDetails: function (index) {
      this.selected_line = index
      this.getDataCumplimiento()
      this.$bvModal.show('modal-details')
    },
    loadGraphicByReference (reference) {
      const self = this
      self.announceToast('Mensaje de prueba', 'No hay datos', 2000, 'warning')
    },
    announceToast (title, message, time, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: time,
        solid: true
      })
    },
    async getReferences () {
      const self = this
      if (sessionStorage.token) {
        self.$store.state.dataReferencias = []
        axios
          .get('/api/product/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
          .then(function (response) {
            response.data.forEach(function (item, index) {
              var data = { nombre: item.name, ref: item.reference }
              self.$store.commit('actionSetDataReferencias', data)
            })
          })
      }
    },
    async topTenReferences () {
      const self = this
      self.topTen.data.labels = []
      self.topTen.data.datasets[0].data = []
      if (self.top_ref_initial_date != null && self.top_ref_final_date != null) {
        await axios.post('/api_reports/topTenReferences/', { initial_date: self.top_ref_initial_date, final_date: self.top_ref_final_date }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          response.data.list_data.forEach((item, index) => {
            self.topTen.data.labels.push(item.reference_code)
            self.topTen.data.datasets[0].data.push(item.total_quantity)
          })
          self.charDataTopReference.update()
        })
      } else {
        await axios.get('/api_reports/topTenReferences/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          response.data.list_data.forEach((item, index) => {
            self.topTen.data.labels.push(item.reference_code)
            self.topTen.data.datasets[0].data.push(item.total_quantity)
          })
          self.charDataTopReference.update()
        })
      }
    },
    async topReference () {
      const self = this
      await axios.get('/api_reports/topReference/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.aux.ref = response.data.list_data[1].reference_code
        self.refManufacturedCurrentYear(self.aux)
      })
    },
    async refManufacturedCurrentYear (reference) {
      const self = this
      if (reference === '') {
        self.announceToast('No selecciono referencia', 'Seleccione una referencia', 4000, 'warning')
      } else {
        self.charDataRefManCurY.data.labels = []
        self.charDataRefManCurY.data.datasets[0].data = []
        await axios.post('/api_reports/referenceManufacturedCurrentYear/', { reference: reference.ref }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          if (response.data.list_data.length === 0) {
            self.announceToast('Intenta otra busqueda', 'No hay datos', 2000, 'warning')
          } else {
            response.data.list_data.forEach((item, index) => {
              self.ref_manufactured.push({ month: item.month, total_quantity: item.total_quantity })
              self.charDataRefManCurY.data.labels.push(item.month)
              self.charDataRefManCurY.data.datasets[0].data.push(item.total_quantity)
            })
          }
          self.charDataRefManCurrentYear.update()
        })
      }
    },
    descargarReporte () {
      const self = this
      if (this.fechaInicio == null || this.fechaFin == null) {
        this.$bvToast.toast('Debe ingresar ambas fechas.', {
          title: 'Campos incompletos',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else {
        const date1 = moment(this.fechaInicio, 'YYYY-MM-DD')
        const date2 = moment(this.fechaFin, 'YYYY-MM-DD')
        const diffTime = date2.diff(date1, 'days')
        if (diffTime > -1) {
          axios.post('/api_reports/getControlProductionReport/', { line: this.selectedLine, fechaInicial: this.fechaInicio, fechaFinal: this.fechaFin }).then(function (response) {
            // const type = response.headers['content-type']
            // const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
            const blob = new Blob([response.data], { type: 'text/csv;charset=UTF-8;' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'reporte_' + self.fechaInicio + '_hasta_' + self.fechaFin + '.csv'
            link.click()
          })
          // self.fechaInicio = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
          // self.fechaFin = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
        } else {
          this.$bvToast.toast('La fecha inicial debe ser mayor o igual a la inicial.', {
            title: 'Error de fecha',
            variant: 'danger',
            autoHideDelay: 5000,
            solid: true
          })
        }
      }
    },
    descargarReporteReferencia () {
      const self = this
      if (this.fechaInicio == null || this.fechaFin == null) {
        this.$bvToast.toast('Debe ingresar ambas fechas.', {
          title: 'Campos incompletos',
          variant: 'danger',
          autoHideDelay: 5000,
          solid: true
        })
      } else {
        const date1 = moment(this.fechaInicio, 'YYYY-MM-DD')
        const date2 = moment(this.fechaFin, 'YYYY-MM-DD')
        const diffTime = date2.diff(date1, 'days')
        if (diffTime > -1) {
          axios.post('/api_reports/getReferenceQuantity/', { lineId: this.selectedLine, fechaInicial: this.fechaInicio, fechaFinal: this.fechaFin, reference: this.referencia }).then(function (response) {
            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'reporte_referencias_' + self.fechaInicio + '_hasta_' + self.fechaFin + '.csv'
            link.click()
          })
          self.fechaInicio = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
          self.fechaFin = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
        } else {
          this.$bvToast.toast('La fecha inicial debe ser mayor o igual a la inicial.', {
            title: 'Error de fecha',
            variant: 'danger',
            autoHideDelay: 5000,
            solid: true
          })
        }
      }
    },
    async getDataCumplimiento () {
      setTimeout(() => {
        const self = this
        const modal = document.getElementById('modalDetail')
        self.loaded = true
        self.modalDetailReference = new Chart(modal, self.chardataModal)
        self.chardataModal.data.datasets[0].data = []
        self.chardataModal.data.labels = []
        axios.post('/api_reports/accomplishment/', { line_id: this.chardata.data.datasets[0].code[this.selected_line], date: this.$store.state.graphicsIndicator.dateReference }).then(function (response) {
          response.data.accomplishment_line.forEach(function (item, index) {
            self.chardataModal.data.datasets[0].data.push(item.prom)
            self.chardataModal.data.labels.push(item.produccionOrder_id__code)
          })
          self.modalDetailReference.update()
        })
      }, 1000)
    },
    async percentagePerReference () {
      this.chardata.data.datasets[0].data = []
      this.chardata.data.labels = []
      this.percentagePerMonth.data.datasets[0].data = []
      this.percentagePerMonth.data.labels = []
      await axios.post('/api_reports/accomplishment/', { date: this.$store.state.graphicsIndicator.dateReference }).then((response) => {
        response.data.accomplishment_line.forEach((item, index) => {
          this.chardata.data.datasets[0].data.push(item.prom)
          this.chardata.data.datasets[0].code.push(item.line_code)
          this.chardata.data.labels.push(item.line_code__name)
        })
        response.data.accomplishment_global.forEach((item, index) => {
          this.percentagePerMonth.data.datasets[0].data.push(item.prom)
          this.percentagePerMonth.data.labels.push(item.date_without_time)
        })
        this.charDataVariable.update()
        this.perentageVariable.update()
      })
    },
    async percentagePerMonthFunction () {
      this.percentagePerMonth.data.datasets[0].data = []
      this.percentagePerMonth.data.labels = []
      await axios.post('/api_reports/accomplishment/', { initial_date: this.initial_date, final_date: this.final_date }).then((response) => {
        response.data.accomplishment_global.forEach((item, index) => {
          this.percentagePerMonth.data.datasets[0].data.push(item.prom)
          this.percentagePerMonth.data.labels.push(item.date_without_time)
        })
        this.perentageVariable.update()
      })
      this.initial_date = null
      this.final_date = null
    },
    async mothGraphicFunction () {
      this.chardataMonth.data.datasets[0].data = []
      this.chardataMonth.data.labels = []
      await axios.post('/api_reports/getAccomplishmentmonth/', { year: this.select_option }).then((response) => {
        response.data.accomplishment_global.forEach((item, index) => {
          this.chardataMonth.data.datasets[0].data.push(item.prom)
          if (item.date_without_time__month === 1) {
            const ene = 'Enero'
            this.chardataMonth.data.labels.push(ene)
          } else if (item.date_without_time__month === 2) {
            const feb = 'Febrero'
            this.chardataMonth.data.labels.push(feb)
          } else if (item.date_without_time__month === 3) {
            const mar = 'Marzo'
            this.chardataMonth.data.labels.push(mar)
          } else if (item.date_without_time__month === 4) {
            const abri = 'Abril'
            this.chardataMonth.data.labels.push(abri)
          } else if (item.date_without_time__month === 5) {
            const may = 'Mayo'
            this.chardataMonth.data.labels.push(may)
          } else if (item.date_without_time__month === 6) {
            const jun = 'Junio'
            this.chardataMonth.data.labels.push(jun)
          } else if (item.date_without_time__month === 7) {
            const jul = 'Julio'
            this.chardataMonth.data.labels.push(jul)
          } else if (item.date_without_time__month === 8) {
            const ago = 'Agosto'
            this.chardataMonth.data.labels.push(ago)
          } else if (item.date_without_time__month === 9) {
            const sep = 'Septiembre'
            this.chardataMonth.data.labels.push(sep)
          } else if (item.date_without_time__month === 10) {
            const oct = 'Octubre'
            this.chardataMonth.data.labels.push(oct)
          } else if (item.date_without_time__month === 11) {
            const nov = 'Noviembre'
            this.chardataMonth.data.labels.push(nov)
          } else if (item.date_without_time__month === 12) {
            const dic = 'Diciembre'
            this.chardataMonth.data.labels.push(dic)
          }
          // this.chardataMonth.data.labels.push(item.date_without_time__month)
        })
        this.monthCompliance.update()
      })
      this.select_option = ''
    },
    async ReportRemission () {
      await axios.post('/api_orders/ReportRemission/', { initial_date: this.fechaInicio, final_date: this.fechaFin }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'reporte_remission.csv'
        link.click()
      })
    },
    async ReportProductionOrder (monthOp) {
      await axios.post('/api_orders/ReportProductionOrder/', { monthOp: monthOp }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'reporte_OrdenDeProduccion.csv'
        link.click()
      })
    },
    async ReportTransactions () {
      await axios.get('/api_inventory/ReportTransactions/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'reporte_OrdenDeProduccion.csv'
        link.click()
      })
    }
  }
}
</script>

<style lang="scss">
.title-indicator {
  font-weight: bold;
}

.subtitle-indicator {
  font-size: 1rem;
  font-weight: 500;
}

.show-item-details {
  cursor: pointer;
}

#modal-details {
  margin-left: 40px;
}

.table-reports {
  thead {
    text-align: left;

    th {
      padding: 10px 15px;
      font-weight: 400;
    }

    .border-left-head {
      border-left: 1px solid #ccc;
    }
  }

  tbody {
    tr {
      background-color: #F5F6F8;
      border-left: 7px solid;
      border-bottom: 8px solid #FFF;
    }

    td {
      padding: 3px 10px;
    }

    .column-left {
      text-align: left;
    }

    .option-divider {
      border-left: 1px solid #ccc;
      border-radius: 0;
    }
  }
}
.nav-fill .nav-item, .nav-fill>.nav-link {
  flex: 1 1 auto;
  text-align: center;
  border: 1px solid #03185A !important;
}
.nav-tabs .nav-link {
  margin-bottom: -1px !important;
  background-color: #03185A !important;
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
  font-weight: 600 !important;
}
.center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
