<template>
    <div class="page ordenCompra px-3 py-5 px-md-5">
      <div class="header-page">
        <h3>Orden de Compra</h3>
      </div>
      <div class="body-page py-4">
        <div class="sub-page sub-page-start d-flex justify-content-center" :class="{ 'align-items-start': listTrack, 'align-items-center': !listTrack }">
          <!-- Pantalla Inicio Orden de producción -->
          <div class="item-page-list w-100" v-if="listTrack">
            <div>
              <div class="text-left my-3 d-flex justify-content-between">
                <div class="col-6">
                  <span class="icon-list icon-blue-dark">
                    <i class="fas fa-cogs fa-1x"></i></span> <span class="mx-3 h4">Gestión de compras</span>
                </div>
              </div>
              <div class="row justify-content-end mb-5" v-if="!is_leader()">
                <div class="col-2">
                  <p>Filtrar por OC:</p>
                  <div>
                    <b-form-input v-model="code_oc" @change="searchOc(code_oc)" placeholder="Ingrese una OC" class="mb-3 b-form-input"></b-form-input>
                  </div>
                </div>
                <div class="col-2 allData">
                  <b-form-checkbox switch size="lg" id="checkbox-1" v-model="selectedItems" @change="allData(selectedItems)" value="All" unchecked-value="notAll">Todo</b-form-checkbox>
                </div>
              </div>
              <div class="table-responsive">
                <table v-if="loadTable" class="table table-list list-track">
                  <thead>
                    <tr>
                      <th class="row-op text-center">Orden de Compra</th>
                      <th></th>
                      <th></th>
                      <th style="width: 20rem;"></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="subhead-titles">
                      <td>N°. OC</td>
                      <td>Cod. Producto</td>
                      <td>Cantidad</td>
                      <td>Fecha</td>
                    </tr>
                    <tr v-for="(k,i) in purchaseOrder" :key="i" :class="{ 'success' : k.order[0].porcent >= 100, 'warning' : k.order[0].porcent <= 99 && k.order[0].porcent >= 60, 'danger' : k.order[0].porcent <= 59 && k.order[0].porcent >= 1 }">
                      <td><b-button class="text-dark font-weight-bold" v-for="(k, a) in k.order" :key="a" variant="link" @click="$bvModal.show('detail-purchase');modalListClose();putCurrentTask(k.codPro)">{{ k.nOc }}</b-button></td>
                      <td><span v-for="(k, b) in k.order" :key="b" class="text-dark font-weight-bold">{{ k.refOp }}</span></td>
                      <td><span class="d-block" v-for="(k, c) in k.order" :key="c">{{k.cantIni}}</span></td>
                      <td><span class="d-block" v-for="(k, d) in k.order" :key="d">{{k.fecha}}</span></td>
                      <td>
                        <button class="btn py-0 px-2" @click="showBlock();"><i class="far fa-edit text-success"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div  class="center" v-if="purchaseOrder.length === 0">
                <span><b-badge variant="danger align-middle"><h6>No existen registros en la base de datos.</h6></b-badge></span>
              </div>
              <div class="my-3" v-if="is_leader()">
                <b-button class="btn-action-page py-2" @click="showBlock();currentData = []" size="lg">Agregar Trazabilidad</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sidenav-page p-4" :class="{ openSidebar: openOptions }">
        <side-block
          :icon="'track-interna'"
          :tpl="'purchase'"
          @closeBlock="close"
          :data="currentData"
        />
      </div>
    <div>
      <b-modal v-if="showModal" ref="close-sub-modal" id="detail-purchase" size="xl" :hide-footer="true" :hide-header="true" body-class="">
        <div class="d-flex justify-content-end">
          <button class="bg-transparent border-0" @click="close_sub_modal()">
            <i class="far fa-times-circle fa-2x color-grayLight"></i>
          </button>
        </div>
        <div class="px-3 mb-3">
          <h4 class="color-bluedark">Detalle Orden de Compra</h4>
          <div class="p-4 bg-grayLight">
            <div class="row">
              <div class="col-6">
                <div class="my-4">
                  <h6>Número de OP</h6>
                  <div class="bg-white border p-2">
                    {{codeList[0].data[0].code_op}}
                  </div>
                </div>
                <div class="my-4">
                  <h6>Número de OC</h6>
                  <div class="bg-white border p-2">
                    {{codeList[0].data[0].code_oc}}
                  </div>
                </div>
                <div class="my-4">
                  <h6>Lote</h6>
                  <div class="bg-white border p-2" v-for="(lote, i) in codeList[0].data[0].batchs" :key="i">
                    {{lote}}
                  </div>
                </div>
                <div class="my-4">
                  <h6>Referencia</h6>
                  <div class="bg-white border p-2">
                    {{codeList[0].data[0].reference}}
                  </div>
                </div>
                <div class="my-4">
                  <h6>Nombre</h6>
                  <div class="bg-white border p-2">
                    {{codeList[0].data[0].name}}
                  </div>
                </div>
                <div class="my-4">
                  <h6>Cantidad</h6>
                  <div class="bg-white border p-2">
                    {{codeList[0].data[0].start_quantity}}
                  </div>
                </div>
                <div class="my-4">
                  <h6>Horas de Producción</h6>
                  <div class="bg-white border p-2">
                    {{codeList[0].data[0].hours}}
                  </div>
                </div>
                <h6>Ver Orden de Producción</h6>
                <div class="pt-3">
                  <b-button class="btn-action-page py-2" size="lg" @click="renderPdf(codeList[0].data[0].url_op)">Ver OP</b-button>
                </div>
              </div>
              <div class="col-6 border-left">
                <div class="my-4">
                  <h6>Bases</h6>
                  <div v-for="(base, i) in codeList[0].data[0].bases" :key="i" class="d-flex align-items-center my-2 py-2 bg-white item-list">
                    <div class="col-5">
                      <h6>{{base.reference}}</h6>
                    </div>
                    <div class="col border-left">
                      <span>{{base.name}}</span>
                    </div>
                  </div>
                </div>
                <div class="my-4">
                  <h6>Insumos</h6>
                  <div v-for="(supplie, i) in codeList[0].data[0].supplies" :key="supplie.id" :id="'supplie' + i + '-' + supplie.id" class="d-flex align-items-center my-2 py-2 bg-white item-list">
                    <div class="col-4">
                      <h6>{{supplie.id}}</h6>
                    </div>
                    <div class="col border-left">
                      <span>{{supplie.name}}</span>
                    </div>
                  </div>
                </div>
                <div class="my-4">
                  <h6>Estándar de la maquila</h6>
                  <div class="bg-white border p-2">
                    {{codeList[0].data[0].ppk_standard}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
      <div>
        <b-modal ref="pdf-modal" id="pdf-modal" size="xl" centered :hide-footer="true" :hide-header="true">
          <div class="d-flex justify-content-end">
            <button class="bg-transparent border-0" @click="close_pdf_modal()">
              <i class="far fa-times-circle fa-2x color-grayLight"></i>
            </button>
          </div>
          <div align="center">
            <pdf :src="currentDataSheetURL" id="pdf-view" :resize="true" @loading="removeText()" :page="1">
              <template slot="loading">
                Cargando ficha técnica...
              </template>
            </pdf>
          </div>
        </b-modal>
      </div>
    </div>
</template>

<script>
import pdf from 'pdfvuer'
import SideBlock from '@/components/SideBlock.vue'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  data: () => ({
    currentDataSheetURL: '',
    showModal: false,
    listTrack: true,
    currentData: [],
    loadTable: true,
    code_oc: undefined,
    codeList: [],
    selectedItems: null
  }),
  components: {
    pdf,
    SideBlock
  },
  mounted () {
    if (sessionStorage.token && sessionStorage.rol === '1') {
      this.setdataOrderStatus()
      this.$store.dispatch('getLineListWithTeam')
      this.$root.$on('setdataOrderStatus', () => {
        this.setdataOrderStatus()
      })
    } else {
      this.$router.push({ name: 'Inicio' })
    }
    if (sessionStorage.token) {
      this.$store.dispatch('statusModulesEnabled')
      this.$store.dispatch('atLeastQualityRecordFn')
    }
  },
  computed: {
    getLineList () {
      return this.$store.state.lineListWithTeam
    },
    currentTask () {
      return this.$store.state.storeProduction.currentTask
    },
    purchaseOrder: {
      get () {
        return this.$store.state.storeTrack.purchaseOrder
      },
      set (value) {
        this.$store.state.storeTrack.purchaseOrder = value
      }
    },
    openOptions () {
      return this.$store.state.showOptions
    }
  },
  methods: {
    modalListClose: function () {
      this.$bvModal.hide('modal-list-production')
    },
    removeText () {
      if (document.getElementsByClassName('textLayer')[0]) {
        document.getElementsByClassName('textLayer')[0].hidden = true
      }
    },
    async searchOc (code) {
      await this.setdataOrderStatus()
      if (code !== null) {
        var array = []
        this.purchaseOrder.forEach(function (item, index) {
          item.order.forEach(function (i, value) {
            if (i.nOc === code) {
              array.push(item)
            }
          })
        })
        this.purchaseOrder = array
      }
    },
    async allData (selectedItems) {
      await this.setdataOrderStatus()
      if (this.selectedItems === 'All') {
        return this.dataOrderStatus
      } else if (this.selectedItems !== 'All') {
      }
    },
    renderPdf (urlSent) {
      this.currentDataSheetURL = url + 'media/' + urlSent.split('/media/')[1]
      window.open(this.currentDataSheetURL, '_blank')
    },
    showBlock: function () {
      this.$store.commit('actionBlockOptions', true)
      this.$parent.overlayBody('on')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    close: function (value) {
      this.$store.commit('actionBlockOptions', value)
      this.$parent.overlayBody('off')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    updateTrack () {
      this.$store.state.storeTrack.updateTrack = true
    },
    is_leader () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    },
    close_modal: function () {
      this.$refs['close-modal'].hide()
    },
    setTrazToAction: function (item) {
      this.$store.commit('actionSetOpToAction', item)
    },
    async putCurrentTask (task) {
      const self = this
      await axios.post('/api_orders/getorders/', { codeOp: task }).then(function (response) {
        response.data.data.forEach(function (item, index) {
          self.codeList = [{ data: response.data.data }]
        })
        self.showModal = true
      }).catch(function (error) {
        console.log(error)
      })
      this.$bvModal.show('detail-purchase')
    },
    infoLoaded () {
      if (this.codeList.length !== 0) {
        return true
      } else {
        return false
      }
    },
    close_sub_modal () {
      this.$refs['close-sub-modal'].hide()
    },
    close_pdf_modal () {
      this.$refs['pdf-modal'].hide()
    },
    async setdataOrderStatus () {
      const self = this
      self.purchaseOrder = []
      await axios.get('/api_orders/getorders/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        response.data.data.forEach(function (item, index) {
          var order = []
          order.push({ refOp: item.reference, nOc: item.code_oc, codPro: item.code_op, cantRes: item.Remaining_amount, fecha: item.wp_date, porcent: item.percentage_compliance, cantIni: item.start_quantity, cantFin: item.end_quantity })
          self.purchaseOrder.push({ order })
          self.loadTable = true
        })
      })
    }
  }
}
</script>
<style lang="scss">
    .list-track {
      thead {
        tr {
          background-color: #F5F6F8;
          border-bottom: 1px solid #ccc;
          th.row-op {
            background-color: #39C2A1;
            color: #FFF;
          }
          th.row-oc {
            background-color: #03185A;
            color: #FFF;
          }
        }
      }
      tbody {
        tr.subhead-titles {
          background-color: #FFF;
          border: 0;
        }
        td {
          vertical-align: middle;
          &.multiple-register {
            span {
              border-bottom: 1px solid #8e8e8e;
              padding-bottom: 5px;
              &:last-child {
                border: 0;
              }
            }
          }
        }
      }
    }
    @media (max-width: 950.98px) {
      #detail-task {
        margin-left: 0;
        .modal-dialog {
          max-width: 90%;
          margin-left: 10%;
          margin-right: 10%;
        }
      }
    }
    @media (min-width: 950.98px) {
      #detail-task, #pdf-modal {
        margin-left: 0;
        .modal-dialog {
          max-width: 90%;
          margin-left: 15%;
          margin-right: 10%;
        }
      }
    }
    .container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  button, input, optgroup, select, textarea {
    padding-left: 10px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  .btn-refresh {
    // background-color: #39C2A1;
    background-color: #03185A;
    display: flex;
    box-shadow: 2px 5px 5px rgb(0 0 0 / 25%) inset;
    font-weight: 600;
    border-radius: 10px;
    border: 0;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    margin-bottom: 1rem;
  }
  .my-class-form-control-group{
    display: flex;
    align-items: center;
    margin-left: 60vw;
    padding-bottom: 2rem;
  }
  .titleFilterPurchase {
    margin-right: 11rem;
  }
</style>
