<template>
  <div class="form-apertura-linea">
    <div>
      <div class="row box-option mx-2" v-bind:class="searchPopup">
        <div class="col-md-5 py-4" v-if="!updateLine">
          <div class="text-left">
            <!-- Línea -->
            <p class="font-weight-bold">Línea</p>
            <b-form-select v-model="selectedLine" :options="lineList" class="mb-3"  :disabled="updateLine">
              <template #first>
                <b-form-select-option :value="null" disabled>Seleccione una Línea</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="col-md-7 py-4 border-custom-right">
          <div class="text-left" v-if="!is_leader()">
            <!-- Lider -->
            <p class="font-weight-bold border-bottom pb-2">Lider</p>
            <div class="py-4">
              <div class="btn-add-user leader text-center" v-if="Object.keys(setLeader).length === 0">
                <div class="icon-button rounded-circle" @click="activeSearchLeder = !activeSearchLeder">
                  <img src="~@/assets/icons/addUser.png">
                </div>
              </div>
              <div v-if="Object.keys(setLeader).length !== 0">
                <div class="text-center position-relative">
                  <img src="@/assets/icons/leader.png" class="rounded-circle avatar-leader img-fluid" alt="avatar">
                  <p class="h6 mt-2">{{setLeader.name}}</p>
                  <div class="delete-leader">
                    <b-button variant="link" @click="deleteLeader()" class="p-0"><i class="far fa-times-circle fa-2x text-danger"></i></b-button>
                  </div>
                </div>
              </div>
              <div class="py-2 px-3 mt-3 text-left box-search-leader rounded-lg" v-if="activeSearchLeder && Object.keys(setLeader).length === 0">
                <p class="m-0"><i>Seleccione un líder</i></p>
                <autocomplete :data="data" type-data="leader" @closeSearchLeader="closeSearchLeader" />
              </div>
            </div>
          </div>
            <!-- Equipo -->
            <div v-bind:class="{ 'text-center': !is_leader, 'text-center': is_leader }">
            <p class="font-weight-bold border-bottom pb-2">Equipo</p>
            <div class="row py-3 position-relative">
              <div class="col-4 my-3 px-1" v-for="(n, i) in quantityTeam" :key="i">
                <div class="btn-add-user team text-center" :id="'btn-team' + i">
                  <div class="icon-button rounded-circle" @click="btnSearchTeam(i)">
                    <img src="~@/assets/icons/addUser.png">
                  </div>
                </div>
                <div :id="'avatar-team' + i" class="d-none">
                  <div class="text-center position-relative">
                    <img src="" :id="'imageAvatarTeam-' + i" class="rounded-circle avatar-team img-fluid" alt="avatar">
                    <div class="delete-team">
                      <b-button variant="link" @click="deleteTeam(i)" class="p-0"><i class="far fa-times-circle fa-2x text-danger"></i></b-button>
                    </div>
                  </div>
                  <div :id="'nameTeam-' + i" class="text-center pt-4"></div>
                </div>
              </div>
              <div class="justify-content-center">
                <div id="search-team" v-if="activeSearchTeam">
                <div class="py-2 px-5 mt-5 text-left box-search-leader rounded-lg">
                  <p class="m-0"><i>Seleccione un colaborador</i></p>
                  <autocomplete :data="data" type-data="team" @closeSearchTeam="closeSearchTeam" />
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@/components/shared/Autocomplete.vue'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  name: 'FormAperturaLinea',
  props: {
    icon: String,
    data: Array
  },
  components: {
    Autocomplete
  },
  data () {
    return {
      image: require('@/assets/icons/user.png'),
      selected: null,
      options: [
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '2', text: '3' }
      ],
      filterUser: '',
      activeSearchLeder: false,
      activeSearchTeam: false,
      quantityTeam: 9,
      auxLineList: []
    }
  },
  computed: {
    searchPopup: function () {
      return {
        'row justify-content-center': this.updateLine && !this.activeSearchTeam,
        'row justify-content-end': this.activeSearchTeam && this.updateLine
      }
    },
    setLeader () {
      return this.$store.state.storeLine.setLeader
    },
    setTeam () {
      return this.$store.state.storeLine.setTeam
    },
    posTeam () {
      return this.$store.state.storeLine.posTeam
    },
    lineList () {
      return this.$store.state.storeLine.lineList
    },
    updateLine () {
      return this.$store.state.storeLine.updateLine
    },
    selectedLine: {
      get () {
        return this.$store.state.storeLine.selected
      },
      set (value) {
        this.$store.state.storeLine.selected = value
      }
    }
  },
  mounted () {
    const self = this
    if (sessionStorage.token) {
      axios.get('/api/getAvailableLines/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        var auxLineList = []
        response.data.json_lines.forEach(function (item, index) {
          auxLineList.push({ value: item.id, text: item.name_line })
        })
        self.quantityTeam = response.data.quantityTeam
        self.$store.commit('actionSetLineList', auxLineList)
      })
      this.$root.$on('loadDataToForm', () => {
        this.loadDataToForm()
      })
    }
  },
  methods: {
    getImgUrl: function (pic) {
      return require('../assets/icons/' + pic + '.png')
    },
    deleteLeader: function () {
      const clearLeader = {}
      this.$store.commit('actionSetLeader', clearLeader)
      this.activeSearchLeder = false
    },
    deleteTeam: function (i) {
      const clearTeam = {}
      this.$store.commit('actionPosTeam', i)
      this.$store.commit('actionSetTeam', clearTeam)
      var avatarTeam = document.getElementById('avatar-team' + this.$store.state.storeLine.posTeam)
      var btnTeam = document.getElementById('btn-team' + this.$store.state.storeLine.posTeam)
      var imageAvatarTeam = document.getElementById('imageAvatarTeam-' + this.$store.state.storeLine.posTeam)
      var nameTeam = document.getElementById('nameTeam-' + this.$store.state.storeLine.posTeam)
      btnTeam.classList.remove('d-none')
      avatarTeam.classList.add('d-none')
      imageAvatarTeam.src = '/img/user.e622f9f0.png'
      nameTeam.innerHTML = ''
      this.$bvToast.toast('Se elimino el usuario con exito', {
        title: 'Equipo',
        variant: 'danger',
        autoHideDelay: 5000,
        solid: true
      })
    },
    is_leader () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    },
    loadDataToForm: function () {
      this.$store.state.storeLine.setTeam.forEach(function (item, index) {
        var avatarTeam = document.getElementById('avatar-team' + index)
        var btnTeam = document.getElementById('btn-team' + index)
        var imageAvatarTeam = document.getElementById('imageAvatarTeam-' + index)
        var nameTeam = document.getElementById('nameTeam-' + index)
        imageAvatarTeam.src = '/img/user.e622f9f0.png'
        nameTeam.innerHTML = item.name
        btnTeam.classList.add('d-none')
        avatarTeam.classList.remove('d-none')
      })
    },
    btnSearchTeam: function (value) {
      this.activeSearchTeam = true
      this.$store.commit('actionPosTeam', value)
    },
    closeSearchLeader: function (value) {
    },
    closeSearchTeam: function (value) {
      this.activeSearchTeam = value
      var avatarTeam = document.getElementById('avatar-team' + this.$store.state.storeLine.posTeam)
      var btnTeam = document.getElementById('btn-team' + this.$store.state.storeLine.posTeam)
      var imageAvatarTeam = document.getElementById('imageAvatarTeam-' + this.$store.state.storeLine.posTeam)
      var nameTeam = document.getElementById('nameTeam-' + this.$store.state.storeLine.posTeam)
      btnTeam.classList.add('d-none')
      avatarTeam.classList.remove('d-none')
      imageAvatarTeam.src = '/img/user.e622f9f0.png'
      nameTeam.innerHTML = this.$store.state.storeLine.setTeam[this.$store.state.storeLine.posTeam].name
    }
  }
}
</script>

<style lang="scss" scoped>
  // Apertura de Línea -------------------------------------------------
  .btn-add-user {
    .icon-button {
      border: 1px solid #CACAD9;
      display: inline-block;
      padding: 15px;
    }
  }
  // Leader ------------------------------------------------------------
  .delete-leader {
    position: absolute;
    bottom: 20%;
    right: 30%;
    background-color: #FFF;
    border-radius: 50%;
  }
  // Team --------------------------------------------------------------
  #search-team {
    position: absolute;
    left: -80%;
    top: 0;
  }
  // Team --------------------------------------------------------------
  .delete-team {
    position: absolute;
    bottom: -10%;
    right: 0;
    background-color: #FFF;
    border-radius: 50%;
  }
</style>
