export var url = ''
if (window.location.href.includes('iprofamilia.produempak.com')) {
  url = 'https://apifamilia.produempak.com/'
} else if (window.location.href.includes('iprobsn.produempak.com')) {
  url = 'https://apibsn.produempak.com/'
} else if (window.location.href.includes('iprodemo.produempak.com')) {
  url = 'https://apidemo.produempak.com/'
} else if (window.location.href.includes('ipromasterfoods.produempak.com')) {
  url = 'https://apimasterfoods.produempak.com/'
}

// export var url = ''
// if (window.location.href.includes('familia2')) {
//   url = 'http://familia:8000/'
// } else if (window.location.href.includes('bsn2')) {
//   url = 'http://bsn:8000/'
// } else if (window.location.href.includes('masterfoods2')) {
//   url = 'http://masterfoods:8000/'
// } else if (window.location.href.includes('demo2')) {
//   url = 'http://demo:8000/'
// }
