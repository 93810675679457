import Vue from 'vue'
import Vuex from 'vuex'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    showOptions: false,
    bodyOverlay: false,
    sessionUsername: '',
    dataMaterial: [],
    dataLotes: [],
    dataOp: [],
    filereferencesStatus: [false],
    dataPlanProduccion: {
      op: '',
      date: '',
      file: null,
      user_id: sessionStorage.getItem('user_id'),
      rowsPlanProduccion: [
        {
          codigo: '',
          descripcion: '',
          cantidad: '',
          lote: '',
          horas: 0,
          ficha: '',
          fichaName: ''
        }
      ]
    },
    referenciaRemision: null,
    referenciaPlan: null,
    ProductionOrder: null,
    idremission: null,
    description: null,
    ProductionPurchase: null,
    codeSiesa: null,
    dispatchStatus: null,
    codeSelect: null,
    codeSelectPurchase: null,
    packingsLists: [],
    productionBatch: null,
    cantidadPlan: 0,
    horasPlan: 0,
    lineListWithTeam: [],
    dataReferencias: [],
    dataEstado: [],
    dataLista: [],
    dataTypeTransaction: [],
    dataCode: [],
    codeSelectList: [],
    editarReferenciaPlan: false,
    editarEstado: false,
    editarLista: false,
    list_referencias: [],
    storeProduction: {
      lotes: null,
      tagOfBatch: '',
      batchList: [],
      showProduction: false,
      listProductionCurrent: null,
      referencia: { nombre: '', ref: '' },
      length_op: 0,
      referenciaBSN: null,
      referenciaOc: null,
      idrefbyop: null,
      batchInventory: { pk: '', observations: '' },
      dispatchStatus: { value: null, text: '' },
      packingsLists: null,
      codeSelect: null,
      codeMaterial: { value: '', text: '' },
      codeLotes: { value: '', text: '' },
      codeSelectPurchase: { pk: '', Op: '' },
      status: { pk: '', observations: '' },
      bases: [],
      insumoBsn: [],
      insumos: [],
      currentTask: [{}],
      currentItem: [{}],
      date: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      dateBsn: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      infoTomorrow: null,
      infoProduction: [],
      infoPendiente: null,
      infoCritico: null,
      addPurchase: null,
      infoRango: null,
      module_op: null,
      fichaTecnica: '',
      refToDel: null,
      refDateToDel: null,
      opToDel: null,
      pkInventory: null,
      observations: '',
      opDateToDel: null,
      dateTwoMonthsBefore: null,
      dateTwoMonthsAfter: null
    },
    storeLine: {
      setLeader: {},
      setTeam: [],
      posTeam: 0,
      lineList: [],
      selected: null,
      infoUsers: null,
      lineTeamToDel: null,
      updateLine: false,
      simpleUser: []
    },
    storeTrack: {
      statusLogisticBatch: false,
      lenLogisticBatch: false,
      julianDay: '0',
      statusTextBtn: 'Agregar',
      selectedRef: null,
      selectedLine: null,
      selectedOP: null,
      selectedLote: null,
      bases: [],
      trazToAction: null,
      opToAction: null,
      dataTrack: [],
      dataStatus: [],
      dataOrderStatus: [],
      dataControlInventory: [],
      dataPicking: [],
      dataControlInventoryPost: [],
      purchaseOrder: [],
      startRemission: [],
      monthOp: null,
      yearOp: null,
      monthOptions: [],
      yearOptions: [],
      packingList: [],
      dispatchNote: [],
      imagesPreview: [],
      updateTrack: false,
      input: {
        lote: [],
        loteLogistico: [],
        ordenProduction: [],
        productividad: [],
        cantidad: [],
        julian_day: []
      },
      inputBsn: {
        quantity: [],
        batch: []
      }
    },
    storeActivity: {
      showCumplimiento: false,
      percentage: 0,
      start_time: '00:00',
      pkTypeProcessProduct: null,
      end_time: '00:00',
      current_activity: 0,
      order_Lote_reference: null,
      validater_line_batch: null,
      selected_activity: null,
      activityList: [],
      edit_activity: false,
      currentProduction: 0,
      openTimer: false,
      selectedRef: null,
      selectedOP: null,
      selectedLote: null,
      production_control_id: null,
      selectedLine: null,
      clockMins: null,
      clockSec: null,
      clockHr: null,
      activateSecondBtn: false,
      itemVerifiedprocess: 1,
      actividadFormTabs: 0,
      order_Lote: '',
      saveFilesActivities: [],
      itemsBatch: [],
      processQualityBtn: '',
      disabledBtnSend: false,
      user_list: []
    },
    storeQualityModule: {
      dateInfo: null,
      statusQualityModule: false,
      lenValidateInQualityModule: 0,
      modal_alert_traceability: { title: '', content: '' },
      dataQualityVerficated: [],
      lenQualityNoVerificated: 0,
      dataQualityNoVerficated: [],
      lenQualityVerificated: 0,
      dataFakeQualityProduct: [],
      atLeastQualityRecord: false
    },
    storeInventory: {
      itemTransaction: null,
      postTabsArray: [],
      placeDelTransaction: null,
      placeObject: [],
      codeSuppliesInventory: null,
      descriptionSupplieInventory: null,
      batchSupplieInventory: null,
      opSupplieInventory: null,
      arrayTabs: [],
      typeSupplieInventory: { pk: '', name: '' },
      placeSupplieInventory: { pk: null, name: '' },
      originSupplieInventory: { pk: null, name: '' },
      destinationSupplieInventory: { pk: null, name: '' },
      updateQuantityTransaction: null,
      updateBatchTransaction: null,
      updateOrdenProductionTransaction: null,
      updatePlaceTransaction: { pk: '', name: '' },
      updateTypeTransaction: { pk: '', name: '' },
      originChangeSupplieInventory: null,
      destinationChangeSupplieInventory: null,
      dateLinesList: [],
      pk: null,
      descriptionInFormControlInventory: null,
      codeInFormControlInventory: null,
      editarPk: false,
      OrdenTransportePk: '',
      OrdenProducion: { value: '', text: '' },
      dateOt: null,
      cantidadOrdenTransporte: [],
      pickingToDel: null,
      pkTransactionToDel: null,
      codeTransportation: null,
      batchUpdate: null,
      quantityOtUpdate: null,
      quantityReceived: null,
      pikingDescripcion: {
        descripcions: []
      },
      pikingLotes: {
        lotes: []
      },
      CodigoOplotef: {
        codigos: []
      },
      descriptionPiking: [''],
      rowsFormPicking: [
        { material: '', descripcion: '', op: '', lote: '', ordenTransporte: '', recibida: '' }
      ],
      inputOt: {
        material: [''],
        descripcion: [''],
        op: [''],
        lote: [''],
        ordenTransporte: [''],
        recibida: ['']
      },
      opForSideBlock: null
    },
    graphicsIndicator: {
      dateReference: ''
    },
    storeTrazabilityOrderProduction: {
      storeTraz: []
    },
    storeCost: {
      statusCostModule: false,
      costGraphic: [],
      costModule: [],
      ViewPerDay: [],
      supplies_record: [],
      priceConstructionMainTable: [],
      personalCost: [],
      listCollaboratorHistory: [],
      personalCostAddCollab: [],
      personalCostJobTitle: [],
      personalCostServiceCost: [],
      personalCostReferenceSupplies: [],
      dateInfo: null,
      show_btn_return: null,
      sale_value_hour: null,
      cost_value_hour: null,
      type_price: false,
      show_btn_add: false,
      general_hour_value: null,
      dateOfToday: new Date().toISOString().slice(0, 10),
      record: {
        id: null,
        name: null,
        box_unds: null,
        unit_standard: null,
        total_standard: null,
        title: null
      }
    }
  },
  getters: {},
  mutations: {
    resetdataPlanPorduccion (state, value) {
      state.dataPlanProduccion = {
        op: '',
        date: '',
        file: null,
        user_id: sessionStorage.getItem('user_id'),
        rowsPlanProduccion: [
          {
            codigo: '',
            descripcion: '',
            cantidad: '',
            lote: '',
            horas: 0,
            ficha: '',
            fichaName: ''
          }
        ]
      }
    },
    actionBlockOptions (state, value) {
      state.storeProduction.referencia = {}
      state.storeProduction.referenciaBSN = null
      state.storeProduction.bases = []
      state.storeProduction.insumos = []
      state.showOptions = value
    },
    actionOrvelay (state, value) {
      state.bodyOverlay = value
    },
    actionListCurrent (state, value) {
      state.storeProduction.listProductionCurrent = value
    },
    actionSetCurrentTask (state, value) {
      state.storeProduction.currentTask = value
    },
    actionSetCurrentItem (state, value) {
      state.storeProduction.currentItem = value
    },
    actionSetLeader (state, value) {
      state.storeLine.setLeader = value
    },
    actionSetTeam (state, value) {
      state.storeLine.setTeam[state.storeLine.posTeam] = value
    },
    actionSetTeamFull (state, value) {
      state.storeLine.setTeam = value
    },
    actionPosTeam (state, value) {
      state.storeLine.posTeam = value
    },
    actionSetActivity (state, value) {
      state.storeActivity.activitys = value
    },
    actionSetProduction (state, value) {
      state.storeActivity.currentProduction = value
    },
    actionSetInfoRango (state, value) {
      state.storeProduction.infoRango = value
    },
    actionSetModuleOp (state, value) {
      state.storeProduction.module_op = value
    },
    actionSetRefToDel (state, value) {
      state.storeProduction.refToDel = value
    },
    actionPickingToDel (state, value) {
      state.storeInventory.pickingToDel = value
    },
    actionPkTransactionToDel (state, value) {
      state.storeInventory.pkTransactionToDel = value
    },
    actioncodeTransportation (state, value) {
      state.storeInventory.codeTransportation = value
    },
    actionSetRefDateToDel (state, value) {
      state.storeProduction.refDateToDel = value
    },
    actionSetOpToDel (state, value) {
      state.storeProduction.opToDel = value
    },
    actionSetPkInventory (state, value) {
      state.storeProduction.pkInventory = value
    },
    actionSetOpDateToDel (state, value) {
      state.storeProduction.opDateToDel = value
    },
    actionSetReferencia (state, value) {
      state.storeProduction.referencia = value
    },
    actionSetReferenciaBsn (state, value) {
      state.storeProduction.referenciaBSN = value
    },
    actionSetOP (state, value) {
      state.ProductionOrder = value
    },
    actionSetDescription (state, value) {
      state.description = value
    },
    actionSetOC (state, value) {
      state.ProductionPurchase = value
    },
    actionSetBases (state, value) {
      state.storeProduction.bases = value
    },
    actionInsumoBsn (state, value) {
      state.storeProduction.insumoBsn = value
    },
    actionbatchUpdate (state, value) {
      state.storeInventory.batchUpdate = value
    },
    actionquantityOtUpdate (state, value) {
      state.storeInventory.quantityOtUpdate = value
    },
    actionquantityReceived (state, value) {
      state.storeInventory.quantityReceived = value
    },
    actionSetInsumos (state, value) {
      state.storeProduction.insumos = value
    },
    actionSetLotes (state, value) {
      state.storeProduction.lotes = value
    },
    actionSetHoras (state, value) {
      state.horasPlan = value
    },
    actionSetCantidad (state, value) {
      state.cantidadPlan = value
    },
    actionSetFichaTecnica (state, value) {
      state.storeProduction.fichaTecnica = value
    },
    actionSetDataReferencias (state, value) {
      state.dataReferencias.push(value)
    },
    actionSetDataEstado (state, value) {
      state.dataEstado.push(value)
    },
    actionSetDataLista (state, value) {
      state.dataLista.push(value)
    },
    actionDataTypeTransaction (state, value) {
      state.dataTypeTransaction.push(value)
    },
    actionSetDataCode (state, value) {
      state.dataCode.push(value)
    },
    actionSetDataMaterial (state, value) {
      state.dataMaterial.push(value)
    },
    actionSetPlaceObject (state, value) {
      state.storeInventory.placeObject.push(value)
    },
    actionSetDataLotes (state, value) {
      state.dataLotes.push(value)
    },
    actionSetDataOp (state, value) {
      state.dataOp.push(value)
    },
    actionSetCodeSelectList (state, value) {
      state.codeSelectList.push(value)
    },
    actionSetStatusBtn (state, value) {
      state.storeTrack.statusTextBtn = value
    },
    actionSetEditarReferenciaPlan (state, value) {
      state.editarReferenciaPlan = value
    },
    actionSetEditarEstado (state, value) {
      state.editarEstado = value
    },
    actionSetEditarPk (state, value) {
      state.storeInventory.editarPk = value
    },
    actionSetEditarLista (state, value) {
      state.editarLista = value
    },
    actionSetLineList (state, value) {
      state.storeLine.lineList = value
    },
    actionSetinfoUsers (state, value) {
      state.storeLine.infoUsers = value
    },
    actionSetAddPurchase (state, value) {
      state.storeProduction.addPurchase = value
    },
    actionSetInfoTomorrow (state, value) {
      state.storeProduction.infoTomorrow = value
    },
    actionSetInfoProduction (state, value) {
      state.storeProduction.infoProduction = value
    },
    actionSetInfoPendiente (state, value) {
      state.storeProduction.infoPendiente = value
    },
    actionSetInfoCritico (state, value) {
      state.storeProduction.infoCritico = value
    },
    actionSetTeamToDel (state, value) {
      state.storeLine.lineTeamToDel = value
    },
    actionSetSelectedLine (state, value) {
      state.storeLine.selected = value
    },
    actionSetUpdateLine (state, value) {
      state.storeLine.updateLine = value
    },
    actionSetInputLote (state, item) {
      state.storeTrack.input.lote[item.index] = item.value
    },
    actionSetInputProduccion (state, item) {
      state.storeTrack.input.productividad[item.index] = item.value
    },
    actionSetInputCantidad (state, item) {
      state.storeTrack.input.cantidad[item.index] = item.value
    },
    actionSetInputLoteLogistico (state, item) {
      state.storeTrack.input.loteLogistico[item.index] = item.value
    },
    actionSetInputordenProduction (state, item) {
      state.storeTrack.input.ordenProduction[item.index] = item.value
    },
    actionSetInputJulianDay (state, item) {
      state.storeTrack.input.julian_day[item.index] = item.value
    },
    actionSetInputQuantity (state, item) {
      state.storeTrack.inputBsn.quantity[item.index] = item.value
    },
    actionSetInputBatch (state, item) {
      state.storeTrack.inputBsn.batch[item.index] = item.value
    },
    actionSetInputMaterial (state, item) {
      state.storeInventory.inputOt.material[item.index] = item.value
    },
    actionSetInputDescripcion (state, item) {
      state.storeInventory.inputOt.descripcion[item.index] = item.value
    },
    actionSetInputLoteOrdenTransporte (state, item) {
      state.storeInventory.inputOt.lote[item.index] = item.value
    },
    actionSetInputOpOrdenTransporte (state, item) {
      state.storeInventory.inputOt.op[item.index] = item.value
    },
    actionSetInputOt (state, item) {
      state.storeInventory.inputOt.ordenTransporte[item.index] = item.value
    },
    actionSetInputRecibida (state, item) {
      state.storeInventory.inputOt.recibida[item.index] = item.value
    },
    actionOpenTimer (state, value) {
      state.storeActivity.openTimer = value
    },
    actionSetTrazToAction (state, value) {
      state.storeTrack.trazToAction = value
    },
    actionSetOpToAction (state, value) {
      state.storeTrack.opToAction = value
    },
    actionStoreTrack (state, value) {
      state.storeTrack.dataTrack = value
    },
    actionSetActivateSecondBtn (state, value) {
      state.storeActivity.activateSecondBtn = value
    },
    actionSetActividadFormTabs (state, value) {
      state.storeActivity.actividadFormTabs = value
    },
    actionSetCurrentActivity (state, value) {
      state.storeActivity.current_activity = value
    },
    actionSetActivityOrderLoteReference (state, value) {
      state.storeActivity.order_Lote_reference = value
    },
    actionSetValidaterLineBatch (state, value) {
      state.storeActivity.validater_line_batch = value
    }
  },
  actions: {
    async atLeastQualityRecordFn (context) {
      await axios.post('/api_quality/atLeastQualityRecord/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        context.state.storeQualityModule.atLeastQualityRecord = response.data
      })
    },
    // async statusQualityControlAndLen (context) {
    async statusModulesEnabled (context) {
      await axios.post('/api/statusModules/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        context.state.storeQualityModule.statusQualityModule = response.data.statusQualityRecords
        context.state.storeQualityModule.lenValidateInQualityModule = response.data.lenValidateInQualityModule
        context.state.storeCost.statusCostModule = response.data.statusCostRecords
        context.state.storeTrack.statusLogisticBatch = response.data.statusLogisticBatch
        context.state.storeTrack.lenLogisticBatch = response.data.lenLogisticBatch
      })
    },
    async getInfoProduction (context) {
      if (sessionStorage.token) {
        await axios.get('/api/getproduction/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(response => {
          context.state.storeProduction.infoProduction = response.data.infoProduction
          context.state.storeProduction.infoPendiente = response.data.infoPendiente
          context.state.storeProduction.infoCritico = response.data.infoCritico
          context.state.storeProduction.infoTomorrow = response.data.infoTomorrow
          context.state.storeProduction.module_op = response.data.module_op
          context.state.storeProduction.dateTwoMonthsBefore = response.data.dateTwoMonthsBefore
          context.state.storeProduction.dateTwoMonthsAfter = response.data.dateTwoMonthsAfter
        })
      }
    },
    async getLineListWithTeam (context) {
      context.state.lineListWithTeam = []
      await axios.get('/api/getLineListWithTeam/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        response.data.forEach(function (item, index) {
          context.state.lineListWithTeam.push({ value: item.line_id__id, text: item.line_id__name })
        })
      })
    },
    async getproduction_plan_per_month (context) {
      if (sessionStorage.token) {
        await axios.post('/api/getproduction_plan_per_month/', { fechaInicial: context.state.storePlanProduccion }, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
          context.state.storeProduction.infoRango = response.data
        })
      }
    }
  }
})

export default store
