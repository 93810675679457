<template>
  <div class="control-inventario">
      <div>
        <div class="row box-option mx-4 center">
            <div class="col-md-10 py-4">
              <div class="text-left">
                <p class="font-weight-bold">Código insumo</p>
                <div class="py-3">
                  <multiselect v-model="codeSuppliesInventory" id="codeMaterial" :show-labels="false" :options="dataMaterial" placeholder="Seleccione un código" @select="DescriptionPiking(codeSuppliesInventory)"></multiselect>
                </div>
                <p class="font-weight-bold">Descripción</p>
                <div class="py-3">
                  <input type="text" v-model="this.$store.state.storeInventory.descriptionSupplieInventory" id="descriptionControl" disabled>
                </div>
                <p class="font-weight-bold">Tipo</p>
                <div class="py-3">
                  <multiselect v-model="typeSupplieInventory" id="codeMaterial" label="name" :show-labels="false" :options="dataTypeTransaction" placeholder="Seleccione un tipo"></multiselect>
                </div>
                <div v-if="this.$store.state.storeInventory.typeSupplieInventory.name === 'transferencia' || this.$store.state.storeInventory.typeSupplieInventory.name === 'Transferencia' || this.$store.state.storeInventory.typeSupplieInventory.name === 'TRANSFERENCIA'">
                  <div class="row py-3" style="display: flex;margin-left: 2px;margin-top: 10px;margin-bottom: 19px;">
                    <p class="font-weight-bold" style="align-self: center;margin: 0;">Origen</p>
                    <div class="col-sm" style="justify-content: center;display: flex;">
                      <multiselect v-model="originSupplieInventory" id="codeMaterial" :show-labels="false" label="name" :options="placeObject" placeholder="Seleccione un lugar"></multiselect>
                    </div>
                    <p class="font-weight-bold" style="align-self: center;margin: 0;">Destino</p>
                    <div class="col-sm" style="justify-content: center;display: flex;">
                      <multiselect v-model="destinationSupplieInventory" id="codeMaterial" :show-labels="false" label="name" :options="placeObject" placeholder="Seleccione un lugar"></multiselect>
                    </div>
                  </div>
                </div>
                <div v-if="this.$store.state.storeInventory.typeSupplieInventory.name === 'cambio' || this.$store.state.storeInventory.typeSupplieInventory.name === 'Cambio' || this.$store.state.storeInventory.typeSupplieInventory.name === 'CAMBIO'">
                  <div class="row py-3" style="display: flex;margin-left: 2px;margin-top: 10px;margin-bottom: 19px;">
                    <p class="font-weight-bold" style="align-self: center;margin: 0;">Origen</p>
                    <div class="col-sm" style="justify-content: center;display: flex;">
                      <multiselect v-model="originChangeSupplieInventory" :show-labels="false" :options="dataOp" placeholder="Seleccione una orden"></multiselect>
                    </div>
                    <p class="font-weight-bold" style="align-self: center;margin: 0;">Destino</p>
                    <div class="col-sm" style="justify-content: center;display: flex;">
                      <multiselect v-model="destinationChangeSupplieInventory" :show-labels="false" :options="dataOp" placeholder="Seleccione una orden"></multiselect>
                    </div>
                  </div>
                </div>
                <div style="margin-top: 12px;" v-if="this.$store.state.storeInventory.typeSupplieInventory.name === 'entrada' || this.$store.state.storeInventory.typeSupplieInventory.name === 'Entrada' || this.$store.state.storeInventory.typeSupplieInventory.name === 'ENTRADA' || this.$store.state.storeInventory.typeSupplieInventory.name === 'salida' || this.$store.state.storeInventory.typeSupplieInventory.name === 'Salida' || this.$store.state.storeInventory.typeSupplieInventory.name === 'SALIDA' || this.$store.state.storeInventory.typeSupplieInventory.name === 'transferencia' || this.$store.state.storeInventory.typeSupplieInventory.name === 'Transferencia' || this.$store.state.storeInventory.typeSupplieInventory.name === 'TRANSFERENCIA' || this.$store.state.storeInventory.typeSupplieInventory.name === 'avería' || this.$store.state.storeInventory.typeSupplieInventory.name === 'Avería' || this.$store.state.storeInventory.typeSupplieInventory.name === 'AVERÍA' || this.$store.state.storeInventory.typeSupplieInventory.name === 'averia' || this.$store.state.storeInventory.typeSupplieInventory.name === 'Averia' || this.$store.state.storeInventory.typeSupplieInventory.name === 'AVERIA'">
                  <p class="font-weight-bold">Orden de producción</p>
                  <div class="py-3">
                    <input type="number" v-model="opSupplieInventory">
                  </div>
                </div>
                <p class="font-weight-bold" style="margin-top: 12px;">Lote</p>
                <div class="py-3">
                  <input type="number" v-model="batchSupplieInventory">
                </div>
                <div v-if="this.$store.state.storeInventory.typeSupplieInventory.name === 'entrada' || this.$store.state.storeInventory.typeSupplieInventory.name === 'Entrada' || this.$store.state.storeInventory.typeSupplieInventory.name === 'ENTRADA' || this.$store.state.storeInventory.typeSupplieInventory.name === 'salida' || this.$store.state.storeInventory.typeSupplieInventory.name === 'Salida' || this.$store.state.storeInventory.typeSupplieInventory.name === 'SALIDA' || this.$store.state.storeInventory.typeSupplieInventory.name === 'cambio' || this.$store.state.storeInventory.typeSupplieInventory.name === 'Cambio' || this.$store.state.storeInventory.typeSupplieInventory.name === 'CAMBIO' || this.$store.state.storeInventory.typeSupplieInventory.name === 'avería' || this.$store.state.storeInventory.typeSupplieInventory.name === 'Avería' || this.$store.state.storeInventory.typeSupplieInventory.name === 'AVERÍA' || this.$store.state.storeInventory.typeSupplieInventory.name === 'averia' || this.$store.state.storeInventory.typeSupplieInventory.name === 'Averia' || this.$store.state.storeInventory.typeSupplieInventory.name === 'AVERIA'">
                  <p class="font-weight-bold">Lugar</p>
                  <div class="py-3">
                    <multiselect v-model="placeSupplieInventory" id="codeMaterial" :show-labels="false" :options="placeObject" label="name" placeholder="Seleccione un lugar"></multiselect>
                  </div>
                </div>
                <div class="row align-items-center pt-3 py-3">
                  <div class="col-5 text-left">
                    <p class="font-weight-bold m-0">Cantidad</p>
                  </div>
                  <div class="col-7">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-btn variant="info" @click="cantidadMin()"><i class="fas fa-caret-left"></i></b-btn>
                      </b-input-group-prepend>
                      <b-form-input type="number" min="0" v-model="cantidad" id="cantidad"></b-form-input>
                      <b-input-group-append>
                        <b-btn variant="info" @click="cantidadMax()"><i class="fas fa-caret-right"></i></b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <div class="alert alert-danger mt-4 px-3 w-100" role="alert" v-if="cantidad < 0">* La cantidad no puede <br> ser menor a uno</div>
                </div>
              </div>
            </div>
        </div>
      </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  name: 'RemissionOrder',
  props: {
    icon: String,
    data: Array,
    dataListas: Array,
    arrayCode: Array,
    extraData: Array,
    arrayPlace: Array
  },
  components: {
    Multiselect
  },
  data () {
    return {
      status: null
    }
  },
  computed: {
    cantidad: {
      set (value) {
        this.$store.state.cantidadPlan = value
      },
      get () {
        return this.$store.state.cantidadPlan
      }
    },
    codeSuppliesInventory: {
      set (value) {
        this.$store.state.storeInventory.codeSuppliesInventory = value
      },
      get () {
        return this.$store.state.storeInventory.codeSuppliesInventory
      }
    },
    typeSupplieInventory: {
      set (value) {
        this.$store.state.storeInventory.typeSupplieInventory = value
      },
      get () {
        return this.$store.state.storeInventory.typeSupplieInventory
      }
    },
    batchSupplieInventory: {
      set (value) {
        this.$store.state.storeInventory.batchSupplieInventory = value
      },
      get () {
        return this.$store.state.storeInventory.batchSupplieInventory
      }
    },
    opSupplieInventory: {
      set (value) {
        this.$store.state.storeInventory.opSupplieInventory = value
      },
      get () {
        return this.$store.state.storeInventory.opSupplieInventory
      }
    },
    placeSupplieInventory: {
      set (value) {
        this.$store.state.storeInventory.placeSupplieInventory = value
      },
      get () {
        return this.$store.state.storeInventory.placeSupplieInventory
      }
    },
    originSupplieInventory: {
      set (value) {
        this.$store.state.storeInventory.originSupplieInventory = value
      },
      get () {
        return this.$store.state.storeInventory.originSupplieInventory
      }
    },
    destinationSupplieInventory: {
      set (value) {
        this.$store.state.storeInventory.destinationSupplieInventory = value
      },
      get () {
        return this.$store.state.storeInventory.destinationSupplieInventory
      }
    },
    arrayTabs: {
      set (value) {
        this.$store.state.storeInventory.arrayTabs = value
      },
      get () {
        return this.$store.state.storeInventory.arrayTabs
      }
    },
    originChangeSupplieInventory: {
      set (value) {
        this.$store.state.storeInventory.originChangeSupplieInventory = value
      },
      get () {
        return this.$store.state.storeInventory.originChangeSupplieInventory
      }
    },
    destinationChangeSupplieInventory: {
      set (value) {
        this.$store.state.storeInventory.destinationChangeSupplieInventory = value
      },
      get () {
        return this.$store.state.storeInventory.destinationChangeSupplieInventory
      }
    },
    dataMaterial () {
      return this.$store.state.dataMaterial
    },
    dataTypeTransaction () {
      return this.$store.state.dataTypeTransaction
    },
    dataOp () {
      return this.$store.state.dataOp
    },
    placeObject () {
      return this.$store.state.storeInventory.placeObject
    }
  },
  mounted () {
    const self = this
    self.$store.state.dataMaterial = []
    axios.get('/api/supplies/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
      response.data.forEach(function (item, inde) {
        var arrayCode = item.id
        self.$store.commit('actionSetDataMaterial', arrayCode)
      })
    })
    self.$store.state.storeInventory.placeObject = []
    axios.get('/api_inventory/viewPlace/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
      response.data.forEach(function (item, index) {
        var arrayPlace = { pk: item.pk, name: item.name + ' - ' + item.location }
        self.$store.commit('actionSetPlaceObject', arrayPlace)
      })
    })
    if (sessionStorage.token) {
      self.$store.state.dataTypeTransaction = []
      axios.get('/api_inventory/viewCondition/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        response.data.forEach(function (item, index) {
          var dataListas = { pk: item.pk, name: item.name }
          self.$store.commit('actionDataTypeTransaction', dataListas)
        })
      })
      self.$store.state.dataOp = []
      axios.get('/api_inventory/viewsordersproduction/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        response.data.forEach(function (item, inde) {
          var arrayCode = item.production_order_code
          self.$store.commit('actionSetDataOp', arrayCode)
        })
      })
    }
  },
  methods: {
    getImgUrl: function (pic) {
      return require('../assets/icons/' + pic + '.png')
    },
    selectReference: function (id) {
      console.log(id)
    },
    fileFichaTecnica: function (event) {
      const self = this
      var reader = new FileReader()
      reader.onload = function (e) {
        self.fichaTecnica = e.target.result
      }
      reader.readAsDataURL(event.target.files[0])
    },
    deleteFile: function () {
      document.getElementById('fileFichaTecnica').value = ''
      this.$store.commit('actionSetFichaTecnica', '')
    },
    close_modal_zoom: function () {
      this.$refs['close-modal'].hide()
    },
    async DescriptionPiking (material) {
      await axios.get('/api/supplies/' + material, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then((response) => {
        if (response.data.description === undefined) {
          this.$bvToast.toast('El código material no contiene una descripción', {
            title: 'Código Material',
            variant: 'danger',
            autoHideDelay: 5000,
            solid: true
          })
        } else {
          this.$store.state.storeInventory.descriptionSupplieInventory = response.data.description
        }
      }).catch(function (error) {
        console.log(error)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
// Plan de Producción -------------------------------------------------
.box-simple {
  background: #FFF;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.inputs {
  width: 42vh !important;
}
.title {
  margin-top: 1rem;
}
input {
  width: 100%;
  margin-bottom: 25px;
  padding: 8px 12px;
  // box-shadow: 0 1px 2px #0064b7;
  border: none;
  border-radius: 5px;
  &:hover, &:active, &:focus {
    outline: none;
    box-shadow: 0 1px 8px #0064b7;
  }
}
</style>
