<template>
    <div class="form-orden-produccion">
        <div>
            <div class="row box-option mx-2">
                <div class="col-md-6 py-4 pr-4">
                    <!-- Número de orden de producción -->
                    <div class="text-left">
                        <p class="font-weight-bold">Número de OP</p>
                        <td class="text-center">
                        <b-form-input class="inputs" disabled></b-form-input>
                        </td>
                    </div>
                    <!-- Número de orden de compra -->
                    <div class="text-left title">
                        <p class="font-weight-bold">Número de OC</p>
                        <td class="text-center">
                        <b-form-input class="inputs" placeholder="Ingrese el número de la OP"></b-form-input>
                        </td>
                    </div>
                    <!-- Código de producto -->
                    <div class="text-left title">
                        <p class="font-weight-bold">Cód. Producto</p>
                        <td class="text-center">
                        <b-form-input class="inputs" placeholder="Ingrese el número de la OC"></b-form-input>
                        </td>
                    </div>
                    <!-- Fecha -->
                    <div class="form-group row title">
                    <label for="example-date-input" class="col-3 col-form-label font-weight-bold">Fecha</label>
                    <div class="col-10">
                        <input class="form-control inputs" v-model="dateProduction" type="date">
                    </div>
                    </div>
                </div>
                <div class="col-md-6 py-4 border-custom-left">
                    <!-- Cantidad -->
                    <div class="row align-items-center mt-2 pt-3">
                        <div class="col-5 text-left">
                        <p class="font-weight-bold m-0">Cantidad</p>
                        </div>
                        <div class="col-7">
                        <b-input-group>
                            <b-input-group-prepend>
                            <b-btn variant="info" @click="cantidadMin()"><i class="fas fa-caret-left"></i></b-btn>
                            </b-input-group-prepend>
                            <b-form-input type="number" min="0" v-model="cantidad" @change="updateHour()"></b-form-input>
                            <b-input-group-append>
                            <b-btn variant="info" @click="cantidadMax()"><i class="fas fa-caret-right"></i></b-btn>
                            </b-input-group-append>
                        </b-input-group>
                        </div>
                        <div class="alert alert-danger mt-4 px-3 w-100" role="alert" v-if="cantidad < 0">* La cantidad no puede <br> ser menor a uno</div>
                    </div>
                    <!-- Archivo de orden de compra -->
                    <div class="mt-5 text-left">
                        <p class="font-weight-bold m-0">Adjuntar Orden de Compra</p>
                        <div class="box-add-file mt-3 text-center">
                        <div class="px-3 py-4" v-show="!fichaTecnica">
                        <label for="fileFichaTecnica"><img src="~@/assets/icons/uploadFile.png" class="img-fluid"> <br> Adjuntar archivo</label>
                        <input type="file" name="file" id="fileFichaTecnica" @change="fileFichaTecnica($event)" class="inputfile" />
                        </div>
                        <div class="col-10 m-auto pt-4" v-show="fichaTecnica">
                        <div>
                            <img :src="fichaTecnica" class="img-fluid m-auto" alt="">
                        </div>
                        <div class="d-flex align-items-center mt-3">
                            <b-button v-b-modal.zoom-file class="color-grayLight col" variant="link"><i class="fas fa-search-plus h3"></i></b-button>
                            <span class="h2 font-weight-light col color-grayLight"> | </span>
                            <b-button class="col" variant="link" @click="deleteFile()"><i class="fas fa-trash-alt text-danger h3"></i></b-button>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/*
import dataproduct from '../../public/product.json'
*/
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url

export default {
  name: 'FormOrdenProduccion',
  props: {
    icon: String,
    data: Array,
    extraData: Array
  },
  data () {
    return {}
  },
  computed: {
    module_op () {
      return this.$store.state.storeProduction.module_op
    },
    dataReferencias () {
      return this.$store.state.dataReferencias
    },
    referenciaObj () {
      return this.$store.state.storeProduction.referencia
    },
    dateProduction: {
      get () {
        return this.$store.state.storeProduction.date
      },
      set (value) {
        this.$store.state.storeProduction.date = value
      }
    },
    cantidad: {
      set (value) {
        this.$store.state.cantidadPlan = value
      },
      get () {
        return this.$store.state.cantidadPlan
      }
    },
    codeOp: {
      set (value) {
        this.$store.state.ProductionOrder = value
      },
      get () {
        return this.$store.state.ProductionOrder
      }
    },
    horas: {
      set (value) {
        this.$store.state.horasPlan = value
      },
      get () {
        return this.$store.state.horasPlan
      }
    },
    referencia: {
      set (value) {
        this.$store.state.storeProduction.referencia = value
      },
      get () {
        return this.$store.state.storeProduction.referencia
      }
    },
    bases () {
      return this.$store.state.storeProduction.bases
    },
    insumos () {
      return this.$store.state.storeProduction.insumos
    },
    lotes () {
      return this.$store.state.storeProduction.lotes
    },
    fichaTecnica: {
      get () {
        return this.$store.state.storeProduction.fichaTecnica
      },
      set (value) {
        this.$store.state.storeProduction.fichaTecnica = value
      }
    }
  },
  mounted () {
    const self = this
    if (sessionStorage.token) {
      self.$store.state.dataReferencias = []
      axios
        .get('/api/product/', { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } })
        .then(function (response) {
          response.data.forEach(function (item, index) {
            var data = { value: { nombre: item.name, ref: item.reference }, text: item.reference }
            self.$store.commit('actionSetDataReferencias', data)
          })
        })
      this.$root.$on('selectRef', () => {
        this.selectReference(self.referencia)
      })
    }
  },
  methods: {
    getImgUrl: function (pic) {
      return require('../assets/icons/' + pic + '.png')
    },
    cantidadMin: function () {
      const self = this
      if (this.cantidad !== 0) {
        this.cantidad--
      }
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
    },
    updateHour: function () {
      const self = this
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
    },
    cantidadMax: function () {
      const self = this
      this.cantidad++
      if (this.referencia.nombre != null) {
        var personStandard = this.ppk_standard
        self.$store.state.horasPlan = Number((this.cantidad * 60 / personStandard) / 60).toFixed(2)
      }
    },
    selectReference: function (id) {
      const self = this
      self.$store.state.referenciaPlan = id.ref
      axios.get('/api/productinfo/' + id.ref, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.$store.commit('actionSetBases', response.data.Bases)
        self.$store.commit('actionSetInsumos', response.data.Supplies)
        self.$store.commit('actionSetLotes', response.data.Batchs)
        if (self.referencia != null) {
          self.ppk_standard = response.data.Product.ppk_standard
          var personStandard = self.ppk_standard
          var hours = (self.cantidad * 60 / personStandard) / 60
          self.$store.state.horasPlan = Number(hours).toFixed(2)
        }
      })
    },
    fileFichaTecnica: function (event) {
      const self = this
      var reader = new FileReader()
      reader.onload = function (e) {
        self.fichaTecnica = e.target.result
      }
      reader.readAsDataURL(event.target.files[0])
    },
    deleteFile: function () {
      document.getElementById('fileFichaTecnica').value = ''
      this.$store.commit('actionSetFichaTecnica', '')
    },
    close_modal_zoom: function () {
      this.$refs['close-modal'].hide()
    }
  }
}
</script>

<style lang="scss" scoped>
  // Orden de producción -------------------------------------------------
  .box-simple {
    background: #FFF;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  .inputs {
    width: 20rem !important;
  }
  .title {
    margin-top: 1rem;
  }
</style>
