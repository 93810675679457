<template>
  <div class="page estadoLinea px-3 py-5 px-md-5">
    <div class="header-page">
        <h3>Estado de líneas</h3>
    </div>
    <div class="body-page py-4">
      <div class="sub-page sub-page-start d-flex justify-content-center" :class="{ 'align-items-start': listTrack, 'align-items-center': !listTrack }">
        <!-- Pantalla Inicio Estado de Linea -->
        <div class="item-page-list w-100" v-if="listTrack">
          <div>
            <!-- If the person logged in is an admin, you can filter the production lines -->
            <div class="col-sm d-flex justify-content-start">
              <span class="icon-list icon-blue-dark">
                <i class="fas fa-cogs fa-1x"></i></span> <span class="mx-3 h4">Control de Producción</span>
            </div>
            <div class="row justify-content-end mt-4 mb-4" v-if="!is_leader()">
              <div class="col-xl-2 text-left" v-if="module_op === true">
                <p class="font-weight-bold">Filtrar por línea:</p>
                <div>
                  <b-form-select v-model="line_id" @change="searchbsn(line_id)" :options="getLineList" class="mb-3">
                    <template #first>
                      <b-form-select-option value="undefined">Todas las líneas</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-xl-1 allData text-left">
                <button class="btn-filter-search" @click="setdataStatus()">Refrescar</button>
              </div>
            </div>
            <div class="table-responsive">
              <table v-if="loadTable" class="table table-list list-track">
                <tbody>
                  <tr class="subhead-titles">
                    <td class="font-weight">Línea</td>
                    <td class="font-weight">Código orden de producción</td>
                    <td class="font-weight">Cód. Producto</td>
                    <td class="font-weight">Descripción</td>
                    <td class="font-weight">Lote</td>
                    <td class="font-weight">Cantidad</td>
                    <td class="font-weight">Productividad</td>
                  </tr>
                  <template v-for="itemline in ConsolidatedLineData">
                    <tr v-for="(subitem, i) in itemline.Referencias" :key="i" :class="{'success' : subitem.prom >= 100, 'warning' : subitem.prom <= 99 && subitem.prom >= 60, 'danger' : subitem.prom <= 59 && subitem.prom >= 1 }">
                      <td v-if="i === 0" :rowspan="itemline.Referencias.length">
                        <span class="text-dark font-weight-bold">{{ itemline.line_code__name }}</span>
                      </td>
                      <td>
                        <span class="d-block">{{ subitem.production_order_code}}</span>
                      </td>
                      <td class="multiple-register">
                        <span class="d-block">{{ subitem.product_code }}</span>
                      </td>
                      <td class="multiple-register">
                        <span class="d-block">{{ subitem.product_code__name }}</span>
                      </td>
                      <td class="multiple-register">
                        <span class="d-block">{{ subitem.batch }}</span>
                      </td>
                      <td class="multiple-register">
                        <span class="d-block">{{ subitem.sum }}</span>
                      </td>
                      <td>
                        <span class="font-weight-bold" :class="{'color-green' : subitem.prom >= 100, 'color-yellow' : subitem.prom <= 99 && subitem.prom >= 60, 'color-red' : subitem.prom <= 59 && subitem.prom >= 1 }">{{subitem.prom + '%'}}</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import pdf from 'pdfvuer'
// import SideBlock from '@/components/SideBlock.vue'
const { url } = require('../../api_config.js')
const axios = require('axios')
axios.defaults.baseURL = url
export default {
  data: () => ({
    currentDataSheetURL: '',
    showModal: false,
    listTrack: true,
    searchTrack: '',
    currentTrackItem: [],
    currentData: [],
    ConsolidatedLineData: [],
    urlsheet: '',
    loadTable: true,
    line_id: undefined
  }),
  mounted () {
    if ((sessionStorage.token && sessionStorage.rol === '1')) {
      this.setdataStatus()
      this.$store.dispatch('getLineListWithTeam')
      this.$root.$on('setdataStatus', () => {
        this.setdataStatus()
      })
    } else {
      this.$router.push({ name: 'Inicio' })
    }
    if (sessionStorage.token) {
      this.$store.dispatch('statusModulesEnabled')
      this.$store.dispatch('atLeastQualityRecordFn')
    }
  },
  computed: {
    module_op () {
      return this.$store.state.storeProduction.module_op
    },
    getLineList () {
      return this.$store.state.lineListWithTeam
    },
    currentTask () {
      return this.$store.state.storeProduction.currentTask
    },
    // A computed function must be created for the global variable, which brings all the information from the store.js file
    dataStatus: {
      get () {
        return this.$store.state.storeTrack.dataStatus
      },
      set (value) {
        this.$store.state.storeTrack.dataStatus = value
      }
    },
    openOptions () {
      return this.$store.state.showOptions
    }
  },
  components: {
    // SideBlock,
    // pdf
  },
  methods: {
    removeText () {
      if (document.getElementsByClassName('textLayer')[0]) {
        document.getElementsByClassName('textLayer')[0].hidden = true
      }
    },
    async search (linea) {
      await this.setdataStatus()
      if (linea !== 'undefined') {
        var array = []
        this.dataStatus.forEach(function (item, value) {
          if (item.line_id === parseInt(linea)) {
            array.push(item)
          }
        })
        this.dataStatus = array
      }
    },
    async searchbsn (linea) {
      await this.setdataStatus()
      // console.log(linea, 'la linea qqq')
      if (linea !== 'undefined') {
        var array = []
        this.ConsolidatedLineData.forEach(function (item, value) {
          if (item.line_code === parseInt(linea)) {
            array.push(item)
          }
        })
        this.ConsolidatedLineData = array
      }
    },
    renderPdf (urlSent) {
      this.currentDataSheetURL = url + 'media/' + urlSent.split('/media/')[1]
      window.open(this.currentDataSheetURL, '_blank')
    },
    showBlock: function () {
      this.$store.commit('actionBlockOptions', true)
      this.$parent.overlayBody('on')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    close: function (value) {
      this.$store.commit('actionBlockOptions', value)
      this.$parent.overlayBody('off')
      this.$store.commit('actionSetStatusBtn', 'Agregar')
    },
    updateTrack () {
      this.$store.state.storeTrack.updateTrack = true
    },
    is_leader () {
      if (sessionStorage.rol === '2') {
        return true
      } else {
        return false
      }
    },
    close_modal: function () {
      this.$refs['close-modal'].hide()
    },
    setTrazToAction: function (traz) {
      this.$store.commit('actionSetTrazToAction', traz)
    },
    async putCurrentTask (task) {
      const self = this
      await axios.get('/api/productinfo/' + task, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.currentTrackItem = [{ product: response.data.Product, bases: response.data.Bases, supplies: response.data.Supplies }]
        self.showModal = true
      })
      this.dataStatus.forEach(function (item, index) {
        if (item.ref === task) {
          self.urlsheet = item.url
        }
      })
      this.$bvModal.show('detail-task')
    },
    async setDataToForm (trazId) {
      const self = this
      this.dataStatus.forEach(function (item, index) {
        if (item.trazId === trazId) {
          self.$store.state.storeTrack.selectedLine = item.line_id
          self.$store.state.storeTrack.selectedRef = item.ref
          self.$store.state.storeTrack.input.cantidad = item.suma
          self.$store.state.storeTrack.input.productividad = item.prom
          self.$store.state.storeTrack.bases = []
          var auxBases = []
          item.bases.forEach(function (item, index) {
            auxBases.push({ refBase: item.refBase })
            var value = item.lote
            self.$store.commit('actionSetInputLote', { index, value })
            value = item.loteLogisticoEntrada
            self.$store.commit('actionSetInputProduccion', { index, value })
            value = item.productividad
            self.$store.commit('actionSetInputCantidad', { index, value })
            value = item.cantidad
            self.$store.commit('actionSetInputLoteLogistico', { index, value })
            value = item.ordenProduccion
            self.$store.commit('actionSetInputordenProduction', { index, value })
            self.$store.state.storeTrack.imagesPreview.push(url + 'media/' + item.file_url.split('/media/')[1])
            value = item.julian_day
            self.$store.commit('actionSetInputJulianDay', { index, value })
          })
          self.$set(self.$store.state.storeTrack.bases, 'bases', auxBases)
        }
      })
    },
    infoLoaded () {
      if (this.currentTrackItem.length !== 0) {
        return true
      } else {
        return false
      }
    },
    close_sub_modal () {
      this.$refs['close-sub-modal'].hide()
    },
    close_pdf_modal () {
      this.$refs['pdf-modal'].hide()
    },
    // An asynchronous function must be carried out, where we make the call to the information that the API contains through axios, we declare empty arrays, in which, through a push, we store the information that we need to paint in the table.
    async setdataStatus () {
      const self = this
      await axios.get('/api_orders/getConsolidatedLinebsn/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.ConsolidatedLineData = []
        self.ConsolidatedLineData = response.data
      })
      await axios.post('/api_reports/getConsolidatedLine/', null, { headers: { Authorization: 'token ' + sessionStorage.getItem('token') } }).then(function (response) {
        self.dataStatus = []
        response.data.forEach(function (traz, index) {
          var basestrack = []
          var cantrack = []
          traz.trazabilityproduct.forEach(function (bases, index) {
            basestrack.push({ refBase: bases.base_id, loteLogisticoEntrada: bases.entry_logistics_batch, lote: bases.entry_batch, ordenProduccion: bases.entry_production_order, file_url: bases.file, julian_day: bases.julian_day })
          })
          if (traz.quantity.length === 0) {
            cantrack.push({ productividad: 0, cantidad: 0 })
          } else {
            traz.quantity.forEach(function (cant, index) {
              cantrack.push({ productividad: cant.prom, cantidad: cant.suma })
            })
          }
          self.dataStatus.push({ trazId: traz.trazability.id, line_id: traz.trazability.line, line_name: traz.line_name, url: traz.url, ref: traz.trazability.product_code, loteLogisticoSalida: traz.trazability.output_batch, fechaVencimiento: traz.trazability.output_due_date, finalOrderMaquila: traz.trazability.output_production_order, bases: basestrack, cantidades: cantrack })
          self.loadTable = true
        })
      })
    }
  }
}
</script>

<style lang="scss">
  .list-track {
    thead {
      tr {
        background-color: #F5F6F8;
        border-bottom: 1px solid #ccc;
        th.row-entrada {
          background-color: #464FB5;
          color: #FFF;
        }
        th.row-salida {
          background-color: #363D8A;
          color: #FFF;
        }
      }
    }
    tbody {
      tr.subhead-titles {
        background-color: #FFF;
        border: 0;
      }
      td {
        vertical-align: middle;
        &.multiple-register {
          span {
            border-bottom: 1px solid #8e8e8e;
            padding-bottom: 5px;
            &:last-child {
              border: 0;
            }
          }
        }
      }
    }
  }
  @media (max-width: 950.98px) {
    #detail-task {
      margin-left: 0;
      .modal-dialog {
        max-width: 90%;
        margin-left: 10%;
        margin-right: 10%;
      }
    }
  }
  @media (min-width: 950.98px) {
    #detail-task, #pdf-modal {
      margin-left: 0;
      .modal-dialog {
        max-width: 90%;
        margin-left: 15%;
        margin-right: 10%;
      }
    }
  }
  .container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.font-weight {
  font-weight: 600;
}
</style>
